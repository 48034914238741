/**
 * @generated SignedSource<<6382b9ff8f6f280cab5f6abd5453765d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CompareTableRowChild_blockComparison$data = {
  readonly comparedDocumentBlocks: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly text: string;
      readonly type: BlockType;
    }>;
    readonly id: string;
    readonly text: string;
    readonly type: BlockType;
  } | null>;
  readonly mainBlock: {
    readonly id: string;
    readonly text: string;
    readonly type: BlockType;
  };
};
export type CompareTableRowChild_blockComparison$key = {
  readonly " $data"?: CompareTableRowChild_blockComparison$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompareTableRowChild_blockComparison">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "CompareTableRowChild_blockComparison",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "mainBlock",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "comparedDocumentBlocks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Block",
          "kind": "LinkedField",
          "name": "children",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BlockComparison",
  "abstractKey": null
};
})();

(node as any).hash = "383249453587134228de5d73ca69f39b";

export default node;

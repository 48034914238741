/**
 * @generated SignedSource<<2cd037aa32845c5223edb7d7f6bee3c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoomDetailsForDocument_document$data = {
  readonly id: string;
  readonly zoomMeeting: {
    readonly closedCaptioningDownloadUrl: string | null;
    readonly closedCaptioningText: string | null;
    readonly durationInMinutes: number;
    readonly startTime: string;
    readonly topic: string | null;
    readonly zoomMeetingId: string | null;
  } | null;
  readonly " $fragmentType": "ZoomDetailsForDocument_document";
};
export type ZoomDetailsForDocument_document$key = {
  readonly " $data"?: ZoomDetailsForDocument_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoomDetailsForDocument_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoomDetailsForDocument_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ZoomMeeting",
      "kind": "LinkedField",
      "name": "zoomMeeting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedCaptioningDownloadUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedCaptioningText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationInMinutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zoomMeetingId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "2add077ac67481a93e6430843b3dae23";

export default node;

import { Resolver } from 'found-relay';
import { RelayEnvironmentProvider } from 'react-relay';
import './App.css';
import { environment } from './auth/relayEnvironment';
import { ChakraProvider } from '@chakra-ui/react'
import { Router } from './routes';
import { verdiChakraTheme } from './chakraTheme';
import { SuspenseErrorBoundary } from './components/SuspenseErrorBoundary';
import { useWebSocket } from './components/documentSubscriptions/useWebSocket';

const resolver = new Resolver(environment)

function App() {
  // just start with a websocket for performance
  // and to make sure we don't close it
  useWebSocket()

  return (
    <ChakraProvider theme={verdiChakraTheme}>
      <SuspenseErrorBoundary>
        <RelayEnvironmentProvider environment={environment}>
          { /*  @ts-ignore */}
          <Router resolver={resolver} />
        </RelayEnvironmentProvider>
      </SuspenseErrorBoundary>
    </ChakraProvider>
  );
}

export default App;

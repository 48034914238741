import { DeleteIcon } from "@chakra-ui/icons"
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, useDisclosure } from "@chakra-ui/react"
import { useRef } from "react"
import { useDeleteDocument } from "./useDeleteDocument"



type Props = {
  documentId: string,
  documentTitle: string,
  redirectUrl: string
}

/** 
 * Handles confirmation, deletion of the document, and redirecting 
*/
const DeleteDocumentButton = ({ documentId, documentTitle, redirectUrl }: Props) => {

  const [deleteDocument, isCurrentlyDeleting] = useDeleteDocument(documentId)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null);

  const proceedToDelete = () => {
    console.log("DELETING DOCUMENT")
    deleteDocument()
    window.location.href = redirectUrl || "/"
  }

  return (
    <>
      <IconButton aria-label='Delete document'
        onClick={onOpen}
        variant='ghost'
        opacity='0.4'
        _hover={{ opacity: 1, color: "red" }}
        title="Delete document" icon={<DeleteIcon />} />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Document
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this document? <br />
              "{documentTitle}" <br />
              You can't undo this action.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef}
                onClick={onClose}
                disabled={isCurrentlyDeleting}>
                Cancel
              </Button>
              <Button colorScheme='red'
                onClick={proceedToDelete}
                ml={3}
                disabled={isCurrentlyDeleting}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )

}

export default DeleteDocumentButton
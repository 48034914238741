import { useCallback } from "react"

export const useOnKeyDownCommandMenu = (
  menuIsOpen: boolean,
  closeMenu: () => void,
  incrementIndex: () => void,
  decrementIndex: () => void,
  enterPressed: () => void
) => {

  const onKeyDown: (event: KeyboardEvent) => boolean = useCallback(
    (event => {
      if (menuIsOpen) {
        switch (event.key) {
          case 'ArrowDown':
            incrementIndex();
            return true
            break
          case 'ArrowUp':
            decrementIndex()
            return true
            break
          case 'Enter':
            enterPressed()
            return true
          case 'Escape':
            // event.preventDefault()
            closeMenu()
            return true
          default:
            return false
        }
      }
      return false
    }), [
    menuIsOpen, closeMenu,
  ])

  return { onKeyDown } as const
}
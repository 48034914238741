const generateRandomString = () => {
  let string = ""
  for (let i = 0; i < 5; i++) {
    string += (Math.random() + 1).toString(36).substring(7)
  }
  return string
}

// This value needs to be static for each browser page life-cycle
// If not, then prosemirror will mistake client identities and bad things will happen
// e.g. infinitely rebasing your own changes against yourself 🏃🚆
// context: 
// we originally tied client id to connectionIds provided by aws lambda
// this led to changed values for client id onn websocket reconnections
const clientIdSuffix = generateRandomString()

export const generateClientId = (base64EncodedGlobalId: string) => {
  const base64decodedUserId = window.atob(base64EncodedGlobalId)
  const [, hexStringUserId] = base64decodedUserId.split(':')
  return hexStringUserId + ':' + clientIdSuffix
}
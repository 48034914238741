import { IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { css } from "@emotion/react"
import {
  HiDotsVertical as MenuIcon,
  HiDocumentAdd as AddIcon,
  HiTrash as DeleteIcon,
} from 'react-icons/hi'
import {
  IoMdGrid as CompareIcon
} from 'react-icons/io'
import { useDeleteDocument } from "../document/useDeleteDocument"
import { useNewDocumentMutation } from "./guideList/NewDocumentButton"

type Props = {
  documentId: string,
  connectionIds: string[],
  includeNewInterview: boolean
}

export const DocumentItemMenu = ({
  documentId,
  connectionIds,
  includeNewInterview,
}: Props) => {

  const [copyDocument, _isCopying] = useNewDocumentMutation()
  const [deleteDocument, _isDeleting] = useDeleteDocument(documentId, connectionIds)

  return (
    <Menu>
      <MenuButton
        css={css`
          &:hover{
            opacity: 0.3;
          }
        `}
        as={IconButton}
        aria-label='Options'
        icon={<>
          <MenuIcon />
          <div
            css={css`
              pointer-events: none;
              border: 2px #999 solid;
              top: -2px;
              position: absolute;
              border-radius: 10px;
              height: 20px;
              aspect-ratio: 1;
              overflow: hidden;
             `} />
        </>}
        onClick={(event) => {
          // event.stopPropagation()
        }}
        color={'#999'}
        variant='link'
      >

      </MenuButton>
      <MenuList>
        {
          includeNewInterview &&
          <MenuItem
            icon={<AddIcon />}
            onClick={(event) => {
              event.stopPropagation()
              copyDocument({ type: 'interview', createFromDocId: documentId })
            }}
          >
            New Interview
          </MenuItem>
        }
        <MenuItem
          icon={<DeleteIcon />}
          onClick={(event) => {
            event.stopPropagation()
            deleteDocument()
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          icon={<CompareIcon />}
          onClick={(event) => {
            event.stopPropagation()
            window.location.href = `/compareDocument/${documentId}`
          }}
        >
          Compare
        </MenuItem>
      </MenuList>
    </Menu >
  )
}
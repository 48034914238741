export interface TranscriptLine {
  timeStamp: string
  text: string
}


export const buildFromString = (rawText: string): TranscriptLine[] => {
  const toReturn: TranscriptLine[] = []
  const rawLines = rawText.split('\r')
  let currentLine: TranscriptLine = {
    text: '',
    timeStamp: ''
  }
  rawLines.forEach(rawLine => {
    if (rawLine.length > 1) {
      if (rawLine.indexOf('-->') > 0) {
        currentLine.timeStamp = rawLine
      } else {
        currentLine.text = rawLine
        toReturn.push(currentLine)
      }
    } else {
      currentLine = {
        text: '',
        timeStamp: ''
      }
    }
  })

  return toReturn
}
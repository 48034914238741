import { Editor } from "@tiptap/core"
import { EditorState } from "prosemirror-state"
import { useCallback, useTransition, forwardRef, useRef, useState, useImperativeHandle, useEffect } from "react"
import { createPortal } from "react-dom"
import { getSelectedBlockText } from "../utils/getSelectedBlockText"
import { CommandMenuContent, CommandMenuKeyRef } from "./CommandMenuContent"
import './CommandMenu.css'
import VeNode from "../VeNode"

const isCommandChar = (char: string) =>
  char === '/'

type Props = { editor: Editor | null }

export type CommandMenuRef = CommandMenuKeyRef & {
  onEditorUpdate: (newState: EditorState, oldState?: EditorState) => void
}

export const CommandMenuWrapperUnforwarded: React.ForwardRefRenderFunction<CommandMenuRef, Props> = ({ editor }, ref) => {
  const lastOpenPos = useRef<{
    nodePos: number
    slashPos: number
  }>()
  const [search, setSearchRaw] = useState('')
  const [nodePos, setNodePos] = useState<number>()
  const [tranistionPending, startTransition] = useTransition()
  const setSearch = (query: string) => {
    startTransition(() => {
      setSearchRaw(query)
    })
  }
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const closeMenu = useCallback(() => {
    lastOpenPos.current = undefined
    setMenuIsOpen(false)
    setSearch('')
  },
    [setMenuIsOpen, editor],
  )

  const menuWrapperRef = useRef<HTMLDivElement>()
  const commandKeyRef = useRef<CommandMenuKeyRef>()
  const [targetElement, setTargetElement] = useState<HTMLBaseElement>()

  useEffect(() => {
    if (menuWrapperRef.current && targetElement) {
      const el = menuWrapperRef.current
      const rect = targetElement.getBoundingClientRect()
      const top = rect.top + window.scrollY + 10
      const left = rect.left + window.scrollX
      // TODO: update based on position (above instead of below if near bottom)
      // TODO: dynamic height 
      // TODO: update on scroll height and pos on scroll
      const maxHeight = 400
      el.style.top = `${top}px`
      el.style.left = `${left}px`
      el.style.maxHeight = `${maxHeight}px`
    }
  }, [editor, targetElement])

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (event) => {
          return commandKeyRef.current?.onKeyDown(event) || false
        },
        onEditorUpdate: (state, oldState) => {
          const nodeText = getSelectedBlockText(state.selection.$anchor)
          const nodePos = state.selection.$anchor.posAtIndex(0)
          const node = VeNode.fromResolvedPosition(state.selection.$anchor)
          const domAtPos = typeof node?.pos() === 'number' && editor?.view.domAtPos(node.pos())?.node
          // const domAtPos = editor?.view.nodeDOM(state.selection.$anchor.pos)
          if (domAtPos && nodeText && typeof nodePos === 'number') {
            setTargetElement(domAtPos as HTMLBaseElement)
            setNodePos(nodePos)
            const positionBeforeAnchor = state.selection.anchor - 1
            const isValidRange = typeof oldState?.doc.content.size !== 'undefined' && positionBeforeAnchor >= 0 && positionBeforeAnchor <= oldState?.doc.content.size
            const oldText = !isValidRange ? '' : (getSelectedBlockText(oldState?.doc.resolve(positionBeforeAnchor)) || '')
            if (nodeText === oldText + '/') {
              lastOpenPos.current = {
                nodePos,
                slashPos: nodeText.length - 1,
              }
              setSearch('')
              setMenuIsOpen(true)
              return
            } else if (lastOpenPos.current) {
              // if currently open, make sure we're on the same node still, 
              // and that the slash is still there in the same spot
              if (nodePos === lastOpenPos.current.nodePos && isCommandChar([...nodeText][lastOpenPos.current.slashPos])) {
                const textAfterSlash = nodeText.substring(lastOpenPos.current.slashPos + 1)
                setSearch(textAfterSlash)
                setMenuIsOpen(true)
                return
              }
            }
          }
          if (lastOpenPos.current) {
            closeMenu()
            lastOpenPos.current = undefined
          }
          setMenuIsOpen(false)
        }
      }
    },
    [setMenuIsOpen],
  )

  if (!editor) return null

  return createPortal(
    <div
      className="command-menu"
      ref={ref => menuWrapperRef.current = ref || undefined}
      style={{
        visibility: menuIsOpen ? 'visible' : 'hidden',
      }}
    >
      {
        <CommandMenuContent
          key={`${search}:${nodePos}`}
          menuIsOpen={menuIsOpen}
          closeMenu={closeMenu}
          search={search}
          transitionPending={tranistionPending}
          editor={editor}
          ref={ref => commandKeyRef.current = ref || undefined}
        />
      }
    </div>,
    document.body
  )
}


export const CommandMenuWrapper = forwardRef(CommandMenuWrapperUnforwarded)
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { firstColumnCellStyling, tableCellStyling } from './styles';
import { CompareTableHeader_document$key } from './__generated__/CompareTableHeader_document.graphql';

const fragmentQL = graphql`
  fragment CompareTableHeader_document on Document {
    title
    comparison {      
      comparedDocuments{
        id
        title
      }
    }
  }
`

type Props = { document: CompareTableHeader_document$key; }

export function CompareTableHeader({ document: documentKey }: Props) {

  const document = useFragment(fragmentQL, documentKey)
  return (
    <thead>
      <tr>
        <th css={firstColumnCellStyling}>
          {document.title}
        </th>
        {
          document.comparison.comparedDocuments.map(document =>
            <th
              css={tableCellStyling}
              key={document.id}>
              {document.title}
            </th>
          )
        }
      </tr>
    </thead>
  )
}
/**
 * @generated SignedSource<<0e6dac7f47dae585b27048f73207bdcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOpportunityInput = {
  description?: string | null;
  title?: string | null;
};
export type useCreateOpportunityMutation$variables = {
  input: CreateOpportunityInput;
  updateConnectionIds: ReadonlyArray<string>;
};
export type useCreateOpportunityMutation$data = {
  readonly createOpportunity: {
    readonly description: string | null;
    readonly id: string;
    readonly title: string | null;
  };
};
export type useCreateOpportunityMutation = {
  response: useCreateOpportunityMutation$data;
  variables: useCreateOpportunityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateConnectionIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "Opportunity",
  "kind": "LinkedField",
  "name": "createOpportunity",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateOpportunityMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateOpportunityMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createOpportunity",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "updateConnectionIds"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "OpportunityEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "419b752a45ff92302007818283bf9c5e",
    "id": null,
    "metadata": {},
    "name": "useCreateOpportunityMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateOpportunityMutation(\n  $input: CreateOpportunityInput!\n) {\n  createOpportunity(input: $input) {\n    id\n    title\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "67b4ce5fdca72ca691dfc4468ff059ab";

export default node;

import { css } from '@emotion/react';
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { Feature } from '../../../components/Features';
import { IconTitleButton } from '../../../components/IconTitleButton';
import { DocumentItemMenu } from '../DocumentItemMenu';
import { GuideInterviewList } from './GuideInterviewList';
import { GuideListItem_document$key } from './__generated__/GuideListItem_document.graphql';
import {
  IoMdGrid as CompareIcon
} from 'react-icons/io'
import { Card } from '../../../components/Card';
import { useNewDocumentMutation } from './NewDocumentButton';

const fragmentQL = graphql`
  fragment GuideListItem_document on Document {
    id
    title
    description
    ...GuideInterviewList_document
  }
`

type Props = {
  document: GuideListItem_document$key
  connectionIds: string[]
};
export function GuideListItem({
  document: documentKey,
  connectionIds,
}: Props) {
  const document = useFragment(fragmentQL, documentKey)
  const [copyDocument, _isCopying] = useNewDocumentMutation()

  return (
    <Card>
      <Feature
        title={document.title || 'Untitled'}
        description={document.description}
      >
        <div css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
 
        `}>
          <div
            onClick={(event) => {
              event.stopPropagation()
              window.location.href = `/compareDocument/${document.id}`
            }}
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right:20px;
              top: 2px;
              position: relative;
              cursor: pointer;
            &:hover {
              opacity: 0.4;
             }
          `}>
            <CompareIcon
              color='#999'
              size={20}
            />
            <h6 css={css`
              top: -1px;
              position: relative;
              margin-left: 5px;
              color: #999;
            `}>
              Compare Notes
            </h6>
          </div>
          <DocumentItemMenu
            documentId={document.id}
            connectionIds={connectionIds}
            includeNewInterview={true}
          />
        </div>
      </Feature>
      <IconTitleButton
        title={'Interview Guide'}
        hasFocusBrackets
        color={'gray'}
        css={css`
          color: #999;
          text-transform: uppercase;
        `}
        onClick={(event) => {
          const url = `/document/${document.id}`
          if (event.metaKey)
            window.open(url, '_blank')
          else
            window.location.href = url
        }}
      />
      <IconTitleButton
        inverted
        title={'Start a new interview'}
        subtitle={'Today'}
        color={'magenta'}
        css={css`
          color: #999;
          text-transform: uppercase;
        `}
        onClick={(event) => {
          copyDocument({ type: 'interview', createFromDocId: document.id })
        }}
      />
      <GuideInterviewList document={document} />
    </Card>
  )
}
import { OpportunityDocumentSideMenu_opportunity$key } from './__generated__/OpportunityDocumentSideMenu_opportunity.graphql';
import { css } from "@emotion/react"
import { useColorModeValue } from "@chakra-ui/react"
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react'

type Props = {
  opportunity?: OpportunityDocumentSideMenu_opportunity$key;
  selectedDocId: string
  documentPosition: any;
}

export function DynamicPrompt({
  opportunity: opportunityKey,
  selectedDocId,
  documentPosition
}: Props) {

  const menuBorderColor = useColorModeValue('#EEE', '#0A0A0A')
  const menuBackgroundColor = useColorModeValue('#f9f9f9', 'black')

  const [promptText, setPromptText] = useState('')

  useEffect(() => {
    setPromptText('')
    switch (documentPosition.type) {
      case 'emptyTitle':
        setPromptText("Draft a quick title for a problem you're solving for your customers")
        break;
      case 'title':
        setPromptText('Hit TAB to add a sentence or two describing the opportunity')
        break;
      case 'emptyDescription':
        setPromptText('')
        break;
      case 'description':
        setPromptText('Press CMD + ENTER to have Verdi start drafting')
        break;
      case 'tipTap':
        setPromptText('Edit, and press CMD + ENTER to have Verdi draft the next section')
        break;
      case 'emptyTipTap':
        setPromptText('')
        break;
      default:
        setPromptText('')
    }
  }, [documentPosition])


  return (
    <div
      css={css`
      padding: 20px;
      display: flex;
      flex-direction: column;
      min-width: 220px;
      max-width: 320px;
      position: relative;
      flex: 1;
      border-left: 1px solid ${menuBorderColor};
      background-color: ${menuBackgroundColor};
      height: 100%;
    `}>
      <div css={css`position: absolute; top: ${documentPosition.verticalPosition}px`}>

        <div css={css`display: flex; padding: 0;`}> <ChevronLeftIcon fontSize={25} margin={0} /> <p>{promptText}</p></div>
      </div>

    </div >
  )
}

import graphql from 'babel-plugin-relay/macro'
import {
  NewDocumentButtonMutation,
  DocumentType,
  CreateDocumentInput,
  NewDocumentButtonMutation$data,
} from './__generated__/NewDocumentButtonMutation.graphql';
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../../utility-hooks/useErrorHandledMutation';
import { IconTitleButton } from '../../../components/IconTitleButton';
import { css } from '@emotion/react';
import { Card } from '../../../components/Card';

const mutationQL = graphql`
  mutation NewDocumentButtonMutation (
    $input: CreateDocumentInput!
  ) {
   createDocument(input: $input){
    id
    title 
    description 
    type
    opportunities{
      ...OpportunityDocumentSideMenu_opportunity
    }
   }    
  }
`

type Props = {
  text: string
  type: DocumentType,
  createFromDocId?: string
};

export function useNewDocumentMutation(
  navigateOnSave = true,
  onCompleted?: (response: NewDocumentButtonMutation$data['createDocument']) => void
) {
  const [commit, isSaving] = useErrorHandledMutation<NewDocumentButtonMutation>(mutationQL)
  const createDocument = useCallback((input: CreateDocumentInput,) => {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (onCompleted) {
          onCompleted(response.createDocument)
        }
        if (response?.createDocument?.id && navigateOnSave) {
          window.location.href = `document/${response.createDocument.id}`
        }
      }
    })
  }, [])
  return [createDocument, isSaving] as const
}

export function NewDocumentButton({
  text,
  createFromDocId,
  type,
}: Props) {

  const [createDocument, isSaving] = useNewDocumentMutation()
  return (
    <Card
      onClick={(event) => {
        event.preventDefault()
        createDocument({ type, createFromDocId })
      }}
    >
      <IconTitleButton
        title={`Start a new guide ${isSaving ? ' (loading...)' : ''}`}
        hasFocusBrackets
        color={'gray'}
        inverted
        iconSize={17}
        topMargin={false}
        css={css`
          color: #999;
          font-size: 33px;
          font-weight: normal;
        `}
      />
    </Card>
  )
}
import graphql from 'babel-plugin-relay/macro'
import { useRouter } from 'found'
import { useRef, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { DocumentSettingsEditor } from './DocumentSettingsEditor'
import { DocumentEditorQuery } from './__generated__/DocumentEditorQuery.graphql'
import { css } from '@emotion/react'
import { DocumentOriginBanner } from './DocumentOriginBanner'
import './debug-css.css'
import { ZoomDetailsForDocument, ZoomTranscriptForDocument } from '../zoom/ZoomDetailsForDocument'
import { useUrlParams } from '../../utility-hooks/useUrlParams'
import { Collaborators } from './Collaborators'
import { useDocumentSubscription } from '../../components/documentSubscriptions/useDocumentSubscription'
import { ProsemirrorNodes } from 'shared-constants'
import { TiptapEditor } from './tiptapEditor/TipTapEditor'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react'
import DocumentErrorBoundary from './DocumentErrorBoundary'

export const queryQL = graphql`
  query DocumentEditorQuery ($id: ID!){
    ...TipTapEditor_query
    node(id: $id){
      id
      ... on Document {
        title
        type
        ...DocumentSettingsEditor_document
        ...DocumentOriginBanner_document
        ...TipTapEditor_document
        ...ZoomDetailsForDocument_document
      }
    }
  }
`

const outlineWidth = 300
const mainEditorPadding = 10
const idkWhyFudge = -2
type Props = {
  documentId?: string
  setDocumentPosition: (args: any) => void
}

function UnSuspendedDocumentEditor(props: Props) {
  const [tipTapData, setTipTapData] = useState<ProsemirrorNodes.TipTapDocument | undefined>(undefined)
  const [selectedZoomTimestamp, setSelectedZoomTimestamp] = useState<string>()
  const router = useRouter()
  const documentId = props.documentId || router.match.params['documentId']
  const response = useLazyLoadQuery<DocumentEditorQuery>(queryQL, { id: documentId })
  const document = response.node
  const [autoFocusTitle] = useState(!document?.title?.length)
  const scrollToTranscriptRef = useRef<ZoomTranscriptForDocument>(null)
  const { debug: shouldDebug, zoom: showZoom, collab: collabEnabled } = useUrlParams()
  const documentSubscription = useDocumentSubscription(collabEnabled ? documentId : undefined)


  if (!document)
    throw new Error(`No document found for id`);

  const classNameForDarkModeToggle = useColorModeValue("lightTheme", "darkTheme")

  return (
    <>

      <Modal
        isOpen={
          !!documentSubscription?.mustRefreshMessage?.length
        }
        onClose={() => { }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>🫠 Opps..it broke 🫠</ModalHeader>
          <ModalBody>
            <p>{documentSubscription?.mustRefreshMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => window.location.reload()}>
              Refresh
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

      <DocumentOriginBanner
        document={document}
        leftPadding={mainEditorPadding + idkWhyFudge}
      />
      <div
        className={classNameForDarkModeToggle}
        css={css`
        width: 100%;
        max-width: 100%;
        margin: auto;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        padding: 13px;
      `}>
        <div
          className={shouldDebug ? 'debug' : ''}
          css={css`
          flex: 1;
          padding: ${mainEditorPadding}px;
          min-width: 0;
          padding-bottom: 150px;
      `}>
          <DocumentSettingsEditor
            document={document}
            autoFocusTitle={autoFocusTitle}
            setDocumentPosition={props.setDocumentPosition}
          />
          {
            typeof documentSubscription !== 'undefined' &&
              (!documentSubscription.initialDoc) ? null :
              <div css={css`padding: 20px;`}>
                <TiptapEditor
                  query={response}
                  document={document}
                  setTipTapData={setTipTapData}
                  showDevTools={shouldDebug}
                  selectedZoomTimestamp={selectedZoomTimestamp}
                  scrollToTranscriptRef={scrollToTranscriptRef}
                  documentSubscription={documentSubscription}
                  setDocumentPosition={props.setDocumentPosition}
                />
              </div>
          }
        </div>
        {
          showZoom && document && document.type === "interview" &&
          <ZoomDetailsForDocument document={document} ref={scrollToTranscriptRef} />
        }
        {
          typeof documentSubscription !== 'undefined' && shouldDebug &&
          <Collaborators
            documentId={document.id}
            readyState={documentSubscription.readyState}
            subscriberIds={documentSubscription.subscriberIds}
          />
        }

      </div >
    </>
  )
}

export const DocumentEditor = (props: Props) => (
  <DocumentErrorBoundary>
    <UnSuspendedDocumentEditor {...props} />
  </DocumentErrorBoundary>
)
import graphql from 'babel-plugin-relay/macro'
import {
  useTipTapData_document$data,
  useTipTapData_document$key
} from './__generated__/useTipTapData_document.graphql';
import { useFragment } from 'react-relay/hooks'
import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Mutable } from 'utility-types';
// import { useBlockEditorSaver } from './useBlockEditorSaver';
import { makeBlockEditorReady } from '../convertBlocks';

graphql`
fragment useTipTapData_Block on Block @relay(mask: false) {
  id
  type
  questionType
  text
  level
  sourceBlockId
  originalBlockId
  transcriptTimestamp
}
`

const fragmentQL = graphql`
  fragment useTipTapData_document on Document {
      id
      blocks {
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
      ...useTipTapData_Block @relay(mask: false)
      children{
        ...useTipTapData_Block @relay(mask: false)
      }}}}}}}}}
    }
  }
`

type GraphqlBlockWithoutChildren = Omit<useTipTapData_document$data[`blocks`][number], 'children'>
export type RecursiveGraphQLBlock = GraphqlBlockWithoutChildren & { children?: RecursiveGraphQLBlock[] }

type Props = {
  document?: useTipTapData_document$key | undefined
}

export function useTipTapData({
  document: documentKey,
}: Props) {

  const document = useFragment(fragmentQL, documentKey!)
  const data = useMemo(() => {
    if (!document?.blocks)
      return undefined
    const mutableGraphqlBlockCopies = cloneDeep(document.blocks) as Mutable<RecursiveGraphQLBlock[]>
    const convertedRootChildren = mutableGraphqlBlockCopies.map(graphqlBlock =>
      makeBlockEditorReady(graphqlBlock)
    )
    return convertedRootChildren
  }, [
    // document.blocks
  ])
  return data
}
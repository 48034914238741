import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { css } from '@emotion/react'
import { Card } from '../../../../components/Card'
import { useOnKeyDownCommandMenu } from './useOnKeyDownCommandMenu'
import { AddNewBlockSection } from './AddNewBlockSection'
import { SectionRef } from './CommandMenuSection'
import { AddExistingBlocksSection, queryQL } from './AddExistingBlocksSection'
import { CircularProgress } from '@chakra-ui/react'
import { CommandMenuId } from './CommandMenuSectionItem'
import { useQueryLoader } from 'react-relay'
import { useFetchRelayQuery } from '../../../../utility-hooks/useFetchRelayQuery'
import { AddExistingBlocksSectionQuery } from './__generated__/AddExistingBlocksSectionQuery.graphql'
import { GLOBAL_MAX_NESTING_LEVEL } from './commands'
import { Editor } from '@tiptap/core'
const relayConfig = { fetchPolicy: 'store-or-network' } as const

export type CommandMenuKeyRef = {
  onKeyDown: (event: KeyboardEvent) => boolean
}


type Props = {
  menuIsOpen: boolean
  closeMenu: () => void
  search: string
  transitionPending: boolean
  editor?: Editor | null
}

const CommandMenuContentUnforwarded: React.ForwardRefRenderFunction<CommandMenuKeyRef, Props> =
  ({
    menuIsOpen,
    search,
    transitionPending,
    closeMenu: closeMenuProp,
    editor,
  }: Props, ref) => {
    const menuWrapperRef = useRef<HTMLDivElement | null>(null)

    const hasAddedSlashToDomRef = useRef(false)

    const newBlockSectionRef = useRef<SectionRef>(null)
    const sectionBlockSectionRef = useRef<SectionRef>(null)
    const questionBlockSectionRef = useRef<SectionRef>(null)
    const allSectionRefs = useRef([
      newBlockSectionRef,
      sectionBlockSectionRef,
      questionBlockSectionRef,
    ])
    const [activeSectionIndex, setActiveSectionIndex] = useState(0)
    const getActiveSectionAndIndex = useCallback((
      type: 'previous' | 'next' | 'current' = 'current',
      startingIndex?: number
    ) => {

      const initialIndex = startingIndex ?? activeSectionIndex

      if (type === `current`) {
        return [allSectionRefs.current[initialIndex]?.current, initialIndex] as const
      }
      if (type === `previous`) {
        for (let i = initialIndex - 1; i >= 0; i--) {
          if (allSectionRefs.current[i].current?.length) {
            return [allSectionRefs.current[i].current, i] as const
          }
        }
      }
      if (type === `next`) {
        for (let i = initialIndex + 1; i < allSectionRefs.current.length; i++) {
          if (allSectionRefs.current[i].current?.length) {
            return [allSectionRefs.current[i].current, i] as const
          }
        }
      }
      return [null, undefined] as const
    }
      , [activeSectionIndex])
    const [index, setIndex] = useState(0)


    const closeMenu = () => {
      hasAddedSlashToDomRef.current = false
      setActiveSectionIndex(0)
      setIndex(0)
      closeMenuProp()
    }

    const incrementIndex = useCallback(() => {
      const [activeSection] = getActiveSectionAndIndex()
      if (activeSection) {
        const [nextSection, nextSectionIndex] = getActiveSectionAndIndex('next')
        const incrementedIndex = index + 1
        const incrementingPastSection = incrementedIndex >= activeSection.length
        if (incrementingPastSection) {
          if (nextSection && nextSection?.length) {
            setIndex(0)
            setActiveSectionIndex(nextSectionIndex)
          } else {
            // do nothing, just stay at the end of the list
          }
        } else {
          setIndex(incrementedIndex)
        }
      }
    }, [setIndex, index, getActiveSectionAndIndex, activeSectionIndex])

    const decrementIndex = useCallback(
      () => {
        if (index === 0) {
          const [previousSection, previousSectionIndex] = getActiveSectionAndIndex('previous')
          if (previousSection?.length) {
            setIndex(previousSection.length - 1)
            setActiveSectionIndex(previousSectionIndex)
          } else {
            // do nothing...just keep things where they are
          }
        } else {
          setIndex(index - 1)
        }
      }, [setIndex, index, getActiveSectionAndIndex, setActiveSectionIndex])

    const enterPressed = useCallback(() => {
      const [activeSection] = getActiveSectionAndIndex()
      if (activeSection && editor) {
        activeSection.insertElement(editor)
        closeMenu()
      }
    }, [closeMenu, getActiveSectionAndIndex]
    )


    useEffect(() => {
      const [section] = getActiveSectionAndIndex()
      if (!section) {
        return // we haven't initialized our refs yet
      }
      const selectionNowOutOfRange = !section?.length || index >= section?.length
      if (selectionNowOutOfRange) {
        const [firstSectionWithItems, firstSectionWithItemsIndex] = getActiveSectionAndIndex('next', -1)
        if (firstSectionWithItems) {
          setActiveSectionIndex(firstSectionWithItemsIndex)
          setIndex(0)
        }
      }
    }, [allSectionRefs, activeSectionIndex, index, transitionPending])

    const { onKeyDown } = useOnKeyDownCommandMenu(
      menuIsOpen,
      closeMenu,
      incrementIndex,
      decrementIndex,
      enterPressed,
    )

    useImperativeHandle(ref, () => ({
      onKeyDown,
    }), [onKeyDown])

    const nestingLevel = 1
    // const nestingLevel = useMemo(() => {
    //   const focus = currentSelection?.focus
    //   if (!focus) return undefined
    //   const nestingLevel = getNestingLevel(editor, focus.path, 'UserFacing')
    //   return nestingLevel
    // }, [currentSelection])

    const [sectionQueryRef, loadSectionQuery, disposeSectionQuery] = useQueryLoader<AddExistingBlocksSectionQuery>(queryQL)
    const [questionQueryRef, loadQuestionQuery, disposeQuestionQuery] = useQueryLoader<AddExistingBlocksSectionQuery>(queryQL)

    const [fetchSectionQuery, isSectionQueryLoading] = useFetchRelayQuery(queryQL)
    const [fetchQuestionQuery, isQuestionQueryLoading] = useFetchRelayQuery(queryQL)

    useEffect(() => {
      loadSectionQuery({
        blockType: 'section',
        query: search?.length ? search : undefined,
      }, relayConfig)
      loadQuestionQuery({
        blockType: 'question',
        query: search?.length ? search : undefined,
      }, relayConfig)
      return () => { }
    }, [])

    useEffect(() => {
      fetchSectionQuery({
        blockType: 'section',
        query: search?.length ? search : undefined,
      }, relayConfig)
      fetchQuestionQuery({
        blockType: 'question',
        query: search?.length ? search : undefined,
      }, relayConfig)
    }, [search])


    const [activeSection] = getActiveSectionAndIndex()
    const isLoading = transitionPending || isQuestionQueryLoading || isSectionQueryLoading
    if (!editor) return null

    return (
      <Card
        /* used in items to find the element to check visibility */
        id={CommandMenuId}
        elementRef={menuWrapperRef}
        css={css`
        padding: 0;
        max-width: 450px;
        overflow-y: scroll;
        background-color: #fff;
      `}
      >
        <div css={css`
        position: relative;
        width: 100%;
      `}>
          {
            isLoading &&
            <CircularProgress
              size={5}
              isIndeterminate
              css={css`
              top: 15px;
              right: 15px;
              position: absolute;
          `}
            />
          }
          <AddNewBlockSection
            editor={editor}
            ref={newBlockSectionRef}
            search={search}
            nestingLevel={nestingLevel!}
            selectedIndex={
              activeSection !== newBlockSectionRef.current ? -1 :
                index
            }
          />
          {
            nestingLevel && nestingLevel <= 1 && sectionQueryRef &&
            <AddExistingBlocksSection
              editor={editor}
              ref={sectionBlockSectionRef}
              blockType={'section'}
              preloadedQuery={sectionQueryRef!}
              search={search}
              nestingLevel={nestingLevel!}
              title={'Section Templates'}
              isLoading={isLoading}
              selectedIndex={
                activeSection !== sectionBlockSectionRef.current ? -1 :
                  index
              }
            />
          }
          {
            nestingLevel && nestingLevel < GLOBAL_MAX_NESTING_LEVEL && questionQueryRef &&
            <AddExistingBlocksSection
              editor={editor}
              ref={questionBlockSectionRef}
              blockType={'question'}
              preloadedQuery={questionQueryRef!}
              search={search}
              title={'Question Templates'}
              nestingLevel={nestingLevel!}
              isLoading={isLoading}
              selectedIndex={
                activeSection !== questionBlockSectionRef.current ? -1 :
                  index
              }
            />
          }
        </div>
      </Card>
    )
  }

export const CommandMenuContent = forwardRef(CommandMenuContentUnforwarded)
import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Textarea, useDisclosure } from '@chakra-ui/react';
import graphql from 'babel-plugin-relay/macro'
import { useState } from 'react';
import { useMutation } from 'react-relay/hooks'
import { NewInitiativeMutation } from './__generated__/NewInitiativeMutation.graphql';

const mutationQL = graphql`
  mutation NewInitiativeMutation (
    $input: CreateDocumentInput!
  ) @raw_response_type {
    createDocument(input: $input){
      id
    }
  }
`

type Props = {};

export function NewInitiative(props: Props) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [commit, isSaving] = useMutation<NewInitiativeMutation>(mutationQL)
  const [createFromAIPrompt, setCreateFromAIPrompt] = useState('')
  const [initiativeName, setInitiativeName] = useState('')
  const save = () => {
    commit({
      variables: {
        input: {
          type: 'initiative',
          createFromAIPrompt,
          title: initiativeName,
        }
      },
      onCompleted: (response) => {
        console.log('on created')
        if (response?.createDocument?.id) {
          window.location.href = `document/${response.createDocument.id}`
        }
      }
    })
  }
  return (
    <>
      <Button
        onClick={onOpen}
      >
        + Initiative
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Initiative</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Input
                placeholder='Initiative Name'
                size='md'
                value={initiativeName}
                onChange={(event) => setInitiativeName(event.target.value)}
              />
              <Textarea
                placeholder='Give us a line or two about describing initiative.'
                value={createFromAIPrompt}
                onChange={(event) => setCreateFromAIPrompt(event.target.value)}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'
              isLoading={isSaving}
              loadingText={'Creating...'}
              onClick={save}
              disabled={!initiativeName.length || !createFromAIPrompt.length}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
/**
 * @generated SignedSource<<97959ceb7e75b21eb7548ab8bcad1ee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
export type QuestionType = "freeResponse" | "multipleChoice" | "numeric" | "picture" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddExistingBlocksSection_query$data = {
  readonly blockConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly children: ReadonlyArray<{
          readonly children: ReadonlyArray<{
            readonly children: ReadonlyArray<{
              readonly children: ReadonlyArray<{
                readonly children: ReadonlyArray<{
                  readonly children: ReadonlyArray<{
                    readonly children: ReadonlyArray<{
                      readonly children: ReadonlyArray<{
                        readonly children: ReadonlyArray<{
                          readonly id: string;
                          readonly level: number;
                          readonly originalBlockId: string;
                          readonly questionType: QuestionType | null;
                          readonly sourceBlockId: string | null;
                          readonly text: string;
                          readonly type: BlockType;
                        }>;
                        readonly id: string;
                        readonly level: number;
                        readonly originalBlockId: string;
                        readonly questionType: QuestionType | null;
                        readonly sourceBlockId: string | null;
                        readonly text: string;
                        readonly type: BlockType;
                      }>;
                      readonly id: string;
                      readonly level: number;
                      readonly originalBlockId: string;
                      readonly questionType: QuestionType | null;
                      readonly sourceBlockId: string | null;
                      readonly text: string;
                      readonly type: BlockType;
                    }>;
                    readonly id: string;
                    readonly level: number;
                    readonly originalBlockId: string;
                    readonly questionType: QuestionType | null;
                    readonly sourceBlockId: string | null;
                    readonly text: string;
                    readonly type: BlockType;
                  }>;
                  readonly id: string;
                  readonly level: number;
                  readonly originalBlockId: string;
                  readonly questionType: QuestionType | null;
                  readonly sourceBlockId: string | null;
                  readonly text: string;
                  readonly type: BlockType;
                }>;
                readonly id: string;
                readonly level: number;
                readonly originalBlockId: string;
                readonly questionType: QuestionType | null;
                readonly sourceBlockId: string | null;
                readonly text: string;
                readonly type: BlockType;
              }>;
              readonly id: string;
              readonly level: number;
              readonly originalBlockId: string;
              readonly questionType: QuestionType | null;
              readonly sourceBlockId: string | null;
              readonly text: string;
              readonly type: BlockType;
            }>;
            readonly id: string;
            readonly level: number;
            readonly originalBlockId: string;
            readonly questionType: QuestionType | null;
            readonly sourceBlockId: string | null;
            readonly text: string;
            readonly type: BlockType;
          }>;
          readonly id: string;
          readonly level: number;
          readonly originalBlockId: string;
          readonly questionType: QuestionType | null;
          readonly sourceBlockId: string | null;
          readonly text: string;
          readonly type: BlockType;
        }>;
        readonly id: string;
        readonly level: number;
        readonly originalBlockId: string;
        readonly questionType: QuestionType | null;
        readonly sourceBlockId: string | null;
        readonly text: string;
        readonly type: BlockType;
      };
    }>;
  };
  readonly " $fragmentType": "AddExistingBlocksSection_query";
};
export type AddExistingBlocksSection_query$key = {
  readonly " $data"?: AddExistingBlocksSection_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddExistingBlocksSection_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalBlockId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceBlockId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "blockType"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddExistingBlocksSection_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "blockType",
          "variableName": "blockType"
        },
        {
          "kind": "Literal",
          "name": "documentType",
          "value": "guide"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Literal",
          "name": "oldestSharedOrigin",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": "QueryBlockConnection",
      "kind": "LinkedField",
      "name": "blockConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueryBlockConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Block",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Block",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Block",
                      "kind": "LinkedField",
                      "name": "children",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Block",
                          "kind": "LinkedField",
                          "name": "children",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Block",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v4/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Block",
                                  "kind": "LinkedField",
                                  "name": "children",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/),
                                    (v2/*: any*/),
                                    (v3/*: any*/),
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Block",
                                      "kind": "LinkedField",
                                      "name": "children",
                                      "plural": true,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        (v2/*: any*/),
                                        (v3/*: any*/),
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "Block",
                                          "kind": "LinkedField",
                                          "name": "children",
                                          "plural": true,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v1/*: any*/),
                                            (v2/*: any*/),
                                            (v3/*: any*/),
                                            (v4/*: any*/),
                                            (v5/*: any*/),
                                            (v6/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "Block",
                                              "kind": "LinkedField",
                                              "name": "children",
                                              "plural": true,
                                              "selections": [
                                                (v0/*: any*/),
                                                (v1/*: any*/),
                                                (v2/*: any*/),
                                                (v3/*: any*/),
                                                (v4/*: any*/),
                                                (v5/*: any*/),
                                                (v6/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "Block",
                                                  "kind": "LinkedField",
                                                  "name": "children",
                                                  "plural": true,
                                                  "selections": [
                                                    (v0/*: any*/),
                                                    (v1/*: any*/),
                                                    (v2/*: any*/),
                                                    (v3/*: any*/),
                                                    (v4/*: any*/),
                                                    (v5/*: any*/),
                                                    (v6/*: any*/)
                                                  ],
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2c031440376e1b5c22bbd0b29c714fda";

export default node;

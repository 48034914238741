/**
 * @generated SignedSource<<218de4f410be8259a4fe78cb66a648bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsQuery$variables = {};
export type DocumentsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GuideConnection_query">;
};
export type DocumentsQuery = {
  response: DocumentsQuery$data;
  variables: DocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "GuideConnection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DocumentsQuery",
    "selections": [
      {
        "alias": "guideConnection",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          },
          {
            "kind": "Literal",
            "name": "type",
            "value": "guide"
          }
        ],
        "concreteType": "QueryDocumentConnection",
        "kind": "LinkedField",
        "name": "documentConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryDocumentConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "copies",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "documentConnection(first:100,type:\"guide\")"
      }
    ]
  },
  "params": {
    "cacheID": "ffefcfedb4c6f0be77f1de3f9f7f1f30",
    "id": null,
    "metadata": {},
    "name": "DocumentsQuery",
    "operationKind": "query",
    "text": "query DocumentsQuery {\n  ...GuideConnection_query\n}\n\nfragment GuideConnection_query on Query {\n  guideConnection: documentConnection(first: 100, type: guide) {\n    edges {\n      node {\n        id\n        ...GuideListItem_document\n      }\n    }\n  }\n}\n\nfragment GuideInterviewListItem_document on Document {\n  id\n  title\n  createdAt\n}\n\nfragment GuideInterviewList_document on Document {\n  copies {\n    id\n    ...GuideInterviewListItem_document\n  }\n}\n\nfragment GuideListItem_document on Document {\n  id\n  title\n  description\n  ...GuideInterviewList_document\n}\n"
  }
};
})();

(node as any).hash = "0f08a32992ac218b6f13748d150cf890";

export default node;

import { CircularProgress } from "@chakra-ui/react"


const LoadingSpinner = () => {


  return <div>
    <CircularProgress isIndeterminate title="Loading" />
  </div>
}

export default LoadingSpinner
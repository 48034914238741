import { Editor } from "@tiptap/react";
import { getVersion } from "prosemirror-collab";
import React, { useEffect, useRef } from "react";
import { DocumentSubscription } from "../../../components/documentSubscriptions/useDocumentSubscription";
import { removeAllAttributionMarksTransaction } from "../tiptapEditor/extensions/clearAIEditMarksPlugin";
import { settingsForDocumentTypes, DocumentType } from "../tiptapEditor/documentType/settingsForDocumentType";
import {
  defaultPrompt,
  generatePrompt,
  PromptParams,
  SharedContext,
} from "../tiptapEditor/documentType/aiPrompts/prompt";

type Props = {
  documentSubscription: DocumentSubscription
  editor: Editor | null
  documentType: DocumentType
  sharedContext: SharedContext
};

export function useGenerateNext({
  documentSubscription,
  editor,
  documentType,
  sharedContext,
}: Props) {

  const stepIndexRef = useRef(1)

  useEffect(() => {
    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (event.metaKey && event.key === 'Enter') {
        if (!editor) return

        const settings = settingsForDocumentTypes(documentType)
        if (settings.disableAiPrompts) {
          console.info("AI Prompts are not enabled for this document")
          return
        }

        const tr = removeAllAttributionMarksTransaction(editor.state)
        const newState = editor.state.apply(tr)
        editor.view.updateState(newState)

        const documentVersion = getVersion(editor.state)
        const endOfDocInsertPoints = editor.state.doc.content.size
        const textContent = editor.state.doc.textContent

        const params: PromptParams = {
          context: sharedContext,
          paragraphNumber: stepIndexRef.current,
          existingDocumentText: textContent,
        }
        const promptInfo = settings.prompt ? generatePrompt(params, settings.prompt) : defaultPrompt(params)
        if (promptInfo === false) {
          // we're done
          return
        }
        console.log('generating prompt', prompt)
        documentSubscription?.makeAIEditRequest(
          documentVersion,
          promptInfo.prompt,
          endOfDocInsertPoints,
          'openAIParams' in promptInfo ? promptInfo.openAIParams : undefined
        )
        stepIndexRef.current = stepIndexRef.current + 1
      }
    }

    window.addEventListener("keydown", handleKeyPress as any);
    return () => {
      window.removeEventListener("keydown", handleKeyPress as any);
    }
  }, [editor])

}
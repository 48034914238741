import { cloneDeep } from 'lodash'
import { Slice, Fragment, Schema } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'
import { findParentNestableNodeClosestToPos } from '../../utils/findParentNestableNode'
import { ProsemirrorNodes } from 'shared-constants'

type SliceNode = { attrs?: ProsemirrorNodes.Attributes, content: SliceNode[] }
const attributesToRemove = ['id', 'sourceBlockId', 'originalBlockId', 'transcriptTimestamp'] as Array<keyof ProsemirrorNodes.Attributes>

const recursivelyRemoveIds = (node: SliceNode) => {
  const nodeCopy = cloneDeep(node)
  if ('attrs' in nodeCopy) {
    for (const attribute of attributesToRemove) {
      delete nodeCopy.attrs?.[attribute]
    }
  }
  if (Array.isArray(nodeCopy.content)) {
    const content = nodeCopy.content.map(recursivelyRemoveIds)
    nodeCopy.content = content
    return nodeCopy
  } else {
    return nodeCopy
  }
}

export const transformPasted = (slice: Slice, schema: Schema) => {
  const content = slice.content
  const jsonContent = content.toJSON()
  const newJson = jsonContent.map(recursivelyRemoveIds)
  const transformedFragment = Fragment.fromJSON(schema, newJson)
  return new Slice(transformedFragment, slice.openStart, slice.openEnd)
}

const distanceFromTitleToNestableWrapper = 2
const getNestableNodeFromSlice = (slice: Slice) => {
  const firstChild = slice.content.firstChild
  if (!firstChild) return undefined
  const resolvedNestableNode = firstChild.resolve(slice.openStart - distanceFromTitleToNestableWrapper)
  return resolvedNestableNode
}

export const handlePaste = (view: EditorView, event: ClipboardEvent, slice: Slice) => {
  const selection = view.state.selection
  const nestableParent = findParentNestableNodeClosestToPos(selection.$head)
  const nestableInSlice = getNestableNodeFromSlice(slice)
  const incomingType = nestableInSlice?.node().type
  // TODO: See if there are adjustments we should make to the non-single block case
  if (!selection.empty) return false
  if (!nestableParent) return false
  if (nestableParent.node.type.name !== 'freeText') return false
  if (!incomingType || incomingType.name === 'freeText') return false

  view.dispatch(view.state.tr.setNodeMarkup(nestableParent.pos, incomingType))
  return false // still let prosemirror handle the paste
}
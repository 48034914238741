import graphql from 'babel-plugin-relay/macro';
import { OpportunityDocumentSideMenu_opportunity$key } from './__generated__/OpportunityDocumentSideMenu_opportunity.graphql';
import { useLazyLoadQuery } from 'react-relay';
import { SideMenuQuery } from './__generated__/SideMenuQuery.graphql'
import { css } from "@emotion/react"
import verdiLogo from '../../components/logo/verdi-logo.svg'
import { NewOpportunityModal } from './NewOpportunityModal';
import { OpportunityDocumentSideMenu } from './OpportunityDocumentSideMenu';
import { Button, useColorMode, IconButton, useColorModeValue, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react"
import { MoonIcon, SunIcon } from '@chakra-ui/icons';


const queryQL = graphql`
  query SideMenuQuery {
    opportunityConnection {
      __id
      edges {
        node {
          title
          id
          documents {
            id
            title
          }
          interviewGuides: documents(type: guide) {
            id
            title
            copies {
              id
              title
            }
          }
          ... OpportunityDocumentSideMenu_opportunity
        }
        
      }
    }
  }
`

type Props = {
  opportunity?: OpportunityDocumentSideMenu_opportunity$key;
  selectedDocId: string
}


export function SideMenu({
  opportunity: opportunityKey,
  selectedDocId,
}: Props) {

  const { colorMode, toggleColorMode } = useColorMode()
  const menuBorderColor = useColorModeValue('#EEE', '#0A0A0A')
  const menuFontColorActive = useColorModeValue('black', 'white')
  const menuBackgroundColor = useColorModeValue('#f9f9f9', 'black')

  const query = useLazyLoadQuery<SideMenuQuery>(queryQL, {})
  const opportunities = query.opportunityConnection.edges

  let expandedMenuIndex = opportunities.findIndex(opp => opp.node.documents?.find(doc => doc.id === selectedDocId))
  let expandedMenuIsDiscovery = false
  if (!expandedMenuIndex) {
    expandedMenuIndex = opportunities.findIndex(opp => opp.node.interviewGuides?.find(doc => doc.copies?.find(c => c.id === selectedDocId)))
    expandedMenuIsDiscovery = true
  }

  return (
    <div
      css={css`
      padding: 20px;
      display: flex;
      flex-direction: column;
      min-width: 220px;
      max-width: 320px;
      width: 20vw;
      height: calc(100vh - 10px);
      overflow-y: auto;
      position: relative;
      flex: 1;
      background-color: ${menuBackgroundColor};
      border-right: 1px solid ${menuBorderColor};
    `}>

      <img
        css={css`
          filter: grayscale(1);
          opacity: 0.6;
          margin-bottom: 45px;
          width:150px;
        `}
        src={verdiLogo}
      />

      <Accordion allowMultiple borderBottom='1px solid #eee;'
        marginBottom={2} defaultIndex={[expandedMenuIndex ? expandedMenuIndex : 0]}>
        {opportunities && opportunities.map((opportunity, index) =>
          <AccordionItem key={index} marginLeft={-4} marginRight={-4} border='none'>
            <h2>
              <AccordionButton color='grey' _expanded={{ color: menuFontColorActive }}>
                <Box flex='1' textAlign='left' lineHeight={1} fontWeight='bold'>
                  {opportunity.node.title}
                </Box>
                <AccordionIcon opacity={0.3} />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OpportunityDocumentSideMenu
                opportunity={opportunity.node}
                selectedDocId={selectedDocId}
                shouldExpandInterviewGuide={expandedMenuIsDiscovery && index === expandedMenuIndex}
              />
            </AccordionPanel>
          </AccordionItem>

        )}
      </Accordion>

      <NewOpportunityModal updateConnectionIds={[query.opportunityConnection.__id]} />

      <div css={css`
        flex: 1;
      
      `} />

      <div css={css`display: flex; justify-content: space-between; margin-top: 40px;`}>
        <Button
          size='sm'
          variant='ghost'
          justifyContent='left'
          color='GrayText'
          onClick={() => {
            window.location.href = '/logout'
          }}>
          Log out
        </Button>
        <IconButton variant="ghost" aria-label="Toggle Mode" onClick={toggleColorMode} w="fit-content">
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </IconButton>
      </div>

    </div >
  )
}

import { CircularProgress } from '@chakra-ui/react'
import { createPortal } from 'react-dom'

type Props = {
  isSaving?: boolean
}

const elementId = `loading-corner`

export const LoadingIndicatorPortal = ({ isSaving = false }: Props) => {
  if (!isSaving) return null
  const element = document.getElementById(elementId)
  if (!element) throw `There is no element ${elementId}`;
  return createPortal(
    // <div>saving...</div>
    <CircularProgress
      size={`25px`}
      isIndeterminate
    />
    ,
    element
  );
} 
import { css } from "@emotion/react"
import { useEffect, useRef } from "react"

type Props = {
  index: number
  selectedIndex: number
  title: string
  onClick: () => void
  description?: string
  disabled?: boolean
}

export const CommandMenuId = 'THE_COMMAND_MENU'

const isElementVisible = (element: HTMLLIElement) => {
  const rect = element.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  // todo: get the right parent
  const container = document.getElementById(CommandMenuId)
  const parentRect = container?.getBoundingClientRect()
  if (!parentRect || typeof parentRect === 'undefined') {
    console.error(`Parent with id ${CommandMenuId} not found!`)
    return
  }
  const isVisible = (elemTop >= 0) && elemTop > parentRect.top && (elemBottom <= parentRect.bottom);
  return isVisible;
}



export const CommandMenuSectionItem = ({
  index,
  selectedIndex,
  title,
  description,
  disabled,
  onClick,
}: Props) => {
  const isSelected = selectedIndex === index
  const backgroundColor = isSelected ? 'rgba(200,205,210,0.15)' : undefined
  const ref = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isSelected && ref.current) {
      const element = ref.current
      const isVisible = isElementVisible(element)
      if (!isVisible) {
        element.scrollIntoView({
          // behavior: "smooth",
          block: 'nearest',
        })
      }
    }
  }, [isSelected])


  return (
    <li
      ref={ref}
      onClick={onClick}
      css={css`
        background: ${backgroundColor};
        display: flex;
        align-items: center;
        padding: 2px 10px;
        font-size: 18px;
        &:hover{
          background-color: rgba(200,205,210,0.10)
        }
      `}
    >
      <span css={css`
        color: ${!title.length ? '#ccc' : '#333'};
        font-weight: bold;
      `}>
        {title || 'Untilted'}
      </span>
      &nbsp;
      <span
        css={css`
          opacity: 0.25;
        `}>
        {disabled ? ' (Unavailable)' : description}
      </span>
    </li>
  )
}
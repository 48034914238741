/**
 * @generated SignedSource<<eaa1609b7440caa2875787aa6e1b7f07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
export type QuestionType = "freeResponse" | "multipleChoice" | "numeric" | "picture" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddExistingBlocksSection_block$data = {
  readonly id: string;
  readonly level: number;
  readonly originalBlockId: string;
  readonly questionType: QuestionType | null;
  readonly sourceBlockId: string | null;
  readonly text: string;
  readonly type: BlockType;
};
export type AddExistingBlocksSection_block$key = {
  readonly " $data"?: AddExistingBlocksSection_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddExistingBlocksSection_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "AddExistingBlocksSection_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalBlockId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceBlockId",
      "storageKey": null
    }
  ],
  "type": "Block",
  "abstractKey": null
};

(node as any).hash = "31ae5956458c26583922c7f5d107ffe9";

export default node;

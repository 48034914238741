import { Editor } from "@tiptap/core"
import { getSelectedBlockChildren, getSelectedBlockText } from "../utils/getSelectedBlockText"
import { ProsemirrorNodes } from 'shared-constants'
import { SLASH_COMMANDS } from "./commands"


export const defaultContentForType = (commandType: typeof SLASH_COMMANDS[number]['type']) => {
  switch (commandType) {
    case 'section':
      return ProsemirrorNodes.makeSectionBlock('')
    case 'question':
      return ProsemirrorNodes.makeQuestionBlock('', [ProsemirrorNodes.makeFreeTextBlock('')])
    default:
      break;
  }
}

const textWithoutCommand = (text: string) => {
  const index = text?.lastIndexOf('/')
  if (index === -1) {
    throw new Error(`Expected command string to have slash. Command string: ${text}`)
  }
  const textWithoutCommand = text.substring(0, index)
  return textWithoutCommand
}



export const insertContentForCommand = (editor: Editor, contentToInsert: any) => {
  const text = getSelectedBlockText(editor.state.selection.$head)
  const children = getSelectedBlockChildren(editor.state.selection.$head)
  const leftoverText = textWithoutCommand(text || '')
  if (!leftoverText?.length && !children?.childCount) {
    editor.chain()
      .deleteToLastSlash()
      .replaceNestableBlockNear(contentToInsert)
      .run()
  } else {
    editor.chain()
      .deleteToLastSlash()
      .insertNestableBlockNear(contentToInsert)
      .run()
  }
}

import { ContentNodeWithPos, findParentNodeClosestToPos } from "prosemirror-utils";
import { ResolvedPos, Node, NodeType, DOMSerializer, Fragment, Slice } from "prosemirror-model";
import { EditorState } from 'prosemirror-state';

import isEqual from 'lodash/isEqual';

const TAB_CHAR = '  ';

export default class VeNode {
  static NESTABLE_TYPES: String[] = ['question', 'section', 'freeText'];

  _node: ContentNodeWithPos;

  constructor(node: ContentNodeWithPos){
    this._node = node;
  }

  static fromPosition(state: EditorState, pos: number): VeNode | undefined {
    const resolvedPosition = state.doc.resolve(pos);

    return VeNode.fromResolvedPosition(resolvedPosition);
  }

  static fromResolvedPosition(pos: ResolvedPos): VeNode | undefined {
    const contentNode = findParentNodeClosestToPos(pos, () => true);

    if(!contentNode){
      return undefined;
    }

    return new VeNode(contentNode);
  }

  node(): Node {
    return this._node.node;
  }

  pos(): number{
    return this._node.pos;
  }

  type(): NodeType {
    return this._node.node.type;
  }

  textContent(): string {
    return this._node.node.textContent;
  }

  childCount(): number {
    return this._node.node.childCount;
  }

  size(): number {
    return this._node.node.nodeSize;
  }

  bounds() {
    const { pos, node } = this._node;

    return { start: pos, end: pos + node.nodeSize };
  }

  index(state: EditorState) {
    return this.resolvePosition(state).index();
  }

  resolvePosition(state: EditorState): ResolvedPos {
    return state.doc.resolve(this._node.pos);
  }

  findParent(state: EditorState): VeNode | undefined {
    const parent = findParentNodeClosestToPos(this.resolvePosition(state), () => true);

    if(!parent){
      return undefined;
    }

    return new VeNode(parent);
  }

  findParentWithType(state: EditorState, types: String[]): VeNode | undefined {
    const parent = findParentNodeClosestToPos(this.resolvePosition(state), (node: Node) => types.includes(node.type.name) );

    if(!parent){
      return undefined;
    }

    return new VeNode(parent);
  }

  findNestableParent(state: EditorState): VeNode | undefined {
    return this.findParentWithType(state, VeNode.NESTABLE_TYPES);
  }

  findNextSibling(state: EditorState) {
    const parent = this.findParent(state);

    if(parent && parent.childCount() < 2){
      return undefined;
    }

    return VeNode.fromPosition(state, this.bounds().end + 1);
  }

  findAncestors(state: EditorState): VeNode[] {
    const parent = this.findParent(state);

    if(!parent){
      return [];
    }

    return [
      parent,
      ...parent.findAncestors(state),
    ];
  }

  findNestableAncestors(state: EditorState): VeNode[] {
    const parent = this.findNestableParent(state);

    if(!parent){
      return [];
    }

    return [
      parent,
      ...parent.findNestableAncestors(state),
    ];
  }

  findCommonAncestor(state: EditorState, node: VeNode): VeNode | undefined {
    const thisAncestors = [...this.findAncestors(state), this];
    const thatAncestors = [...node.findAncestors(state), node];

    for(let thatPointer = 0; thatPointer < thatAncestors.length; thatPointer += 1){
      for(let thisPointer = 0; thisPointer < thisAncestors.length; thisPointer += 1){
        const { [thisPointer]: thisAncestor} = thisAncestors;
        const { [thatPointer]: thatAncestor} = thatAncestors;

        if(isEqual(thisAncestor.bounds(), thatAncestor.bounds())){
          return thatAncestor;
        }
      }
    }

    return undefined;
  }
  
  isEqualTo(node: VeNode): boolean{
    return isEqual(this.bounds(), node.bounds());
  }

  isAncestorOf(node: VeNode): boolean {
    const thisBounds = this.bounds();
    const thatBounds = node.bounds();

    return thisBounds.start < thatBounds.start && thisBounds.end > thatBounds.end;
  }

  isDescendantOf(node: VeNode): boolean {
    const thisBounds = this.bounds();
    const thatBounds = node.bounds();

    return thatBounds.start < thisBounds.start && thatBounds.end > thisBounds.end;
  }

  isParentOf(state: EditorState, node: VeNode): boolean {
    const parent = node.findParent(state);

    return !!parent && parent.isEqualTo(this);
  }

  isNestableParentOf(state: EditorState, node: VeNode): boolean {
    const parent = node.findNestableParent(state);

    return !!parent && parent.isEqualTo(this);
  }

  isChildOf(state: EditorState, node: VeNode): boolean {
    const parent = this.findParent(state);

    return !!parent && parent.isEqualTo(node);
  }

  isNestableChildOf(state: EditorState, node: VeNode): boolean {
    const parent = this.findNestableParent(state);

    return !!parent && parent.isEqualTo(node);
  }

  toHtml(state: EditorState){
    return DOMSerializer.fromSchema(state.schema).serializeNode(this._node.node);
  }

  private _toString(node: Node, level: number = 0): string{
    const { name } = node.type;

    const tabSpace = Array(level).fill(TAB_CHAR).join('');

    const children: Node[] = [];

    if(!node.isLeaf){
      node.content.forEach((node: Node) => {
        children.push(node);
      });
    }

    return [
      `${tabSpace}<${name}>`, 
      ...(node.isLeaf? 
          [`${tabSpace}${TAB_CHAR}${node.textContent}`] :
          [children.map((child) => this._toString(child, level + 1)).join('\n')]
      ) as string[],
      `${tabSpace}</${name}>`
    ].join('\n');
  }

  toString(): string{
    return this._toString(this._node.node);
  }

  slice(openLeft: number, openRight: number): Slice {
    const fragment = Fragment.from(this._node.node);

    return new Slice(fragment, openLeft, openRight);
  }
}
import { useState } from "react"
import { format } from "date-fns"
import "./ZoomMeetingList.css"
import { fromZoomRecordingsMeetingResponse, ZoomMeeting } from "./Common"



export const ZoomMeetingList = () => {

  const getMeetingsFromZoom = () => {

    fetch(`${process.env.REACT_APP_API_DOMAIN}/zoom/meetings`, {
      method: "GET",
      credentials: "include",
      mode: "cors"
    }).then(res => res.json())
      .then(body => {
        setMeetingList(body.meetings)
        console.log("Meeting list = ", meetingList)
        if (meetingList.length === 0) {
          setErrorMessageForMeetings("No zoom meetings found.")
        }
      })
      .catch(err => {
        console.error(err)
        setErrorMessageForMeetings("Could not load meetings from Zoom")
      })

  }

  const getRecordingsFromZoom = () => {

    fetch(`${process.env.REACT_APP_API_DOMAIN}/zoom/recordings`, {
      method: "GET",
      credentials: "include",
      mode: "cors"
    }).then(res => res.json())
      .then(body => {

        const recordings = fromZoomRecordingsMeetingResponse(body)
        console.log("recordings = ", recordings)

        setRecordingsList(recordings)
        console.log("Recordings list = ", recordingsList)
        if (recordingsList.length === 0) {
          setErrorMessageForRecordings("No cloud recordings found.")
        }
      })
      .catch(err => {
        console.error(err)
        setErrorMessageForRecordings("Could not load cloud recordings from Zoom")
      })

  }

  const importRecording = (meetingToImport: ZoomMeeting) => {
    console.log("Import this one = ", meetingToImport)

    fetch(`${process.env.REACT_APP_API_DOMAIN}/zoom/meetings/import`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(meetingToImport),
      mode: "cors",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(body => {

        console.log("YAY it was imported!")
      })
      .catch(err => {
        console.error(err)
        setErrorMessageForRecordings("Could not import meeting from Zoom")
      })

  }

  const [meetingList, setMeetingList] = useState<any[]>([])
  const [recordingsList, setRecordingsList] = useState<ZoomMeeting[]>([])
  const [errorMessageForMeetings, setErrorMessageForMeetings] = useState("")
  const [errorMessageForRecordings, setErrorMessageForRecordings] = useState("")

  return (
    <div className="pageContents">
      <h1>Zoom meetings</h1>
      <p>
        This is the meetings list
      </p>
      <div>
        <a href="/zoom/connect">Connect your zoom account</a>
      </div>
      <div>
        <h2>
          Meetings
          <button onClick={getMeetingsFromZoom}>Refresh</button>
        </h2>
        <ul>
          {meetingList && meetingList.length > 0 && meetingList.map((m, i) =>
            <li key={i}>
              <div className="listedItem">
                <h3>{m.topic}</h3>
                <p>{format(new Date(m.created_at), "MMM/dd/yy hh:mm aaa")} </p>
              </div>
            </li>
          )}
        </ul>

        <p>{errorMessageForMeetings}</p>
      </div>
      <div>
        <h2>
          Recordings
          <button onClick={getRecordingsFromZoom}>Refresh</button>
        </h2>
        <ul>
          {recordingsList && recordingsList.length > 0 && recordingsList.map((recording, i) =>
            <li key={i}>
              <div className="listedItem">
                <button onClick={() => importRecording(recording)}>Import me</button>
                <h3>{recording.topic} <small>{recording.zoomMeetingId}</small></h3>

                {format(new Date(recording.startTime), "MMM dd, yyyy - hh:mm aaa")} - {recording.durationInMinutes} min
                <div className="statusIcons">
                  <span className={recording.audioRecordingDownloadUrl ? "complete" : ""}>Audio DL</span>
                  <span className={recording.audioRecordingPlayUrl ? "complete" : ""}>Audio Play</span>
                  <span className={recording.closedCaptioningDownloadUrl ? "complete" : ""}>CC DL</span>
                  <span className={recording.closedCaptioningText ? "complete" : ""}>CC TXT</span>
                  <span className={recording.transcriptDownloadUrl ? "complete" : ""}>Tran DL</span>
                  <span className={recording.transcriptPlayUrl ? "complete" : ""}>Tran Play</span>
                  <span className={recording.transcriptText ? "complete" : ""}>Trans TXT</span>
                  <span className={recording.videoRecordingDownloadUrl ? "complete" : ""}>Video DL</span>
                  <span className={recording.videoRecordingPlayUrl ? "complete" : ""}>Video Play</span>

                </div>

              </div>
            </li>
          )}
        </ul>
        <p>{errorMessageForRecordings}</p>
      </div>

    </div>
  )
}
/**
 * @generated SignedSource<<22de9ca12d18dcd5d1b879fda9551105>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "faq" | "guide" | "initiative" | "interview" | "opportunity" | "prd" | "pressRelease" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentOriginBanner_document$data = {
  readonly origin: {
    readonly id: string;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
  readonly " $fragmentType": "DocumentOriginBanner_document";
};
export type DocumentOriginBanner_document$key = {
  readonly " $data"?: DocumentOriginBanner_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentOriginBanner_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentOriginBanner_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "5a4656a620df5d1331aba69fc810587e";

export default node;

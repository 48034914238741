import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react';
import { useMutation } from 'react-relay/hooks'
import { useDeleteDocumentMutation } from './__generated__/useDeleteDocumentMutation.graphql';

const mutationQL = graphql`
  mutation useDeleteDocumentMutation (
    $input: DeleteDocumentInput!
    $connectionIds: [ID!]!
  ) @raw_response_type {
    deleteDocument(input: $input){
      id @deleteEdge(connections: $connectionIds)
      opportunities {
        ...OpportunityDocumentSideMenu_opportunity
      }
    }
  }
`

export function useDeleteDocument(documentId: string, connectionIds: string[] = []) {
  const [commit, isSaving] = useMutation<useDeleteDocumentMutation>(mutationQL)
  const deleteDocument = useCallback(() => {
    const shouldDelete = window.confirm(`Are you really sure you want to delete this document?`)
    if (!shouldDelete) return
    commit({
      variables: {
        input: {
          documentId,
        },
        connectionIds,
      }
    })
  }, [commit])
  return [deleteDocument, isSaving] as const
}
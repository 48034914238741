import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { GuideInterviewListItem } from './GuideInterviewListItem';
import { GuideInterviewList_document$key } from './__generated__/GuideInterviewList_document.graphql';

const fragmentQL = graphql`
  fragment GuideInterviewList_document on Document {
    copies {
      id
      ...GuideInterviewListItem_document
    }
    #  copyConnection(
    #     type: interview
    #   ){
    #     edges {
    #       node{
    #         id
    #         title
    #         createdAt
    #        }
    #     }
    #  }
  }
`

type Props = { document: GuideInterviewList_document$key; }

export function GuideInterviewList({ document: documentKey }: Props) {

  const document = useFragment(fragmentQL, documentKey)

  return (
    <div>
      {
        document.copies?.map(copy =>
          <GuideInterviewListItem key={copy.id} document={copy} />
        )
      }
      {/* {
        document.copyConnection.edges.map(edge =>
          <div key={edge.node.id}>
            {edge.node.title}
          </div>)
      } */}
    </div>
  )
}
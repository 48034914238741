import { useState, useMemo, useEffect } from 'react'
import { useRouter } from "found"
import { useLazyLoadQuery } from "react-relay/hooks"
import graphql from 'babel-plugin-relay/macro'
import { DocumentHistoryDebuggerQuery, DocumentHistoryDebuggerQuery$data } from "./__generated__/DocumentHistoryDebuggerQuery.graphql"
import { useDocumentSubscription } from "../../components/documentSubscriptions/useDocumentSubscription"
import { DocumentSchema, ProsemirrorNodes } from "shared-constants"
import { Button, Container, Flex, Heading } from "@chakra-ui/react"
import { TiptapEditor } from "./tiptapEditor/TipTapEditor"
import { useEditor, EditorContent } from '@tiptap/react'
import "./tiptapEditor/tiptap.css"
import { extensions } from "./tiptapEditor/extensions/extensions"
import { Step } from "prosemirror-transform"
import { EditorState } from "prosemirror-state"
import { css } from '@emotion/react'


export const queryQL = graphql`
  query DocumentHistoryDebuggerQuery ($id: ID!){
    ...TipTapEditor_query
    node(id: $id){
      id
      ... on Document {
        title
        type
        ...DocumentSettingsEditor_document
        ...TipTapEditor_document
      }
    }
    documentStepConnection(
      documentId: $id
      first: 1000
    ) {
      edges {
        node {
          id
          redisId
          body
          stepNumber
          timestamp
          user {
            id
            email
          }
        }
      }
    }
    loggedInUser {
        id
        email
      }
  }
`

type StepInfo = DocumentHistoryDebuggerQuery$data['documentStepConnection']['edges']

export const incrementallyUpdateSnapshot = (
  docJson: ProsemirrorNodes.TipTapDocument,
  steps: StepInfo
) => {
  const prosemirrorDocument = DocumentSchema.createNewDoc(docJson)
  let newSnapshotDoc = prosemirrorDocument
  for (const stepInfo of steps) {
    const step = Step.fromJSON(DocumentSchema.schema, JSON.parse(stepInfo.node.body))
    const newDocument = step.apply(newSnapshotDoc).doc
    if (!newDocument) {
      throw new Error(`Step created empty document! [${JSON.stringify(stepInfo, null, 2)}]`);
    }
    newSnapshotDoc = newDocument
  }
  return newSnapshotDoc
}


type Props = {
  documentId?: string
}
export const DocumentHistoryDebugger = (props: Props) => {

  const router = useRouter()
  const documentId = props.documentId || router.match.params['documentId']

  const response = useLazyLoadQuery<DocumentHistoryDebuggerQuery>(queryQL, { id: documentId })
  if (!response.loggedInUser) throw new Error(`No user logged in!`);
  const stepEdges = response.documentStepConnection.edges
  const [activeStepIndex, setActiveStepIndex] = useState(stepEdges.length - 1)
  const document = response.node
  const documentSubscription = useDocumentSubscription(documentId)
  const startingContent = documentSubscription?.initialDoc ? documentSubscription.initialDoc!.body : DocumentSchema.initialDoc()
  const documentRootContent = useMemo(() =>
    incrementallyUpdateSnapshot(startingContent, stepEdges.slice(0, activeStepIndex + 1))
    , [stepEdges, activeStepIndex])

  const startingPoint = DocumentSchema.initialDoc
  const editor = useEditor({
    extensions: [
      ...extensions(),
    ],
    content: documentRootContent.toJSON(),
    editable: false,
  })

  useEffect(() => {
    if (!documentRootContent) return
    const newState = EditorState.create({
      schema: DocumentSchema.schema,
      doc: documentRootContent,
    })
    editor?.view.updateState(newState)
  }, [documentRootContent])


  const allSteps = response.documentStepConnection.edges.map(s => s.node)

  console.log("DocumentHistoryDebugger: document = ", document)
  console.log("DocumentHistoryDebugger: documentSubscription = ", documentSubscription)

  const onStepClick = (stepNumber: number) => {
    setActiveStepIndex(stepNumber)

    console.log(`onStepClick(${stepNumber})`)
  }

  return (
    <div>
      <Heading as='h1'>Document History</Heading>
      <h2>
        Title: {!document?.title} <br />
        Type: {!document?.type} <br />
        mustRefresh: {!documentSubscription?.mustRefreshMessage}
      </h2>
      <Flex direction='row'>
        <Container border='1px solid #999' width='100%'>
          The current state of the document
          <EditorContent
            editor={editor}
          />
        </Container>
        <Container
          width={300}>
          List of steps: ({allSteps.length})
          <ul css={css`
            border: 1px solid #999;
            height: 70vh;
            overflow-y: scroll;
            width: 100%;
            margin:0;
          `}>
            {allSteps.map((s, i) =>
              <li key={i}>
                <Button
                  isActive={activeStepIndex === s.stepNumber}
                  onClick={() => onStepClick(s.stepNumber)}
                  size='sm'
                >
                  {s.stepNumber}
                  {s.timestamp} <br />
                  <small>{s.user?.email}</small>
                </Button>
              </li>)
            }
          </ul>
        </Container>
      </Flex>
    </div>
  )
}
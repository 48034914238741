/**
 * @generated SignedSource<<4a69420e2ca3eda6c5c4180eec1c43d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentType = "faq" | "guide" | "initiative" | "interview" | "opportunity" | "prd" | "pressRelease" | "%future added value";
export type CreateDocumentInput = {
  createFromAIPrompt?: string | null;
  createFromDocId?: string | null;
  description?: string | null;
  opportunityId?: string | null;
  projectId?: string | null;
  title?: string | null;
  type: DocumentType;
};
export type NewDocumentButtonMutation$variables = {
  input: CreateDocumentInput;
};
export type NewDocumentButtonMutation$data = {
  readonly createDocument: {
    readonly description: string | null;
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
    }>;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
};
export type NewDocumentButtonMutation = {
  response: NewDocumentButtonMutation$data;
  variables: NewDocumentButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewDocumentButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OpportunityDocumentSideMenu_opportunity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewDocumentButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "opportunities",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "opportunity"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "documents(type:\"opportunity\")"
              },
              {
                "alias": "pressReleases",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "pressRelease"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "documents(type:\"pressRelease\")"
              },
              {
                "alias": "faqs",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "faq"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "documents(type:\"faq\")"
              },
              {
                "alias": "prds",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "prd"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "documents(type:\"prd\")"
              },
              {
                "alias": "interviewGuides",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "guide"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "copies",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "documents(type:\"guide\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "033ee13fcf5f7e23f13b7aff65730d4f",
    "id": null,
    "metadata": {},
    "name": "NewDocumentButtonMutation",
    "operationKind": "mutation",
    "text": "mutation NewDocumentButtonMutation(\n  $input: CreateDocumentInput!\n) {\n  createDocument(input: $input) {\n    id\n    title\n    description\n    type\n    opportunities {\n      ...OpportunityDocumentSideMenu_opportunity\n      id\n    }\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  title\n  description\n  opportunities: documents(type: opportunity) {\n    id\n  }\n  pressReleases: documents(type: pressRelease) {\n    id\n  }\n  faqs: documents(type: faq) {\n    id\n  }\n  prds: documents(type: prd) {\n    id\n  }\n  interviewGuides: documents(type: guide) {\n    id\n    title\n    copies {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4478bb6fbac6afbd53101894e113e51";

export default node;

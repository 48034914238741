import { EditorOptions, Extension, useEditor } from "@tiptap/react"
import { collab, receiveTransaction, sendableSteps, getVersion } from "prosemirror-collab"
import { Step } from "prosemirror-transform"
import { useEffect, useRef } from "react"
import { DocumentSubscription } from "../../../components/documentSubscriptions/useDocumentSubscription"
import { CorruptedDocumentError } from "../DocumentErrorBoundary"

export const useCollabEditor = (
  clientId: string,
  documentSubscription?: DocumentSubscription,
  options?: Partial<EditorOptions> | undefined,
  deps?: React.DependencyList | undefined,
) => {

  const lastAttemptedSaveMaxVersion = useRef<number>()
  const runAgainAfterReceivingStepsRef = useRef(false)
  const editorRef = useRef<ReturnType<typeof useEditor>>()

  const { sendSteps, initialDoc, newSteps, requestStepsSinceVersion } = documentSubscription || {}

  if (documentSubscription && typeof initialDoc?.version !== 'number') {
    throw new Error('Must have an initial version to use collab');
  }

  const sendSendableSteps = !sendSteps ? () => { } : () => {
    if (!editorRef.current) {
      return
    }
    const editor = editorRef.current
    const stepInfo = sendableSteps(editor.state)
    if (!stepInfo?.steps.length) return
    let saveContainsRedundantVersions = false
    if (stepInfo?.steps?.length) {
      const serializedSteps = stepInfo.steps.map((step, index) => {
        const version = stepInfo.version + index + 1
        saveContainsRedundantVersions = (
          typeof lastAttemptedSaveMaxVersion.current === 'number'
          && version <= lastAttemptedSaveMaxVersion.current
        )
        return step.toJSON()
      })
      // when typing fast, serializable steps contains steps that we are likely already
      // in the process of trying to save to the server
      // so let's hold off until those save to avoid unnecessary errors
      if (!saveContainsRedundantVersions) {
        lastAttemptedSaveMaxVersion.current = stepInfo.version + stepInfo.steps.length
        sendSteps(serializedSteps, stepInfo?.version, clientId)
      } else {
        runAgainAfterReceivingStepsRef.current = true
      }
    }
  }

  const { onTransaction, extensions, ...restOfOptions } = options || {}
  const editor = useEditor(
    {
      onTransaction: (props) => {
        sendSendableSteps()
        if (onTransaction) {
          onTransaction(props)
        }
      },
      extensions: [
        ...(extensions || []),
        Extension.create({
          name: 'collab',
          addProseMirrorPlugins: () => [
            collab({
              version: initialDoc?.version,
              clientID: clientId
            }),
          ],
        }),
      ],
      ...restOfOptions
    },
    deps)

  useEffect(() => {
    if (
      documentSubscription && requestStepsSinceVersion &&
      editor &&
      newSteps &&
      newSteps.stepData?.length !== 0
    ) {

      const clientVersion = getVersion(editor.state)
      if (typeof clientVersion !== 'number') return
      if (newSteps.documentVersion < clientVersion) {
        const difference = clientVersion - newSteps.documentVersion
        if (newSteps.stepData.length === difference) {
          return
        }
      }
      if (clientVersion !== newSteps.documentVersion) {
        console.log('version mismatch', clientVersion, newSteps.documentVersion)
        requestStepsSinceVersion(clientVersion)
        return
      }

      // console.log(`new steps: ${newSteps.stepData.length}`)
      const clientIds = [] as string[]
      const parsedSteps = newSteps.stepData.map((stepInfo, index) => {
        clientIds.push(
          stepInfo.clientId
        )
        return Step.fromJSON(editor.schema, stepInfo.step)
      })

      try {
        editor.view.dispatch(
          receiveTransaction(
            editor.state,
            parsedSteps,
            clientIds,
            {
              mapSelectionBackward: true,
            }
          )
        )
      } catch (err) {
        throw new CorruptedDocumentError()
      }

      if (runAgainAfterReceivingStepsRef.current) {
        runAgainAfterReceivingStepsRef.current = false
        sendSendableSteps()
      }
    }
  }, [editor, newSteps]);
  if (editor) {
    editorRef.current = editor
  }

  return editor
}
import { css } from "@emotion/react"
import { ColoredIconHolder, IconColors } from "./ColoredIconHolder"

type Props = {
  color: keyof typeof IconColors,
  title: string,
  subtitle?: string,
  onClick?: React.MouseEventHandler,
  titleClassName?: string
  className?: string
  hasFocusBrackets?: boolean
  iconSize?: number
  inverted?: boolean
  topMargin?: boolean
}

export const IconTitleButton = ({
  color,
  title,
  subtitle,
  iconSize,
  onClick,
  className,
  hasFocusBrackets = false,
  inverted = false,
  topMargin = true,
}: Props) => {

  const flexDir = subtitle?.length ? 'flex-start' : 'center' as const


  const hoverableCss = css`
    &:hover {
      opacity: 0.5;
    }
  `

  return (
    <div
      onClick={onClick}
      css={css`
          margin-top: ${topMargin ? '10px' : '0px'};
          display: flex;
          padding: 2px;
          align-items: ${flexDir};
          flex-direction:'row';
          cursor: pointer;
          ${onClick ? hoverableCss : undefined}
        `}
    >
      <ColoredIconHolder
        inverted={inverted}
        hasFocusBrackets={hasFocusBrackets}
        color={color}
        size={iconSize}
        css={css`
          margin-right: 10px;
        `}
      />
      <div>
        <h6
          css={css`
          text-transform: none;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
        `}
          className={className}
        >
          {title}
        </h6>
        {
          subtitle &&
          <div css={css`
            font-size: 12px;
            color: #999;
          `}>
            {subtitle}
          </div>
        }
      </div>
    </div>
  )
}
/**
 * @generated SignedSource<<13deb5ef273a95c489ff504343126187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpportunityPageQueryQuery$variables = {
  opportunityId: string;
};
export type OpportunityPageQueryQuery$data = {
  readonly opportunity: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OpportunityPage_opportunity">;
  } | null;
};
export type OpportunityPageQueryQuery = {
  response: OpportunityPageQueryQuery$data;
  variables: OpportunityPageQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "opportunityId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "opportunityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpportunityPageQueryQuery",
    "selections": [
      {
        "alias": "opportunity",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OpportunityPage_opportunity"
              }
            ],
            "type": "Opportunity",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpportunityPageQueryQuery",
    "selections": [
      {
        "alias": "opportunity",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": "pressReleases",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "pressRelease"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": "documents(type:\"pressRelease\")"
              },
              {
                "alias": "faqs",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "faq"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": "documents(type:\"faq\")"
              },
              {
                "alias": "prds",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "prd"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": "documents(type:\"prd\")"
              }
            ],
            "type": "Opportunity",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee04693ea4dc5beb70a583bfb6ac6f60",
    "id": null,
    "metadata": {},
    "name": "OpportunityPageQueryQuery",
    "operationKind": "query",
    "text": "query OpportunityPageQueryQuery(\n  $opportunityId: ID!\n) {\n  opportunity: node(id: $opportunityId) {\n    __typename\n    id\n    ... on Opportunity {\n      ...OpportunityPage_opportunity\n    }\n  }\n}\n\nfragment OpportunityPage_opportunity on Opportunity {\n  id\n  description\n  pressReleases: documents(type: pressRelease) {\n    id\n  }\n  faqs: documents(type: faq) {\n    id\n  }\n  prds: documents(type: prd) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0c613bb198d33257010b1648b95d29e";

export default node;

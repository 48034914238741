import { NodeConfig, nodePasteRule, PasteRule } from "@tiptap/core"

/* Shared behaviors for all block types */

type Config = Partial<NodeConfig<any, any>>


export const attributes = {
  id: {
    isRequired: false,
    default: null,
    keepOnSplit: false,
  },
  originalBlockId: {
    isRequired: false,
    default: null,
    keepOnSplit: false,
  },
  sourceBlockId: {
    isRequired: false,
    default: null,
    keepOnSplit: false,
  },
  transcriptTimestamp: {
    isRequired: false,
    default: null,
    keepOnSplit: true,
  }
}


export const nestableBlockDefaultConfig = ({
  blockName,
  tagName,
}: {
  blockName: string
  tagName?: string
}) => {
  const tag = tagName ?? blockName
  const config: Config = {
    name: blockName,
    group: 'nestableBlock',
    content: 'title contentList?',
    selectable: false,
    defining: true,

    addAttributes: () => {
      return {
        ...attributes,
      }
    },
    parseHTML() {
      return [
        {
          tag,
          // getAttrs: node => typeof node !== 'string' && node.className.includes(name) && null,
        },
      ]
    },
    addPasteRules() {
      return [
        new PasteRule({
          find: config.find,
          handler: ({ state, range, match }) => {
            console.log(state, range, match)
          },
        })
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return [tag, {
        ...HTMLAttributes,
        class: blockName,
      }, 0]
    }
    // Your code goes here.
  }
  return config
}



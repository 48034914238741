import { Heading } from '@chakra-ui/react'

import './OpportunityStyles.css'
import { OpportunityIntakeForm } from './OpportunityIntakeForm'

export const NewOpportunityPage = () => {

  return <div className="splitView">

    <div className='mainContent'>
      <Heading as="h2" paddingLeft="15px">New Opportunity</Heading>
      <OpportunityIntakeForm />
    </div>

    <div className='sideBar'>

    </div>
  </div>
}
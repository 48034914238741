import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache,
  FetchFunction,
} from 'relay-runtime'
import { baseUrl, } from './network'

const oneMinute = 60 * 1000
const cache = new QueryResponseCache({ size: 250, ttl: oneMinute })
const store = new Store(new RecordSource())

let defaultLanguageCode = 'en'
let onUnauthorizedQueryFunction = () => {
  window.location.href = '/login'
}

const fetchQuery: FetchFunction = async (
  operation,
  variables,
  cacheConfig,
) => {
  const queryID = operation.text!
  const isMutation = operation.operationKind === 'mutation'
  const isQuery = operation.operationKind === 'query'
  const forceFetch = cacheConfig && cacheConfig.force

  const fromCache = cache.get(queryID, { ...variables })
  if (
    isQuery &&
    fromCache !== null &&
    !forceFetch
  ) {
    return fromCache
  }
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    credentials: 'include'
  } as const

  const response = await fetch(`${baseUrl()}/graphql`, params)

  if (response.status >= 401) {
    onUnauthorizedQueryFunction && onUnauthorizedQueryFunction()
    return
  }

  const json = await response.json()

  if (json.errors || response.status === 403) {
    console.error('Network/GraphQL Error:')
    console.error(json.errors)
    if (json.errors?.some && json.errors?.some((e: any) => e.message?.toLowerCase().includes('authenticated'))) {
      onUnauthorizedQueryFunction && onUnauthorizedQueryFunction()
    } else {
      throw new Error(`Server error: ${json.errors[0].message}`)
    }
  }
  if (isQuery && json) {
    cache.set(queryID, variables, json)
  }
  // Clear cache on mutations
  if (isMutation) {
    cache.clear()
  }
  return json
}


const environment = new Environment({
  network: Network.create(fetchQuery),
  store,
})

export { store, environment }

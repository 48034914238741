import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay/hooks';
import { OpportunityPageWrapper } from './OpportunityPageWrapper';
import { OpportunityPage_opportunity$key } from './__generated__/OpportunityPage_opportunity.graphql';
import { OpportunityPage_project$key } from './__generated__/OpportunityPage_project.graphql';

const fragmentQL = graphql`
  fragment OpportunityPage_opportunity on Opportunity {
     id
     description
     pressReleases: documents(type: pressRelease) {
        id
     }
     faqs: documents(type: faq) {
        id
     }
     prds: documents(type: prd) {
        id
     }
  }
`

const projectFragmentQL = graphql`
  fragment OpportunityPage_project on Project {
     id
     interviews: documents(type: interview) {
        id
     }
     guides: documents(type: guide) {
        id
     }
  }
`

type Props = {
  opportunity: OpportunityPage_opportunity$key;
  project?: OpportunityPage_project$key | null;
}

export function OpportunityPage({ opportunity: opportunityKey, project: projectKey }: Props) {

  const opportunity = useFragment(fragmentQL, opportunityKey)
  const project = useFragment(fragmentQL, projectKey!)

  const pressReleaseId = opportunity.pressReleases[0]?.id
  const faqId = opportunity.faqs[0]?.id
  const prdId = opportunity.prds[0]?.id
  const guideId = project?.guides[0]?.id

  return (
    <OpportunityPageWrapper
      opportunityDescription={opportunity.description}
      pressReleaseId={pressReleaseId}
      faqId={faqId}
      prdId={prdId}
      guideId={guideId}
    />
  )
}
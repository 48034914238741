/**
 * @generated SignedSource<<e5dc938ab5b4adc75e7666121e76d015>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuideInterviewList_document$data = {
  readonly copies: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GuideInterviewListItem_document">;
  }> | null;
  readonly " $fragmentType": "GuideInterviewList_document";
};
export type GuideInterviewList_document$key = {
  readonly " $data"?: GuideInterviewList_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuideInterviewList_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuideInterviewList_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "copies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GuideInterviewListItem_document"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "9bdb6d94c3833430ba7ea7f1a34f2313";

export default node;

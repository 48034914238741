import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay/hooks'
import { commitLocalUpdate, Environment } from 'react-relay'
import { networkQuery } from './__generated__/networkQuery.graphql'
const apiDomain = process.env.REACT_APP_API_DOMAIN
const mediaDomain = process.env.REACT_APP_MEDIA_DOMAIN
const websocketDomain = process.env.REACT_APP_WEBSOCKET_DOMAIN
console.log({ websocketDomain })

const websocketUrl = () => `${websocketDomain}`
const baseMediaUrl = () => `${mediaDomain}`
const baseUrl = () => `${apiDomain}`
const loginUrl = () => `${baseUrl()}/login`
const registerUrl = () => `${baseUrl()}/register`
const logoutUrl = () => `${baseUrl()}/logout`
const validatePasswordResetTokenUrl = () => `${baseUrl()}/validatePasswordResetToken`
const resetPasswordUrl = () => `${baseUrl()}/resetPassword`
const googleAuthUrl = (tokenId: string, inviteCode: string) =>
  `${baseUrl()}/googleOAuth/?tokenId=${tokenId}${inviteCode ? `&inviteCode=${inviteCode}` : ''}`
const googleClientId = () => process.env.REACT_APP_GOOGLE_CLIENT_ID || '264776103570-5an97ob72q06t2rfgfqtngsj9t7ar288.apps.googleusercontent.com'

export type RegistrationOrgInfo = {
  existingOrgId: number
} | {
  newOrgName: string
}


const login = async (username: string, password: string, inviteCode?: string) => {
  const response = await (await fetch(
    loginUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({
      username,
      password,
      inviteCode
    })
  })).json()
  return response
}

const register = async (username: string, password: string, orgInfo: RegistrationOrgInfo) => {
  const response = await fetch(
    registerUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password,
      orgInfo,
    })
  })
  const result = await response.json()
  if (!response.ok || result.error) {
    const error = result.error?.errors.join('') || result.error || response.statusText
    throw new Error(error)
  }
  return result
}

export const validatePasswordResetToken = async (uid: string, token: string) => {
  const response = await fetch(
    validatePasswordResetTokenUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({
      uid,
      token,
    })
  })
  const result = await response.json()
  if (!response.ok || result.error) {
    const error = result.error?.errors?.join('') || result.error || response.statusText
    throw new Error(error)
  }
  return result
}
export const resetPassword = async (uid: string, token: string, newPassword: string) => {
  const response = await fetch(
    resetPasswordUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({
      uid,
      token,
      newPassword
    })
  })
  const result = await response.json()
  if (!response.ok || result.error) {
    const error = result.error?.errors?.join('') || result.error || response.statusText
    throw new Error(error)
  }
  return result
}


const loggedInUserQuery = graphql`
query networkQuery {
  loggedInUser{
    id
    email
  }
}`

type User = NonNullable<networkQuery['response']['loggedInUser']>

const checkLoginStatus = async (environment: Environment) => {
  try {
    const response = await fetchQuery<networkQuery>(environment, loggedInUserQuery, {}).toPromise()
    commitLocalUpdate(environment, store => {
      const root = store.getRoot()
      const authStatus = root.getOrCreateLinkedRecord('authenticationStatus', 'AuthenticationStatus')
      const user = authStatus.getOrCreateLinkedRecord('user', 'User')
      authStatus.setValue(true, 'isLoggedIn')
      let fieldName: keyof User
      for (fieldName in response?.loggedInUser) {
        user.setValue(response?.loggedInUser?.[fieldName], fieldName)
      }
    })
  } catch (error) {
    console.warn(error)
    commitLocalUpdate(environment, store => {
      const root = store.getRoot()
      const authStatus = root.getOrCreateLinkedRecord('authenticationStatus', 'AuthenticationStatus')
      authStatus.setValue(null, 'user')
      authStatus.setValue(false, 'isLoggedIn')
    })
  }
}

export {
  checkLoginStatus,
  login,
  register,
  baseUrl,
  loginUrl,
  logoutUrl,
  baseMediaUrl,
  googleAuthUrl,
  googleClientId,
  websocketUrl,
}

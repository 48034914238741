import { Mark, MarkConfig, nodePasteRule, PasteRule } from "@tiptap/core"

export const attributes = {
  userid: {
    isRequired: true,
    default: null,
    keepOnSplit: true,
  },
  isgenerated: {
    isRequired: true,
    default: null,
    keepOnSplit: true,
  },
  isconfirmed: {
    isRequired: false,
    default: null,
    keepOnSplit: true,
  },
}


const tag = 'attribution'
type BooleanString = 'true' | 'false'

export type AttributionMark = {
  type: typeof tag,
  attrs: {
    isgenerated: BooleanString,
    isconfirmed?: BooleanString,
    userid?: string,
  }
}

const attributionConfig: MarkConfig = {
  name: tag,

  addAttributes: () => {
    return {
      ...attributes,
    }
  },
  parseHTML() {
    return [
      {
        tag,
        // getAttrs: node => typeof node !== 'string' && node.className.includes(name) && null,
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return [tag, {
      ...HTMLAttributes,
      class: tag,
    }, 0]
  }
  // Your code goes here.
}


export const attribution = Mark.create(attributionConfig)
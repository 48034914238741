import { useState, useEffect, ReactPropTypes } from 'react'
import verdiLogo from '../components/logo/verdi-logo.svg'
import GoogleLogin from 'react-google-login'
import { useRelayEnvironment } from 'react-relay/hooks'
import {
  googleAuthUrl,
  checkLoginStatus,
  googleClientId,
  register,
  login,
} from './network'
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { useRouter } from 'found'

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    alignSelf: 'center',
    margin: 'auto',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 500,
    marginTop: 20,
    paddingTop: 20,
    borderRadius: 7,
  },
  cardContent: {
    textAlign: 'center',
  },
  error: {
    marginTop: 20,
    fontSize: 15,
  },
}

const Login = ({ routeData }: { routeData: any }) => {

  const { match, router } = useRouter();
  const [error, setError] = useState<string>()
  const [loginCreds, setLoginCreds] = useState({
    username: ``,
    password: ``,
    newOrgName: `Yes & Co`,
  })
  const [loggingIn, setLoggingIn] = useState(false)

  const onSuccess = async () => {
    await checkLoginStatus(environment)
    router.replace(`/`)
  }

  const isReadyForPasswordAuth = loginCreds.username?.length && loginCreds.password?.length


  const attemptRegistration = async () => {
    if (!isReadyForPasswordAuth) {
      return
    }
    setLoggingIn(true)
    try {
      await register(loginCreds.username, loginCreds.password, { newOrgName: loginCreds.newOrgName })
      await attemptLogin()
    } catch (error: any) {
      setError(error?.message)
    } finally {
      setLoggingIn(false)
    }
  }

  const attemptLogin = async () => {
    if (!isReadyForPasswordAuth) {
      return
    }
    setLoggingIn(true)

    try {
      const { jwt, error } = await login(
        loginCreds.username,
        loginCreds.password,

      )
      if (jwt) {
        await onSuccess()
      }
      else {
        setError(error?.message || error)
      }
    } catch (error: any) {
      setError(error.message)
    }
    finally {
      setLoggingIn(false)
    }
  }

  const environment = useRelayEnvironment()
  type OnSuccess = React.ComponentProps<typeof GoogleLogin>['onSuccess']
  const onGoogleSuccess: OnSuccess = async (response) => {
    // @ts-ignore idk why the types don't match reality here?
    const code = response.tokenId
    const fetchConfig = {
      credentials: 'include'
    } as const
    const fetched = await fetch(googleAuthUrl(code, `n/a`), fetchConfig)
    const data = await fetched.json()
    if (data.error) setError(error)
    else {
      await onSuccess()
    }
  }

  type OnFailure = React.ComponentProps<typeof GoogleLogin>['onFailure']
  const onGoogleFailure: OnFailure = async (response) => {
    if (response.error) {
      setError(response.error + ' ' + response.details)
      console.error(response)
      return
    }
  }

  return (
    <div style={{
      marginTop: 45,
    }}>
      <img style={{
        margin: `auto`,
        maxWidth: 300,
      }} src={verdiLogo} />
      <div style={styles.container} className="login-area">
        <Box>
          <Container style={styles.cardContent}>
            <br />
            <div style={{
              maxWidth: 350,
              margin: 'auto',
            }}>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  size='small'
                  id='usernameemail'
                  variant={`outlined`}
                  value={loginCreds.username}
                  onChange={(e) => setLoginCreds({ ...loginCreds, username: e.target.value })}
                />
              </FormControl>
              <br />
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  id='password'
                  size='small'
                  variant={`outlined`}
                  type='password'
                  value={loginCreds.password}
                  onChange={(e) => setLoginCreds({ ...loginCreds, password: e.target.value })}
                />
              </FormControl>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
              }}>
                <Button
                  onClick={attemptRegistration}
                  disabled={!isReadyForPasswordAuth || loggingIn}
                  isLoading={loggingIn}
                >
                  Register
                </Button>

                <Button
                  style={{
                    marginLeft: 'auto',
                    maxWidth: 100,
                  }}
                  variant={'outlined'}
                  onClick={attemptLogin}
                  isLoading={loggingIn}
                  disabled={!isReadyForPasswordAuth || loggingIn}>
                  Login
                </Button>
              </div>
            </div>
            <div style={{
              margin: 20,
              marginBottom: 30,
              fontSize: 13,
            }}>
              &mdash;&mdash; OR &mdash;&mdash;
            </div>
            <GoogleLogin
              onRequest={() => setError(undefined)}
              clientId={googleClientId()}
              buttonText="Login"
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              disabled={false}
              cookiePolicy={'single_host_origin'}
            >
              Login with Google
            </GoogleLogin>
            {
              error &&
              <Alert status="error" style={styles.error}>
                <div style={{
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-wrap',
                }}>
                  {/* {JSON.stringify(error, null, 2)} */}
                  {error}
                </div>
              </Alert>
            }
            <br />
            <br />
          </Container >
        </Box>
      </div >
    </div>
  )
}

export default Login
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import {
  CompareTableRow_blockComparison$data,
  CompareTableRow_blockComparison$key,
} from './__generated__/CompareTableRow_blockComparison.graphql';
import { firstColumnCellStyling, tableCellStyling } from './styles'

graphql`
fragment CompareTableRowChild_blockComparison on BlockComparison @relay(mask: false) {
  mainBlock {
    id
    text
    type
  }
  comparedDocumentBlocks {
    id
    text
    type
    children {
      type
      text
    }
  }
}
`

const fragmentQL = graphql`
  fragment CompareTableRow_blockComparison on BlockComparison {
    ...CompareTableRowChild_blockComparison @relay(mask: false)
    children {
      ...CompareTableRowChild_blockComparison @relay(mask: false)
      children {
        ...CompareTableRowChild_blockComparison @relay(mask: false)
        children {
          ...CompareTableRowChild_blockComparison @relay(mask: false)
          children {
            ...CompareTableRowChild_blockComparison @relay(mask: false)
            children {
              ...CompareTableRowChild_blockComparison @relay(mask: false)
              children {
                ...CompareTableRowChild_blockComparison @relay(mask: false)
                children {
                  ...CompareTableRowChild_blockComparison @relay(mask: false)
                    children {
                      ...CompareTableRowChild_blockComparison @relay(mask: false)
                      children {
                        ...CompareTableRowChild_blockComparison @relay(mask: false)
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`

type Props = {
  blockComparison: CompareTableRow_blockComparison$key | CompareTableRow_blockComparison$data;
}

type ComparedBlock = CompareTableRow_blockComparison$data['comparedDocumentBlocks'][number]

const answerFromQuestionBlock = (block: ComparedBlock) => {
  const texts = block?.children.map(block => {
    switch (block.type) {
      case 'freeText':
        return block.text
      case 'section':
        return block.text
      case 'question':
        return ''
      default:
        return block.text
    }
  })
  return texts?.join('\n')
}


export function CompareTableRow({ blockComparison: blockComparisonMaybeKey }: Props) {
  const blockComparisonKey = !('children' in blockComparisonMaybeKey) ? blockComparisonMaybeKey : undefined
  const blockComparisonData = useFragment(fragmentQL, blockComparisonKey!)
  const blockComparison = blockComparisonData || blockComparisonMaybeKey

  if (blockComparison.mainBlock.type === 'freeText') {
    return null
  }

  return (
    <>
      <tr>
        <td css={firstColumnCellStyling}>
          {blockComparison.mainBlock.text}
        </td>
        {
          blockComparison.comparedDocumentBlocks?.map(block =>
            <td
              css={tableCellStyling}
              key={block?.id}>
              {answerFromQuestionBlock(block)}
            </td>
          )
        }
      </tr>
      {
        blockComparison.children.map(blockComparison =>
          <CompareTableRow
            key={blockComparison.mainBlock.id}
            // @ts-ignore
            blockComparison={blockComparison}
          />
        )
      }
    </>
  )
}
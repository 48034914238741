/**
 * @generated SignedSource<<4d9ff84f81ba07f0d8e2edf4291c4431>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "faq" | "guide" | "initiative" | "interview" | "opportunity" | "prd" | "pressRelease" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TipTapEditor_document$data = {
  readonly id: string;
  readonly opportunities: ReadonlyArray<{
    readonly description: string | null;
    readonly title: string | null;
  }>;
  readonly type: DocumentType;
  readonly " $fragmentSpreads": FragmentRefs<"useTipTapData_document">;
  readonly " $fragmentType": "TipTapEditor_document";
};
export type TipTapEditor_document$key = {
  readonly " $data"?: TipTapEditor_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"TipTapEditor_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TipTapEditor_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Opportunity",
      "kind": "LinkedField",
      "name": "opportunities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTipTapData_document"
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "ba6433100c47505574dd71f1d2b3ed01";

export default node;

import { BrowserProtocol, queryMiddleware } from 'farce';
import {
  createFarceRouter,
  createRender,
  makeRouteConfig,
  Redirect,
  RenderErrorArgs,
  Route,
} from 'found';
import Login from '../auth/Login';
import { MainWrap } from './MainWrap';
import { DocumentEditorWithOutline } from '../screens/document/DocumentEditorWithOutline';
import { Documents } from '../screens/documents/Documents';
import { CompareDocument } from '../screens/compareDocument/CompareDocument';
import Logout from '../screens/logout/Logout';
import { ZoomConnectAccount } from '../screens/zoom/ZoomConnectAccount';
import { ZoomMeetingList } from '../screens/zoom/ZoomMeetingList';
import { OpportunityPageQuery } from '../screens/opportunities/OpportunityPageQuery';
import { NewOpportunityPage } from '../screens/opportunities/OpprtunityNew';
import { OpportunityDocument } from '../screens/opportunityDocument/OpportunityDocument';
import { DocumentHistoryDebugger } from '../screens/document/DocumentHistoryDebugger';

export const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  renderError: ({ error }: RenderErrorArgs) => {
    console.error(error)
    return (
      <div>{error.status === 404 ? 'Not found' : 'Error'}</div>
    )
  },
  routeConfig: makeRouteConfig(
    <Route
      path="/"
      Component={MainWrap}
    >
      <Redirect from="" to="/opportunity" />
      <Route path="login">
        <Route
          Component={Login}
          getData={() => { }}
        />
      </Route>
      <Route
        path='logout'
        Component={Logout}
      />
      <Route path='opportunities/new'>
        <Route Component={NewOpportunityPage} />
      </Route>
      <Route path='opportunities/:opportunityId'>
        <Route Component={OpportunityPageQuery} />
      </Route>
      <Route path='opportunity'>
        <Route Component={OpportunityDocument} />
      </Route>
      <Route path='opportunity/:opportunityId'>
        <Route Component={OpportunityDocument} />
      </Route>
      <Route path='opportunity/:opportunityId/:documentId'>
        <Route Component={OpportunityDocument} />
      </Route>
      <Route path="documents">
        <Route Component={Documents} />
      </Route>
      <Route path="documentHistory/:documentId">
        <Route Component={DocumentHistoryDebugger} />
      </Route>
      <Route path="compareDocument/:documentId">
        <Route Component={CompareDocument} />
      </Route>
      <Route path="document/:documentId">
        <Route Component={DocumentEditorWithOutline} />
      </Route>
      <Route path="zoom/connect">
        <Route Component={ZoomConnectAccount} />
      </Route>
      <Route path="zoom/meetings">
        <Route Component={ZoomMeetingList} />
      </Route>
    </Route>,
  ),
  render: createRender({}),
});
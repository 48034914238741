import { css } from "@emotion/react"
import { ProsemirrorNodes } from "shared-constants"


const RenderNestableBlock = ({ block }: { block: ProsemirrorNodes.NestableBlock }) => {
  if (
    ('type' in block)
    && ('content' in block)
    && block.content.length
  ) {
    switch (block.type) {
      case 'section':
        const [titleElement, contentList] = block.content
        const title = titleElement.content?.[0]?.text || ''
        return <Section title={title} children={contentList?.content ?? []} />
      case 'question':
        if (!block.content.length) return null
        const [_titleElement, questionContainer] = block.content
        return <>
          {
            questionContainer?.content?.map((child, index) =>
              <RenderNestableBlock
                key={index}
                block={child}
              />
            )
          }
        </>
      default:
        return null
    }
  }
  return null
}


const Section = ({ title, children }: {
  title: string,
  children: ProsemirrorNodes.NestableBlock[]
}) => {
  return (
    <div>
      <div css={css`
        padding-top: 8px;
        font-size: 14px;
      `}>
        {title}
      </div>
      <div css={css`
        padding-left: 12px;
      `}>
        {
          children?.map((child, index) => {
            return (
              <RenderNestableBlock block={child} key={index} />
            )
          })
        }
      </div>
    </div>
  )
}

type Props = {
  blocks: ProsemirrorNodes.NestableBlock[]
  showBackbutton?: boolean
}

export const TipTapBlockOutline = ({ blocks, showBackbutton = true }: Props) => {
  return (
    <div css={css`
      margin-left: 20px;
    `}>
      {
        !showBackbutton ? null :
          <a
            css={css`
          font-size: 30px;
          color: #999;
        `}
            href={'/'}
            className={`link-button`}
          >
            ←
          </a>
      }

      <h6 css={css`
        font-size: 12px;
      `}>
        Outline
      </h6>
      {
        blocks.map((block, index) =>
          <RenderNestableBlock block={block} key={index} />
        )
      }
    </div>
  )
}
import graphql from 'babel-plugin-relay/macro'
import { cloneDeep } from "lodash"
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo } from "react"
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay"
import { Mutable } from "utility-types"
import { Editor } from "@tiptap/core"
import { SuspenseErrorBoundary } from "../../../../components/SuspenseErrorBoundary"
import { RecursiveGraphQLBlock } from "../../BlockEditorDataHandler"
import { CommandMenuSection, SectionProps, SectionRef } from "./CommandMenuSection"
import { CommandMenuSectionItem } from "./CommandMenuSectionItem"
import { GLOBAL_MAX_NESTING_LEVEL } from "./commands"
import { AddExistingBlocksSectionQuery } from "./__generated__/AddExistingBlocksSectionQuery.graphql"
import { AddExistingBlocksSection_query$key } from "./__generated__/AddExistingBlocksSection_query.graphql"
import { makeBlockEditorReady } from '../convertBlocks'
import { insertContentForCommand } from './insertContentForCommand'

graphql`
fragment AddExistingBlocksSection_block on Block @relay(mask: false) {
  id
  type
  questionType
  text
  level
  originalBlockId
  sourceBlockId
}
`

const fragmentQL = graphql`
  fragment AddExistingBlocksSection_query on Query 
  {
    blockConnection (
      query: $query
      blockType: $blockType
      documentType: guide
      oldestSharedOrigin: true
      first: 20
    ){
      edges {
        node {
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
          ...AddExistingBlocksSection_block @relay(mask: false)
          children{
            ...AddExistingBlocksSection_block @relay(mask: false)
          }}}}}}}}}
        }
      }
    }
  }
`


export const queryQL = graphql`
  query AddExistingBlocksSectionQuery (
    $query: String
    $blockType: BlockType
  ) {
    ...AddExistingBlocksSection_query
  }
`



type Props = {
  children?: ReactNode | ReactNode[]
  blockType: 'question' | 'section'
  title: string
  preloadedQuery: PreloadedQuery<AddExistingBlocksSectionQuery>
  isLoading: boolean
} & SectionProps

const UnsuspendedAddExistingBlocksSection: React.ForwardRefRenderFunction<SectionRef, Props> = ({
  search,
  selectedIndex,
  nestingLevel,
  title,
  preloadedQuery,
  blockType,
  isLoading,
  editor,
}: Props, ref) => {

  const query = usePreloadedQuery(queryQL, preloadedQuery)
  const { blockConnection } = useFragment(fragmentQL, query as AddExistingBlocksSection_query$key)

  const filteredBlocks = useMemo(() => {
    const blocks = blockConnection.edges.map(edge => edge.node)
    if (!search.length)
      return blocks
    const filteredBlocks = blocks.filter(block => {
      return block.text.toLowerCase().includes(search.toLowerCase())
    })
    return filteredBlocks
  },
    [blockConnection, search]
  )

  const selectOptionAtIndex = useCallback((editor: Editor, index: number) => {
    const insertingBlock = filteredBlocks[index]
    const blockCopy = cloneDeep(insertingBlock) as Mutable<RecursiveGraphQLBlock>
    const insertData = makeBlockEditorReady(blockCopy, true)
    insertContentForCommand(editor, insertData)
  }, [filteredBlocks])


  useImperativeHandle(ref, () => ({
    length: filteredBlocks.length,
    isLoading: false,
    insertElement: (editor) => {
      selectOptionAtIndex(editor, selectedIndex)
    },
  }), [filteredBlocks, selectedIndex])

  if (!isLoading && !filteredBlocks.length) return null

  return (
    <CommandMenuSection title={title}>
      {
        filteredBlocks.map((block, index) => {
          const disabled = typeof nestingLevel === 'number' && nestingLevel > GLOBAL_MAX_NESTING_LEVEL - 1
          return <CommandMenuSectionItem
            key={block.id}
            title={block.text}
            disabled={disabled}
            index={index}
            selectedIndex={selectedIndex}
            // onClick={() => selectOptionAtIndex(index)}
            onClick={() => selectOptionAtIndex(editor, index)}
          />
        })
      }
      {
        filteredBlocks.length ? null :
          <li>
            {
              isLoading ?
                `Loading...` :
                `No Matching Blocks`
            }
          </li>
      }
    </CommandMenuSection>
  )
}

const ForwardedUnsuspendedAddExistingBlocksSection = forwardRef(UnsuspendedAddExistingBlocksSection)

const UnrefFowardedAddExistingBlocksSection: React.ForwardRefRenderFunction<SectionRef, Props> =
  (props: Props, ref) =>
    <SuspenseErrorBoundary>
      <ForwardedUnsuspendedAddExistingBlocksSection {...props} ref={ref} />
    </SuspenseErrorBoundary>

export const AddExistingBlocksSection = forwardRef(UnrefFowardedAddExistingBlocksSection)
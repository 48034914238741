import { useCallback, useRef, useState } from "react"
import { fetchQuery, GraphQLTaggedNode, useRelayEnvironment } from "react-relay"

type FetchQueryParams = Parameters<typeof fetchQuery>

export const useFetchRelayQuery = (query: GraphQLTaggedNode) => {

  const isLoadingRef = useRef(false)
  const [isLoading, setIsLoading] = useState(isLoadingRef.current)
  const environment = useRelayEnvironment()
  const lastUnrunArgsRef = useRef<{
    variables: FetchQueryParams[2],
    cacheConfig?: FetchQueryParams[3]
  } | undefined>(undefined)

  const fetch = useCallback((variables: FetchQueryParams[2], cacheConfig?: FetchQueryParams[3]) => {
    if (isLoadingRef.current) {
      lastUnrunArgsRef.current = {
        variables,
        cacheConfig
      }
    } else {
      fetchQuery(
        environment,
        query,
        variables,
        cacheConfig,
      ).subscribe({
        start: async () => {
          await setIsLoading(true)
          isLoadingRef.current = true
        },
        complete: async () => {
          if (typeof lastUnrunArgsRef.current === 'undefined') {
            isLoadingRef.current = false
            await setIsLoading(false)
          } else {
            isLoadingRef.current = false
            const { variables, cacheConfig } = lastUnrunArgsRef.current
            lastUnrunArgsRef.current = undefined
            fetch(variables, cacheConfig)
          }
        },
        error: () => {
          isLoadingRef.current = false
          setIsLoading(false)
        },
      })
    }
  }, [])

  return [fetch, isLoading] as const
}

import { DocumentSettingsEditor_document$data } from "../../__generated__/DocumentSettingsEditor_document.graphql"
import { faqAiPrompt } from "./aiPrompts/faqAiPrompt"
import { opportunityAiPrompt } from "./aiPrompts/opportunityAiPrompt"
import { pressReleaseAiPrompt } from "./aiPrompts/pressReleaseAiPrompt"
import { interviewGuideAiPrompt } from "./aiPrompts/interviewGuideAiPrompt"


export type DocumentType = DocumentSettingsEditor_document$data['type']

export const settingsForDocumentTypes = (documentType: DocumentType) => {
  switch (documentType) {
    case 'faq':
      return {
        typeName: 'FAQ',
        shouldUseOpportunityTitle: true,
        prompt: faqAiPrompt,
      }
    case 'opportunity':
      return {
        typeName: 'Opportunity Brief',
        shouldUseOpportunityTitle: true,
        prompt: opportunityAiPrompt,
      }
    case 'pressRelease':
      return {
        typeName: 'Mock Press Release',
        shouldUseOpportunityTitle: true,
        prompt: pressReleaseAiPrompt,
      }
    case 'prd':
      return {
        typeName: 'Product Requirements Doc',
        shouldUseOpportunityTitle: true,
      }
    case 'initiative':
      return {
        typeName: 'Initiative',
        shouldUseOpportunityTitle: false,
      }
    case 'interview':
      return {
        typeName: 'Interview',
        shouldUseOpportunityTitle: false,
        disableAiPrompts: true,
      }
    case 'guide':
      return {
        typeName: 'Interview Guide',
        shouldUseOpportunityTitle: false,
        prompt: interviewGuideAiPrompt,
        disableAiPrompts: true,
      }
    case '%future added value':
      return {
        typeName: 'Document',
        shouldUseOpportunityTitle: false,
      }
  }
}
import { makeFreeTextBlock, TipTapDocument } from "../prosemirrorNodes";
import { Fragment } from "prosemirror-model"
import { schema } from "./index"
import * as ProsemirrorNodes from "../prosemirrorNodes"

export const initialDoc = () => ({
  type: "doc",
  content: [makeFreeTextBlock('')]
}) as TipTapDocument

export const createNewDoc = (document: ProsemirrorNodes.TipTapDocument) => {
  const fragment = Fragment.fromJSON(schema, document.content)
  return schema.node("doc", null, fragment)
}
import { Editor } from "@tiptap/core";
import VeNode from '../VeNode';
// import { makeQuestionBlock, makeSectionBlock, makeFreeTextBlock } from "../makeTypes"
// import { defaultContentForType, insertContentForCommand } from "./insertContentForCommand"

export const GLOBAL_MAX_NESTING_LEVEL = 9
const SECTION_NESTING_LEVEL = 1

type ShouldShowFunction = ({ editor }: { editor: Editor }) => boolean

export type Command = Readonly<{
  type: 'block' | 'question' | 'section' | 'text'
  name: string
  description?: string
  maxNestingLevel: number
  shouldShow?: ShouldShowFunction,
  onClick?: (editor: Editor) => void
}>


const not = (fn: ShouldShowFunction) => (...args: Parameters<ShouldShowFunction>) => !fn(...args);
const and = (...fns: ShouldShowFunction[]) => (...args: Parameters<ShouldShowFunction>) =>
  fns.every((fn) => !!fn(...args))

const isOnlyWhitespaceAndSearch = (text: string) => !/^\/\S*$/.test(text)

const whenCurrentBlockIsEmpty = ({ editor }: { editor: Editor }): boolean => {
  const { state } = editor;
  const { $from } = state.selection;

  const node = VeNode
    .fromResolvedPosition($from)


  const thereIsNoNode = !node
  const isEmpty = thereIsNoNode || isOnlyWhitespaceAndSearch(node.textContent())
  return isEmpty
};

export const SLASH_COMMANDS: Command[] = [
  {
    type: 'text',
    name: 'Text',
    description: '(Turn into text)',
    maxNestingLevel: GLOBAL_MAX_NESTING_LEVEL + 1,
    // shouldShow: not(whenCurrentBlockIsEmpty),
    shouldShow: () => true,
    onClick: (editor: Editor) => {
      editor
        .chain()
        .convertNodeToType('freeText')
        .deleteToLastSlash()
        .run();
    }
  },
  {
    type: 'question',
    name: 'Question',
    description: '(Turn into a question)',
    maxNestingLevel: GLOBAL_MAX_NESTING_LEVEL - 1,
    // shouldShow: not(whenCurrentBlockIsEmpty),
    shouldShow: () => true,
    onClick: (editor: Editor) => {
      editor
        .chain()
        .deleteToLastSlash()
        .convertNodeToType('question')
        .addEmptyChildIfThereAreNone()
        .run();
    }
  },
  {
    type: 'section',
    name: 'Section',
    description: '(Turn into a section)',
    maxNestingLevel: SECTION_NESTING_LEVEL,
    shouldShow: () => true,
    // shouldShow: not(whenCurrentBlockIsEmpty),
    onClick: (editor: Editor) => {
      editor
        .chain()
        .deleteToLastSlash()
        .convertNodeToType('section')
        .addEmptyChildIfThereAreNone()
        .run();
    }
  },
  // {
  //   type: 'question',
  //   name: 'Question',
  //   description: '(Add a new question)',
  //   maxNestingLevel: GLOBAL_MAX_NESTING_LEVEL - 1,
  //   shouldShow: whenCurrentBlockIsEmpty,
  //   onClick: (editor: Editor) => {
  //     insertContentForCommand(editor, makeQuestionBlock('', [makeFreeTextBlock('')]));
  //     editor.commands.moveCursorToPreviousNode();
  //   }
  // },
  // {
  //   type: 'section',
  //   name: 'Section',
  //   description: '(Add a new section)',
  //   maxNestingLevel: SECTION_NESTING_LEVEL,
  //   shouldShow: whenCurrentBlockIsEmpty,
  //   onClick: (editor: Editor) => {
  //     insertContentForCommand(editor, makeSectionBlock(''));
  //   }
  // },
]


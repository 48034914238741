import { css } from "@emotion/react"

type Props = {
  children: React.ReactNode | React.ReactNode[]
  elementRef?: React.MutableRefObject<HTMLDivElement | null>
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const cardCss = css`
  width: 100%;
  display: block;
  cursor: default;
  box-shadow: 1px 1px 8px 4px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 25px;
  margin: 15px;
  background-color: inherit;
`

export const Card = ({ children, elementRef, ...props }: Props) => {

  return (
    <div
      ref={elementRef}
      css={cardCss}
      {...props}
    >
      {children}
    </div>
  )
}
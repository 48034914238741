import { Extension } from '@tiptap/react'

import VeNode from '../VeNode';
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    Convert: {
      convertNodeToType: (type: string) => ReturnType,
    }
  }
}

export default Extension.create<{}>({
  name: 'Convert',
  addCommands() {
    return {
      convertNodeToType: (type: string) => ({ state }) => {
        const { tr: transaction, schema } = state;
        const { $from } = state.selection;
        const {[type]: nodeType} = schema.nodes;

        if(!nodeType){
          console.error(`Cound not convert because node type '${type}' does not exist`);
          return false;
        }

        const node = VeNode.fromResolvedPosition($from);
        const nestableNode = node?.findNestableParent(state);
  
        if(!nestableNode){
          console.error(`Cound not convert to node type '${type}' because the node could not be found`);
          return false;
        }


        transaction.setNodeMarkup(nestableNode.pos(), nodeType);

        return true;
      }
    };
  }
});
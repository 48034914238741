import { Button, Container, Heading, Stack, Text, Textarea } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { DocumentEditorWithOutline } from '../document/DocumentEditorWithOutline'

import './OpportunityStyles.css'
import { ProjectEditor } from './ProjectEditor'


type Props = {
  opportunityDescription: string | null
  pressReleaseId?: string
  faqId?: string
  prdId?: string
  guideId?: string
}

export const OpportunityPageWrapper = (props: Props) => {
  const {
    pressReleaseId,
    faqId,
    prdId,
    guideId,
    opportunityDescription,
  } = props

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [descriptionText, setDescriptionText] = useState(opportunityDescription || '')
  const [project, setProject] = useState(null)


  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    var selectedTab = urlParams.get("tab")
    console.log("selectedTab")
    if (selectedTab === "pr") {
      setCurrentTabIndex(1)
    }
  }, [])

  return <div className="splitView">
    <div className='mainContent'>
      <Heading>Opportunity Name</Heading>
      <Tabs isLazy
        index={currentTabIndex}
        onChange={(index) => setCurrentTabIndex(index)} >
        <TabList>
          <Tab>
            {!!props.opportunityDescription && <CheckIcon color='green.500' boxSize={4} marginRight={2} />}
            <span> Description</span>
          </Tab>
          <Tab>
            {props.pressReleaseId && <CheckIcon color='green.500' boxSize={4} marginRight={2} />}
            <span>Mock Press Release</span>
          </Tab>
          <Tab>{!!props.guideId && <CheckIcon color='green.500' boxSize={4} marginRight={2} />}
            <span>Project</span>
          </Tab>
        </TabList>

        <TabPanels>

          <TabPanel>
            <Container>
              <Heading>Description</Heading>
              <p>
                The detailed description of the opportunity you are exploring
                (i.e., a customer problem or a business outcome).
              </p>

              <Textarea
                value={descriptionText}
                onChange={(evt) => setDescriptionText(evt.target.value)}
                placeholder='start typing here ...'
                size='lg'
                height={400}
              />
            </Container>
          </TabPanel>

          <TabPanel>
            <Heading>Mock Press Release</Heading>
            {
              !pressReleaseId ? null :
                <DocumentEditorWithOutline
                  documentId={pressReleaseId}
                  showBackbutton={false}
                />
            }
          </TabPanel>

          {/* <TabPanel>
            <Heading>Mock FAQ (Frequently Asked Questions)</Heading>
            {
              !faqId ? null :
                <DocumentEditor
                  documentId={faqId}
                  showBackbutton={false}
                />
            }
          </TabPanel> */}


          <TabPanel>
            <ProjectEditor />
          </TabPanel>


        </TabPanels>
      </Tabs>
    </div>
    <div className='sideBar'>


      {currentTabIndex === 0 &&
        <small></small>
      }
      {currentTabIndex === 1 &&

        <Stack spacing={4} fontSize="xl" justifyContent="space-between">
          <div>
            <p>
              Here is a current draft of a mock press Release,
              feel free to edit it until it is to your liking.
            </p>
            <p>
              Once you feel good about this, press the FAQ tab
              to review the next thing.
            </p>

            <Button onClick={() => setCurrentTabIndex(2)}
              colorScheme="blue"
              variant="solid">Continue to Projects</Button>
          </div>

          <div className='regenerateOptions'>
            <p>Not liking my suggestions? </p>
            <Button colorScheme="blue" variant="outline">Regenerate document</Button>
          </div>

        </Stack>
      }
      {currentTabIndex === 2 &&
        <small></small>
      }
    </div>
  </div>
}
import { css } from "@emotion/react"
import { Editor } from "@tiptap/core"
import { ReactNode } from "react"

export type SectionRef = {
  length: number,
  isLoading: boolean,
  insertElement: (editor: Editor) => void
}

export type SectionProps = {
  search: string
  selectedIndex: number
  nestingLevel: number
  editor: Editor
}


const sectionTitleCss = css`
  color: #999;
  margin-bottom: 5px;
  font-size: 13px;
`
const sectionCss = css`
  padding: 15px;
  list-style: none;
  margin-left: 0;
  padding-bottom: 10px;
  &:not(:last-child){
    border-bottom: 1px #ccc solid;
  }
`


type Props = {
  title: string
  children?: ReactNode | ReactNode[]
}

export const CommandMenuSection = ({
  title,
  children,
}: Props) => {
  return (
    <ul
      css={sectionCss}
      contentEditable={false}
    >
      <h6 css={sectionTitleCss}>
        {title}
      </h6>
      <div>
        {children}
      </div>
    </ul>
  )
}
import { Box, Heading } from '@chakra-ui/react'
import graphql from 'babel-plugin-relay/macro'
import { format } from 'date-fns'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ZoomDetailsForDocumentAttachButton } from './ZoomDetailsForDocumentAttachButton'
import { ZoomDetailsForDocumentAttachQuery } from './__generated__/ZoomDetailsForDocumentAttachQuery.graphql'

const queryQL = graphql`
  query ZoomDetailsForDocumentAttachQuery  {
    zoomMeetingConnection {
    edges {
      node {
        id
        topic
        zoomMeetingId
        startTime
        durationInMinutes
        closedCaptioningDownloadUrl
        closedCaptioningText
        transcriptDownloadUrl
        transcriptPlayUrl
        transcriptText
      }
    }
  }
  }
`

type Props = {
  documentId: string
  callbackOnAttach(): void
}

export function ZoomDetailsForDocumentAttach(props: Props) {

  const query = useLazyLoadQuery<ZoomDetailsForDocumentAttachQuery>(queryQL, {})

  return (
    <Box>
      {query.zoomMeetingConnection.edges.length === 0 &&
        <div>
          <p>No Zoom meetings found</p>
        </div>
      }
      {query.zoomMeetingConnection.edges.length > 0 &&
        <div>
          {query.zoomMeetingConnection.edges.map((edge, i) =>
            <div className='listedItem' key={i}>
              <Heading as='h3'>
                {edge.node.topic} <br />
                <small>{edge.node.zoomMeetingId}</small>
              </Heading>

              {format(new Date(edge.node.startTime), "MMM dd, yyyy - hh:mm aaa")} - {edge.node.durationInMinutes} min

              <ZoomDetailsForDocumentAttachButton
                documentId={props.documentId}
                zoomMeetingId={edge.node.id}
                callbackOnAttach={props.callbackOnAttach} />
              <div className='statusIcons'>
                <span className={edge.node.closedCaptioningDownloadUrl ? "complete" : ""}>CC DL</span>
                <span className={edge.node.closedCaptioningText ? "complete" : ""}>CC TXT</span>
                <span className={edge.node.transcriptDownloadUrl ? "complete" : ""}>Tran DL</span>
                <span className={edge.node.transcriptPlayUrl ? "complete" : ""}>Tran Play</span>
                <span className={edge.node.transcriptText ? "complete" : ""}>Trans TXT</span>
              </div>
            </div>
          )}
        </div>
      }
    </Box>

  )
}
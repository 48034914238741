const envy = process.env

const localConfig = {
  stage: 'local',
  domain: `http://local-app.getverdi.com:4000`,
  jwtSecret: `justdev...notsosecret`,
  databaseUrl: process.env.databaseUrl!,
  cookieDomain: undefined,

  awsCredentials: {
    secretAccessKey: process.env.awsSecretAccessKey!,
    region: 'us-west-1',
    accessKeyId: process.env.awsAccessKeyId || `AKIAQTEMPU3BJUWQJKB4`,
  },
  lambdaNames: {
    onConnect: 'websockets-dev-onConnect',
  },
  invokeLambdaEndpoint: 'http://localhost:3002',
  websocketAPIGatewayEndpoint: 'http://localhost:3001',
  awsSqsZoomWebhooksUrl: process.env.awsSqsZoomWebhooksUrl || 'https://sqs.us-west-2.amazonaws.com/041096816322/zoom-webhooks-v1-dev',
  redis: {
    host: 'localhost',
    cluster: false,
    tls: false,
  },

  googleClientIds: [
    '264776103570-5an97ob72q06t2rfgfqtngsj9t7ar288.apps.googleusercontent.com'
  ],
  seedAccountIdHexString: '1ED32F396C506750B69D6183C8DD1322',
  zoomRedirectUrl: process.env.zoomRedirectUrl || 'http://local-app.getverdi.com:3000/zoom/connect',
  zoomClientId: process.env.zoomClientId || 'b_PPciiQQ6OuFb1vJCkxFA',
  zoomClientSecret: process.env.zoomClientSecret!,

  openAIApiKey: process.env.openAIApiKey,
} as const

const prodConfig = {
  stage: 'production',
  domain: `https://getverdi.com`,
  jwtSecret: process.env.authSecret!,
  cookieDomain: 'getverdi.com',

  awsCredentials: {
    secretAccessKey: process.env.awsSecretAccessKey!,
    region: 'us-west-1',
    accessKeyId: `AKIAQTEMPU3BJUWQJKB4`,
  },
  lambdaNames: {
    onConnect: 'arn:aws:lambda:us-west-1:041096816322:function:websockets-production-onConnect',
  },
  invokeLambdaEndpoint: undefined,
  websocketAPIGatewayEndpoint: 'https://8m95ywugab.execute-api.us-west-1.amazonaws.com/production',
  awsSqsZoomWebhooksUrl: process.env.awsSqsZoomWebhooksUrl || 'https://sqs.us-west-2.amazonaws.com/041096816322/zoom-webhooks-v1-production',

  googleAPIsPrivateKey: process.env.googleAPIsPrivateKey!,
  databaseUrl: process.env.databaseUrl!,

  redis: {
    host: 'clustercfg.websocket-memorydb-cluster.7z0wea.memorydb.us-west-1.amazonaws.com',
    cluster: true,
    tls: true,
  },

  googleClientIds: [
    '264776103570-5an97ob72q06t2rfgfqtngsj9t7ar288.apps.googleusercontent.com'
  ],
  seedAccountIdHexString: '1ED332A587EC6760B5EDCF460EF6FE00',
  zoomRedirectUrl: process.env.zoomRedirectUrl || 'https://app.getverdi.com/zoom/connect',
  zoomClientId: process.env.zoomClientId || 'b_PPciiQQ6OuFb1vJCkxFA',
  zoomClientSecret: process.env.zoomClientSecretKey!,

  openAIApiKey: process.env.openAIApiKey,
} as const

const stage = process.env.stage
console.log(`configuring for stage: ${stage}`)
export const environmentConfig = stage === 'production' ? prodConfig : localConfig

let keysWithoutValues: string[] = []
for (const key of Object.keys(environmentConfig)) {
  if (typeof key === 'undefined' || key === null) {
    keysWithoutValues.push(key)
  }
}

if (keysWithoutValues.length) {
  throw new Error(`Missing configuration values for ${keysWithoutValues.join(',')}`);
}
import { Button } from '@chakra-ui/react';
import graphql from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay/hooks'
import { ZoomDetailsForDocumentRemoveButtonMutation } from './__generated__/ZoomDetailsForDocumentRemoveButtonMutation.graphql';

const mutationQL = graphql`
  mutation ZoomDetailsForDocumentRemoveButtonMutation (
    $input: UpdateZoomMeetingForDocumentInput!
  ) @raw_response_type {
    updateZoomMeetingForDocument(input: $input){
      ...ZoomDetailsForDocument_document
    }
  }
`

type Props = {
  documentId: string
}

export function ZoomDetailsForDocumentRemove(props: Props) {

  const [commit, isSaving] = useMutation<ZoomDetailsForDocumentRemoveButtonMutation>(mutationQL)

  return (
    <div>
      <Button
        isLoading={isSaving}
        disabled={isSaving} onClick={() => commit({
          variables: {
            input: {
              documentId: props.documentId
            }
          }
        })}>Remove Zoom meeting</Button>
    </div>
  )
}
import graphql from 'babel-plugin-relay/macro'
import { useRouter } from 'found'
import { useRef, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { DocumentSettingsEditor } from './DocumentSettingsEditor'
import { DocumentEditorWithOutlineQuery } from './__generated__/DocumentEditorWithOutlineQuery.graphql'
import { css } from '@emotion/react'
import { DocumentOriginBanner } from './DocumentOriginBanner'
import './debug-css.css'
import { TipTapBlockOutline } from './TipTapBlockOutline'
import { ZoomDetailsForDocument, ZoomTranscriptForDocument } from '../zoom/ZoomDetailsForDocument'
import { useUrlParams } from '../../utility-hooks/useUrlParams'
import { Collaborators } from './Collaborators'
import { useDocumentSubscription } from '../../components/documentSubscriptions/useDocumentSubscription'
import { ProsemirrorNodes } from 'shared-constants'
import { TiptapEditor } from './tiptapEditor/TipTapEditor'

export const queryQL = graphql`
  query DocumentEditorWithOutlineQuery ($id: ID!){
    ...TipTapEditor_query
    node(id: $id){
      id
      ... on Document {
        title
        type
        ...DocumentSettingsEditor_document
        ...DocumentOriginBanner_document
        ...TipTapEditor_document
        ...ZoomDetailsForDocument_document
      }
    }
  }
`

const outlineWidth = 300
const mainEditorPadding = 10
const idkWhyFudge = -2
type Props = {
  documentId?: string
  showBackbutton?: boolean
}

function UnSuspendedDocumentEditorWithOutline(props: Props) {
  const [tipTapData, setTipTapData] = useState<ProsemirrorNodes.TipTapDocument | undefined>(undefined)
  const [selectedZoomTimestamp, setSelectedZoomTimestamp] = useState<string>()
  const router = useRouter()
  const documentId = props.documentId || router.match.params['documentId']
  const response = useLazyLoadQuery<DocumentEditorWithOutlineQuery>(queryQL, { id: documentId })
  const document = response.node
  const [autoFocusTitle] = useState(!document?.title?.length)
  const scrollToTranscriptRef = useRef<ZoomTranscriptForDocument>(null)
  const { debug: shouldDebug, zoom: showZoom, collab: collabEnabled } = useUrlParams()
  const documentSubscription = useDocumentSubscription(collabEnabled ? documentId : undefined)

  if (!document)
    throw new Error(`No document found for id`);

    const setDocumentPosition = (args: any) => {
      console.log(args)
    }
  return (
    <>
      <DocumentOriginBanner
        document={document}
        leftPadding={outlineWidth + mainEditorPadding + idkWhyFudge}
      />
      <div css={css`
        width: 100%;
        max-width: 100%;
        margin: auto;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
      `}>
        <div css={css`
          width: ${shouldDebug ? outlineWidth * 1.3 : outlineWidth}px;
          padding-right: 10px;
          `}>
          {
            !tipTapData?.content ? null :
              <SuspenseErrorBoundary>
                <TipTapBlockOutline blocks={tipTapData.content} showBackbutton={props.showBackbutton} />
              </SuspenseErrorBoundary>
          }
        </div>
        <div
          className={shouldDebug ? 'debug' : ''}
          css={css`
          flex: 1;
          padding: ${mainEditorPadding}px;
          min-width: 0;
          padding-bottom: 150px;
      `}>
          <DocumentSettingsEditor
            document={document}
            autoFocusTitle={autoFocusTitle}
            setDocumentPosition={setDocumentPosition}
          />
          {
            typeof documentSubscription !== 'undefined' &&
              (!documentSubscription.initialDoc) ? null :
              <TiptapEditor
                document={document}
                query={response}
                setTipTapData={setTipTapData}
                showDevTools={shouldDebug}
                selectedZoomTimestamp={selectedZoomTimestamp}
                scrollToTranscriptRef={scrollToTranscriptRef}
                documentSubscription={documentSubscription}
                setDocumentPosition={setDocumentPosition}
              />
          }
        </div>
        {
          showZoom && document && document.type === "interview" &&
          <ZoomDetailsForDocument document={document} ref={scrollToTranscriptRef} />
        }
        {
          typeof documentSubscription !== 'undefined' &&
          <Collaborators
            documentId={document.id}
            readyState={documentSubscription.readyState}
            subscriberIds={documentSubscription.subscriberIds}
          />
        }

      </div >
    </>
  )
}

export const DocumentEditorWithOutline = (props: Props) => (
  <SuspenseErrorBoundary>
    <UnSuspendedDocumentEditorWithOutline {...props} />
  </SuspenseErrorBoundary>
)
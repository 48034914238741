import { useToast } from "@chakra-ui/react";
import { config } from "process";
import { useMutation } from "react-relay";

const isProd = process.env.NODE_ENV === `production`

const id = `mutation-error`

export const useErrorHandledMutation: typeof useMutation = (...args) => {
  const [commit, ...rest] = useMutation(...args)
  const toast = useToast()
  const errorHandledCommit: typeof commit = (config) =>
    commit({
      ...config,
      onError: (...args) => {
        if (!toast.isActive(id))
          toast({
            id,
            title: 'Error Saving.',
            description: "Something went wrong. Please try again soon.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        if (!isProd) {
          console.error(args[0])
          throw args[0]
        }
        if (config.onError) {
          config.onError(...args)
        }
      },
    })
  return [errorHandledCommit, ...rest]
}
import { Button } from '@chakra-ui/react';
import graphql from 'babel-plugin-relay/macro'
import { props } from 'lodash/fp';
import { useMutation } from 'react-relay/hooks'
import { ZoomDetailsForDocumentAttachButtonMutation } from './__generated__/ZoomDetailsForDocumentAttachButtonMutation.graphql';

const mutationQL = graphql`
  mutation ZoomDetailsForDocumentAttachButtonMutation (
    $input: UpdateZoomMeetingForDocumentInput!
  ) @raw_response_type {
    updateZoomMeetingForDocument(input: $input){
      ...ZoomDetailsForDocument_document
    }
  }
`

type Props = {
  documentId: string,
  zoomMeetingId: string,
  callbackOnAttach(): void
};

export function ZoomDetailsForDocumentAttachButton({ documentId, zoomMeetingId, callbackOnAttach }: Props) {

  const [commit, isSaving] = useMutation<ZoomDetailsForDocumentAttachButtonMutation>(mutationQL)

  return (
    <div>
      <Button
        isLoading={isSaving}
        disabled={isSaving} onClick={() => {
          commit({
            variables: {
              input: {
                documentId,
                zoomMeetingId
              }
            }
          })
          callbackOnAttach()
        }}>Attach</Button>
    </div>

  )
}
import { css } from '@emotion/react'

const tableBorderStyles = css`
  border: solid 1px darkgray;
`
const tableCellSpacing = css`
  padding: 4px;
`

export const tableCellStyling = css`
  ${tableBorderStyles}
  ${tableCellSpacing}
`

export const firstColumnCellStyling = css`
  ${tableCellStyling}
  font-weight: bold;
`
import { findParentNodeClosestToPos } from "prosemirror-utils"
import { ResolvedPos, Node } from "prosemirror-model"

const nestables = ['question', 'section', 'freeText']

export const isNestableNodePredicate = (node: Node) => {
  const isNestableNode = nestables.includes(node.type.name)
  return isNestableNode
}

export const findParentNestableNodeClosestToPos = (pos: ResolvedPos) => {
  const parent = findParentNodeClosestToPos(pos, isNestableNodePredicate)
  return parent
}
/**
 * @generated SignedSource<<bbdd80bb7987fcd227966a8301ac2320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpportunityDocumentQuery$variables = {
  opportunityId: string;
  shouldSkip: boolean;
};
export type OpportunityDocumentQuery$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
  } | null;
};
export type OpportunityDocumentQuery = {
  response: OpportunityDocumentQuery$data;
  variables: OpportunityDocumentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "opportunityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldSkip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "opportunityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpportunityDocumentQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OpportunityDocumentSideMenu_opportunity"
                  }
                ],
                "type": "Opportunity",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpportunityDocumentQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": "opportunities",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "opportunity"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": "documents(type:\"opportunity\")"
                  },
                  {
                    "alias": "pressReleases",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "pressRelease"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": "documents(type:\"pressRelease\")"
                  },
                  {
                    "alias": "faqs",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "faq"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": "documents(type:\"faq\")"
                  },
                  {
                    "alias": "prds",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "prd"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": "documents(type:\"prd\")"
                  },
                  {
                    "alias": "interviewGuides",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "guide"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Document",
                        "kind": "LinkedField",
                        "name": "copies",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "documents(type:\"guide\")"
                  }
                ],
                "type": "Opportunity",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9bf17a0601257ce4724802db195b09b5",
    "id": null,
    "metadata": {},
    "name": "OpportunityDocumentQuery",
    "operationKind": "query",
    "text": "query OpportunityDocumentQuery(\n  $opportunityId: ID!\n  $shouldSkip: Boolean!\n) {\n  node(id: $opportunityId) @skip(if: $shouldSkip) {\n    __typename\n    ... on Opportunity {\n      ...OpportunityDocumentSideMenu_opportunity\n    }\n    id\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  title\n  description\n  opportunities: documents(type: opportunity) {\n    id\n  }\n  pressReleases: documents(type: pressRelease) {\n    id\n  }\n  faqs: documents(type: faq) {\n    id\n  }\n  prds: documents(type: prd) {\n    id\n  }\n  interviewGuides: documents(type: guide) {\n    id\n    title\n    copies {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1630fc474af74abd26d1724e76234d6d";

export default node;

import graphql from 'babel-plugin-relay/macro'
import {
  useCreateOpportunityMutation,
  CreateOpportunityInput,
  useCreateOpportunityMutation$data,
} from './__generated__/useCreateOpportunityMutation.graphql';
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';

const mutationQL = graphql`
  mutation useCreateOpportunityMutation (
    $input: CreateOpportunityInput!
    $updateConnectionIds: [ID!]!
  ) {
   createOpportunity(input: $input) @appendNode(connections: $updateConnectionIds, edgeTypeName: "OpportunityEdge") {
    id 
    title 
    description 
   }    
  }
`
export function useCreateOpportunity(
  onCreated?: (response: useCreateOpportunityMutation$data['createOpportunity']) => void,
  updateConnectionIds?: string[]
) {
  const [commit, isSaving] = useErrorHandledMutation<useCreateOpportunityMutation>(mutationQL)
  const createOpportunity = useCallback((
    input: CreateOpportunityInput
  ) => {
    commit({
      variables: {
        input,
        updateConnectionIds: updateConnectionIds || [],
      },
      onCompleted: (response) => {
        if (onCreated) {
          onCreated(response.createOpportunity)
        }
      }
    })
  }, [])
  return [createOpportunity, isSaving] as const
}

import { PromptFunction } from "./prompt"


export const faqAiPrompt: PromptFunction = () => {
  return {
    documentDescription: 'FAQ',
    sharedOpenAIParams: { max_tokens: 200 },
    steps: [
      {
        prompt: `List 8-10 questions that should be addressed before making a decision to invest in the following opportunity. Explore customer, market, product, strategy, operations, go-to-market, financial, and other considerations.`,
        context: `\
desired format: a list of questions, each question on its own line, with 2 lines between each question to give the user space to fill in answers; do not include any preamble (e.g., do not include "Questions to Address Before Making a Decision to Invest in Better Search:")
scope: explore the most important questions to address for this opportunity, potentially including questions relating to feasibility, viability, usability, and value to customers and the business, and exploring both reasons to support this decision and highlighting reasons why we would oppose it
`
      },
    ],
  }
}
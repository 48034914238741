const parseUrlParamString = (text: string) => {
  try {
    return JSON.parse(text)
  } catch {
    return undefined
  }
}


export const useUrlParams = () => {
  const params = new URLSearchParams(window.location.search)
  const object = Object.fromEntries(params)
  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      [key]: parseUrlParamString(object[key]),
    }
  }, {} as Record<string, any>)
}



import graphql from 'babel-plugin-relay/macro'
import {
  useSaveTipTapDataMutation,
  RecursiveBlockInput
} from './__generated__/useSaveTipTapDataMutation.graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandledMutation } from '../../../../utility-hooks/useErrorHandledMutation';
import { BonusGenerics } from 'shared-constants';
import { useThrottle } from '../../../../utility-hooks/useThrottle';
import { ProsemirrorNodes } from 'shared-constants'

const mutationQL = graphql`
  mutation useSaveTipTapDataMutation (
    $input: UpdateDocumentInput!
  ) @raw_response_type {
    updateDocument(input: $input){
      ...BlockEditorDataHandler_document
    }
  }
`

const getTextFromTitleOrParagraph = (paragraphOrTitle: ProsemirrorNodes.Paragraph | ProsemirrorNodes.Title) => {
  const firstChild = paragraphOrTitle.content?.[0]
  if (typeof firstChild !== 'undefined' && 'text' in firstChild) {
    return firstChild.text
  }
  else return ''
}

const getTextAndChildren = (block: ProsemirrorNodes.NestableBlock) => {
  let text: string, blockChildren: ProsemirrorNodes.NestableBlock[] | undefined
  switch (block.type) {
    case 'question':
    case 'section':
    case 'freeText':
      const [titleElement, contentList] = (block as ProsemirrorNodes.NestableBlock).content
      text = getTextFromTitleOrParagraph(titleElement)
      blockChildren = contentList?.content
      break
    // case 'paragraph':
    //   blockChildren = []
    //   text = getTextFromTitleOrParagraph(block)
    //   break
    default:
      // @ts-ignore
      throw Error(`Unhandled type ${block.type}`)
  }
  return { text, blockChildren, type: block.type } as const
}

const makeBlockSaveReady = (
  block: BonusGenerics.ExpandRecursively<ProsemirrorNodes.NestableBlock>,
  index: number
): RecursiveBlockInput | RecursiveBlockInput[] | undefined => {

  const { text, blockChildren, type } = getTextAndChildren(block)
  const { id, originalBlockId, sourceBlockId, transcriptTimestamp } = block.attrs as ProsemirrorNodes.Attributes
  let children = blockChildren?.length ? makeBlocksSaveReady(blockChildren) : undefined
  const saveReadyBlock: RecursiveBlockInput = {
    id: id,
    // these are handled by the server for now
    // originalBlockId,
    // sourceBlockId,
    level: 0,
    type: type,
    order: index,
    questionType: undefined,
    text: text,
    transcriptTimestamp,
    children,
  }
  return saveReadyBlock
}

const makeBlocksSaveReady = (blocks: ProsemirrorNodes.NestableBlock[]) =>
  blocks.map(makeBlockSaveReady)
    .filter(x => typeof x !== `undefined`) as RecursiveBlockInput[]

const confirmationMessage = `You have unsaved changes, are you sure you want to leave?`

export function useSaveTipTapData(documentId: string) {

  const [commit, isSaving] = useErrorHandledMutation<useSaveTipTapDataMutation>(mutationQL)
  // this is to make sure we don't > 1 save request at a time
  // if we do, we risk having an older save take prescedence over a newer one
  const lastUnsavedData = useRef<ProsemirrorNodes.NestableBlock[]>()
  const isSavingRef = useRef(false)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isSavingRef.current) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnload);
  })

  const save = useCallback((rootLevelBlocks: ProsemirrorNodes.NestableBlock[]) => {
    const blocks = makeBlocksSaveReady(rootLevelBlocks)
    isSavingRef.current = true
    commit({
      variables: {
        input: {
          documentId,
          blocks,
        }
      },
      onError: () => {
        isSavingRef.current = false
        setIsDirty(false)
      },
      onCompleted: () => {
        isSavingRef.current = false
        if (lastUnsavedData.current) {
          save(lastUnsavedData.current)
        } else {
          setIsDirty(false)
        }
        lastUnsavedData.current = undefined
      }
    })
  }, [commit])


  const throttledSave = useThrottle(
    save,
    2000,
    { leading: false, trailing: true }
  )

  const concurrencyCheckedAndThrottledSave = useCallback(
    (rootLevelBlocks: ProsemirrorNodes.NestableBlock[]) => {
      if (!isSavingRef.current) {
        throttledSave(rootLevelBlocks)
      } else {
        lastUnsavedData.current = rootLevelBlocks
      }
    }, [save])

  const saveDirtyThrottled = (rootLevelBlocks: ProsemirrorNodes.NestableBlock[]) => {
    setIsDirty(true)
    return concurrencyCheckedAndThrottledSave(rootLevelBlocks)
  }

  return [
    saveDirtyThrottled,
    isDirty || typeof lastUnsavedData.current !== 'undefined'
  ] as const
}
/**
 * @generated SignedSource<<5b8ed9c2d5cbc16041d828924e9b0d45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteDocumentInput = {
  documentId: string;
};
export type useDeleteDocumentMutation$variables = {
  connectionIds: ReadonlyArray<string>;
  input: DeleteDocumentInput;
};
export type useDeleteDocumentMutation$data = {
  readonly deleteDocument: {
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
    }>;
  } | null;
};
export type useDeleteDocumentMutation$rawResponse = {
  readonly deleteDocument: {
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly description: string | null;
      readonly faqs: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly id: string;
      readonly interviewGuides: ReadonlyArray<{
        readonly copies: ReadonlyArray<{
          readonly id: string;
          readonly title: string | null;
        }> | null;
        readonly id: string;
        readonly title: string | null;
      }>;
      readonly opportunities: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly prds: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly pressReleases: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly title: string | null;
    }>;
  } | null;
};
export type useDeleteDocumentMutation = {
  rawResponse: useDeleteDocumentMutation$rawResponse;
  response: useDeleteDocumentMutation$data;
  variables: useDeleteDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OpportunityDocumentSideMenu_opportunity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDeleteDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connectionIds"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": "opportunities",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "opportunity"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "documents(type:\"opportunity\")"
              },
              {
                "alias": "pressReleases",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "pressRelease"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "documents(type:\"pressRelease\")"
              },
              {
                "alias": "faqs",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "faq"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "documents(type:\"faq\")"
              },
              {
                "alias": "prds",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "prd"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "documents(type:\"prd\")"
              },
              {
                "alias": "interviewGuides",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "guide"
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "copies",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "documents(type:\"guide\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57d652bf7f132623ba9145954f402846",
    "id": null,
    "metadata": {},
    "name": "useDeleteDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteDocumentMutation(\n  $input: DeleteDocumentInput!\n) {\n  deleteDocument(input: $input) {\n    id\n    opportunities {\n      ...OpportunityDocumentSideMenu_opportunity\n      id\n    }\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  title\n  description\n  opportunities: documents(type: opportunity) {\n    id\n  }\n  pressReleases: documents(type: pressRelease) {\n    id\n  }\n  faqs: documents(type: faq) {\n    id\n  }\n  prds: documents(type: prd) {\n    id\n  }\n  interviewGuides: documents(type: guide) {\n    id\n    title\n    copies {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "173a370722a38c87583a21df827fbb9f";

export default node;

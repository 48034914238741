import { Suspense } from 'react';
import * as React from 'react';
import ErrorBoundary from './ErrorBoundary'
import LoadingSpinner from './LoadingSpinner';

type Props = {
  children: React.ReactNode
  fallback?: boolean | React.ReactNode | React.ReactFragment | React.ReactPortal | null
  rerunProp?: any
}

const Fallback = ({ className }: { className?: string }) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}>
      <LoadingSpinner />
    </div>
  )
}

export const SuspenseErrorBoundary = ({
  children,
  fallback = < Fallback />
}: Props) =>
  <ErrorBoundary>
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  </ErrorBoundary>
import { useCallback, useEffect, useRef, useState } from "react"
import { ProsemirrorNodes, WebsocketEvents } from "shared-constants"
import { documentSubscriptionsMap } from "./documentSusbcriptionMap"
import { useWebSocket } from "./useWebSocket"

export type ClientSideOpenAIParams = WebsocketEvents.ServerEvents.RequestAIEdit['args']['openAIParams']

const IS_DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

type ClientEvent = {
  eventPayload?: WebsocketEvents.ClientEvents.ClientEventType
}

export type StepPayload = Pick<WebsocketEvents.ClientEvents.DocumentSteps['payload'], 'documentVersion' | 'stepData'>
type StepArg = WebsocketEvents.ServerEvents.ReceiveDocumentSteps['args']['steps'][number]

export type SendSteps = (steps: StepArg[], documentVersion: number, clientId: string) => void

export const useDocumentSubscription = (
  documentId?: string,
) => {

  const [newSteps, setNewSteps] = useState<StepPayload>()
  const [mustRefreshMessage, setMustRefreshMessage] = useState<string>()
  const [initialDoc, setInitialDoc] = useState<{
    version: number,
    body: ProsemirrorNodes.TipTapDocument
  }>()

  // https://stackoverflow.com/a/71982736
  // Ref used to ignore the first load so we don't load twice
  const ignoreTheInitialMountRef = useRef(IS_DEV_MODE)

  const filter = useCallback((payload: MessageEvent<any>) => {
    const event = payload as ClientEvent
    if (event.eventPayload && 'documentId' in event.eventPayload) {
      return event.eventPayload.documentId === documentId
    }
    // TODO: allow this to filter as false, but now we need to get clientId, for example (inside useWebSocket)
    return true
  }, [documentId])

  const { sendMessage, lastMessage, readyState } = useWebSocket({
    filter,
  })
  const documentIdRef = useRef(documentId)
  if (documentIdRef.current !== documentId)
    // this is because the filtering on the above websocket. 
    throw new Error(`Changing documentIds in a subscription hook is not supported old:${documentIdRef.current} new:${documentId}`);

  const [subscriberIds, setSubscriberIds] = useState([] as string[])

  const sendSubscribeMessage = useCallback(
    (documentId: string) => {
      sendMessage({
        eventName: 'SUBSCRIBE_TO_DOCUMENTS',
        args: {
          documentIds: [documentId],
        }
      })
    }, [documentId])

  const sendUnsubscribeMessage = useCallback(
    (documentId: string) => {
      sendMessage({
        eventName: 'UNSUBSCRIBE_FROM_DOCUMENTS',
        args: {
          documentIds: [documentId],
        }
      })
    }, [documentId])


  useEffect(() => {
    if (ignoreTheInitialMountRef.current) {
      ignoreTheInitialMountRef.current = false
      return
    }
    if (!documentId) return

    let subscriptionCallbacks = documentSubscriptionsMap[documentId]
    if (!subscriptionCallbacks) {
      subscriptionCallbacks = 0
      sendSubscribeMessage(documentId)
    }
    documentSubscriptionsMap[documentId] = subscriptionCallbacks + 1
    return () => {
      if (!documentId) return
      if (!documentSubscriptionsMap[documentId]) return
      documentSubscriptionsMap[documentId] -= 1
      if (!documentSubscriptionsMap[documentId]) {
        delete documentSubscriptionsMap[documentId]
        sendUnsubscribeMessage(documentId)
      }
    }

  }, [documentId])

  useEffect(() => {
    (async () => {
      if (!lastMessage || !lastMessage.eventPayload) return
      // only respond to messages for this document
      if (
        'documentId' in lastMessage.eventPayload &&
        lastMessage.eventPayload.documentId === documentId
      ) return
      switch (lastMessage.eventPayload?.eventName) {
        case 'DOCUMENT_SUBSCRIBER_LIST':
          setSubscriberIds(lastMessage.eventPayload.payload.clientIds)
          break;
        case 'DOCUMENT_STEPS':
          setNewSteps(lastMessage.eventPayload.payload)
          break;
        case 'PAGE_REFRESH_REQUIRED':
          setMustRefreshMessage(lastMessage.eventPayload.payload.message)
          break;
        case 'LATEST_DOCUMENT_VERSION':
          const latestDocumentPayload = lastMessage.eventPayload.payload
          const { documentVersion, body, stepsSince } = latestDocumentPayload
          await setInitialDoc({
            version: documentVersion,
            body: body,
          })
          setNewSteps({
            stepData: stepsSince,
            documentVersion,
          })
          break;
        default:
          break;
      }
    })()
  }, [lastMessage])


  const sendSteps: SendSteps = useCallback(

    (steps: StepArg[], documentVersion: number, clientId: string) => {
      if (!documentId) return
      const event: WebsocketEvents.ServerEvents.ReceiveDocumentSteps = {
        eventName: 'RECEIVE_DOCUMENT_STEPS',
        args: {
          documentId,
          steps,
          documentVersion,
          clientId
        }
      }
      sendMessage(event)
    },
    [sendMessage, documentId]
  )

  const requestStepsSinceVersion = useCallback(
    (documentVersion: number) => {
      if (!documentId) return
      const event: WebsocketEvents.ServerEvents.SendDocumentStepsSinceVersion = {
        eventName: 'SEND_DOCUMENT_STEPS_SINCE_VERSION',
        args: {
          documentId,
          documentVersion,
        }
      }
      sendMessage(event)
    },
    [sendMessage, documentId]
  )

  const makeAIEditRequest = useCallback(
    (documentVersion: number, prompt: string, position: number, openAIParams?: ClientSideOpenAIParams) => {
      if (!documentId) return
      const event: WebsocketEvents.ServerEvents.RequestAIEdit = {
        eventName: 'REQUEST_AI_EDIT',
        args: {
          documentId,
          documentVersion,
          prompt,
          position,
          openAIParams,
        }
      }
      console.log(event.args.prompt)
      console.log(event)
      sendMessage(event)
    },
    [sendMessage, documentId]
  )

  if (!documentId) return undefined

  return {
    mustRefreshMessage,
    readyState,
    subscriberIds,
    sendSteps,
    newSteps,
    initialDoc,
    requestStepsSinceVersion,
    makeAIEditRequest
  }
}



export type DocumentSubscription = ReturnType<typeof useDocumentSubscription>
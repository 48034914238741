import { websocketUrl } from "../../auth/network";
import useReactWebSocket, { ReadyState } from 'react-use-websocket'
import { WebsocketEvents } from "shared-constants";

type ClientEvent = {
  eventPayload?: WebsocketEvents.ClientEvents.ClientEventType
}


type Args = {
  onOpen?: () => void,
  filter?: (message: WebSocketEventMap['message']) => boolean;
}

export const useWebSocket = (args?: Args) => {

  const { sendJsonMessage, lastJsonMessage, readyState } = useReactWebSocket(websocketUrl(), {
    shouldReconnect: (event) => true,
    onError: console.error,
    onOpen: () => {
      if (args?.onOpen) {
        args?.onOpen()
      }
      console.log('websocket opened')
    },
    filter: args?.filter,
    onClose: async () => {
      console.log('websocket closed')
    },
    retryOnError: false,
    share: true,
    // onMessage: (event) => console.log(event.data),
  })

  const sendMessage = (outgoingMessage: WebsocketEvents.ServerEvents.ServerEventType) => {
    // console.log(`sending msg: ${outgoingMessage.eventName} ${JSON.stringify(outgoingMessage.args)}`)
    sendJsonMessage({ eventBody: outgoingMessage })
  }

  const lastMessage = lastJsonMessage as ClientEvent

  return { sendMessage, lastMessage, readyState }

}
/**
 * @generated SignedSource<<8ce50ff3478f9d58aaae010433698b2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ZoomDetailsForDocumentAttachQuery$variables = {};
export type ZoomDetailsForDocumentAttachQuery$data = {
  readonly zoomMeetingConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly closedCaptioningDownloadUrl: string | null;
        readonly closedCaptioningText: string | null;
        readonly durationInMinutes: number;
        readonly id: string;
        readonly startTime: string;
        readonly topic: string | null;
        readonly transcriptDownloadUrl: string | null;
        readonly transcriptPlayUrl: string | null;
        readonly transcriptText: string | null;
        readonly zoomMeetingId: string | null;
      };
    }>;
  };
};
export type ZoomDetailsForDocumentAttachQuery = {
  response: ZoomDetailsForDocumentAttachQuery$data;
  variables: ZoomDetailsForDocumentAttachQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QueryZoomMeetingConnection",
    "kind": "LinkedField",
    "name": "zoomMeetingConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryZoomMeetingConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ZoomMeeting",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoomMeetingId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationInMinutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closedCaptioningDownloadUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closedCaptioningText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transcriptDownloadUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transcriptPlayUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transcriptText",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ZoomDetailsForDocumentAttachQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ZoomDetailsForDocumentAttachQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ef3b4d34144bfecd97872c28b2f83f01",
    "id": null,
    "metadata": {},
    "name": "ZoomDetailsForDocumentAttachQuery",
    "operationKind": "query",
    "text": "query ZoomDetailsForDocumentAttachQuery {\n  zoomMeetingConnection {\n    edges {\n      node {\n        id\n        topic\n        zoomMeetingId\n        startTime\n        durationInMinutes\n        closedCaptioningDownloadUrl\n        closedCaptioningText\n        transcriptDownloadUrl\n        transcriptPlayUrl\n        transcriptText\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "317f5c1f9d6e009ef58d5e1a98d69607";

export default node;

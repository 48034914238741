/**
 * @generated SignedSource<<8a61081729a5c97875834260f00b0784>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateZoomMeetingForDocumentInput = {
  documentId: string;
  zoomMeetingId?: string | null;
};
export type ZoomDetailsForDocumentAttachButtonMutation$variables = {
  input: UpdateZoomMeetingForDocumentInput;
};
export type ZoomDetailsForDocumentAttachButtonMutation$data = {
  readonly updateZoomMeetingForDocument: {
    readonly " $fragmentSpreads": FragmentRefs<"ZoomDetailsForDocument_document">;
  } | null;
};
export type ZoomDetailsForDocumentAttachButtonMutation$rawResponse = {
  readonly updateZoomMeetingForDocument: {
    readonly id: string;
    readonly zoomMeeting: {
      readonly closedCaptioningDownloadUrl: string | null;
      readonly closedCaptioningText: string | null;
      readonly durationInMinutes: number;
      readonly id: string;
      readonly startTime: string;
      readonly topic: string | null;
      readonly zoomMeetingId: string | null;
    } | null;
  } | null;
};
export type ZoomDetailsForDocumentAttachButtonMutation = {
  rawResponse: ZoomDetailsForDocumentAttachButtonMutation$rawResponse;
  response: ZoomDetailsForDocumentAttachButtonMutation$data;
  variables: ZoomDetailsForDocumentAttachButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ZoomDetailsForDocumentAttachButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "updateZoomMeetingForDocument",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ZoomDetailsForDocument_document"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ZoomDetailsForDocumentAttachButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "updateZoomMeetingForDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ZoomMeeting",
            "kind": "LinkedField",
            "name": "zoomMeeting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closedCaptioningDownloadUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closedCaptioningText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationInMinutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoomMeetingId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed67545a439f0a50a38fae5b3011473b",
    "id": null,
    "metadata": {},
    "name": "ZoomDetailsForDocumentAttachButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ZoomDetailsForDocumentAttachButtonMutation(\n  $input: UpdateZoomMeetingForDocumentInput!\n) {\n  updateZoomMeetingForDocument(input: $input) {\n    ...ZoomDetailsForDocument_document\n    id\n  }\n}\n\nfragment ZoomDetailsForDocument_document on Document {\n  id\n  zoomMeeting {\n    topic\n    closedCaptioningDownloadUrl\n    closedCaptioningText\n    durationInMinutes\n    startTime\n    zoomMeetingId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7932715fcf436633fe55e0fc5dd22a10";

export default node;

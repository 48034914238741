import { Plugin, EditorState } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import { Extension } from '@tiptap/core'

class ClearEditMarks {
  constructor(view: EditorView) {
    this.update(view, null)
  }

  update(view: EditorView, lastState: EditorState | null) {
    let state = view.state

  }

  destroy() { }
}


export const removeAllAttributionMarksTransaction = (state: EditorState) => {
  const { tr } = state
  const to = state.doc.content.size
  if (to) {
    // console.log(`removing from ${0} to ${to}`)
    const newTr = tr.removeMark(0, to, state.schema.marks['attribution'])
    return newTr
  }
  return tr
}

const clearEditMarksPlugin = new Plugin({
  appendTransaction: (_transactions, lastState, state) => {
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
      lastState.selection.eq(state.selection)) return state.tr

    const selectionMarks = state.selection.$head.marks()
    const isSelectedInAttbributionMark = selectionMarks.find(mark => mark.type.name === 'attribution')
    if (!isSelectedInAttbributionMark) {
      return state.tr
    }

    return removeAllAttributionMarksTransaction(state)
  }
})


export const clearEditMarksExtension = Extension.create({
  name: 'clearAIEditMarks',
  addProseMirrorPlugins() {
    return [
      clearEditMarksPlugin,
    ]
  },
})
import { Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { ColoredIconHolder } from "./ColoredIconHolder"

type Props = {
  title?: string | null
  subtitle?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode | React.ReactNode[]
}

export function Feature({
  title,
  description,
  children,
  subtitle,
  ...rest
}: Props) {
  return (
    <div
      css={css`
        width: 100%;
        flex-direction: column;

        align-items: flex-start;
      `}
      {...rest}
    >
      <HStack
        justifyContent={`space-between`}
        alignItems={`flex-start`}
        width={`100%`}
      >
        <div css={css`
          display: flex;
          flex-direction: column;
        `}>
          <h1 css={css`
            font-size: 30px;
            line-height: 35px;
            font-weight: 600;
            color: #666;
          `}>
            {title}
          </h1>
          <p
            css={css`
              font-size: 20px;
              margin-bottom: 10px;
            `}>
            {description}
          </p>
          {
            subtitle &&
            <div css={css`
              display: flex;
              padding: 2px;
              align-items: center;
              flex-direction:'row';
            `}>
              <ColoredIconHolder
                color={'magenta'}
                hasFocusBrackets
                css={css`
                margin-right:5px;
                `}
              />
              <h6>{subtitle}</h6>
            </div>
          }
        </div>
        <VStack>
          {children}
        </VStack>
      </HStack>
    </div>
  )
}
/***
 * TODO: Find a way to share these types in a common area.
 * Create React app currently makes it challenging,
 * but we don't necessarily want to eject from CRA.
 * Currently duplicated at: 
 * web/src/zoom/Common.ts
 * api/src/zoom/Common.ts
 */

export interface ZoomErrorResponse {
  code: number,
  message: string,
  httpStatusCode: number
}
export class ApiError extends Error {

  public code?: number
  public httpStatusCode!: number

  constructor(statusCode: number, message: string) {
    super()
    this.httpStatusCode = statusCode
    this.message = message
  }
}

export const getApiError = (response: any, jsonBodyData: any): ApiError => {

  const zoomError = jsonBodyData as unknown as ZoomErrorResponse
  console.log("zoomError = ", zoomError)
  const apiError = new ApiError(Number(response.status), zoomError.message);
  apiError.code = zoomError.code
  apiError.message = zoomError.message
  return apiError
}

export interface ZoomRecordingsResponse {
  from: string
  to: string
  page_count: number
  page_size: number
  total_records: number
  next_page_token: string
  meetings: ZoomRecordingsMeetingResponse[]
}

export interface ZoomRecordingsMeetingResponse {
  uuid: string
  id: number
  account_id: string
  host_id: string
  topic: string
  type: number
  start_time: string
  timezone: string
  duration: number
  total_size: number
  recording_count: number
  share_url: string
  recording_files: ZoomMeetingRecordingsFileResponse[]
}


/*
  Models used by both API and Client
*/

export interface ZoomMeeting {
  // TODO: DB should connect this to a ZoomMeeting and an Organization

  zoomMeetingUUID: string
  zoomMeetingId: string

  zoomAccountId: string
  hostId: string
  topic: string
  type: number
  startTime: string
  timezone: string
  durationInMinutes: number
  shareUrl?: string
  password?: string,
  recordingPlayPasscode?: string

  audioRecordingDownloadUrl?: string
  audioRecordingPlayUrl?: string
  closedCaptioningDownloadUrl?: string
  closedCaptioningText?: string
  transcriptText?: string
  transcriptDownloadUrl?: string
  transcriptPlayUrl?: string
  videoRecordingDownloadUrl?: string
  videoRecordingPlayUrl?: string

}

export const fromZoomRecordingsMeetingResponse = (raw: ZoomRecordingsResponse): ZoomMeeting[] => {

  const toReturn: ZoomMeeting[] = []

  console.log("RAW RAW MEETINGS = ", raw)

  raw.meetings.forEach(meeting => {
    toReturn.push(fromZoomRecordingDto(meeting))
  })

  return toReturn
}


export const fromZoomRecordingDto = (meeting: ZoomRecordingsMeetingResponse): ZoomMeeting => {

  const toAdd: ZoomMeeting = {
    zoomMeetingUUID: meeting.uuid,
    zoomMeetingId: meeting.id.toString(),
    zoomAccountId: meeting.account_id,
    durationInMinutes: meeting.duration,
    hostId: meeting.host_id,
    startTime: meeting.start_time,
    timezone: meeting.timezone,
    topic: meeting.topic,
    type: meeting.type,
    shareUrl: meeting.share_url
  }

  meeting.recording_files.forEach(file => {
    switch (file.file_type) {
      case ZOOM_FILE_TYPE.M4A:
        console.log("M4A")
        toAdd.audioRecordingDownloadUrl = file.download_url
        toAdd.audioRecordingPlayUrl = file.play_url
        break;

      case ZOOM_FILE_TYPE.MP4:
        console.log("MP4")

        toAdd.videoRecordingDownloadUrl = file.download_url
        toAdd.videoRecordingPlayUrl = file.play_url
        break;

      case ZOOM_FILE_TYPE.CC:
        toAdd.closedCaptioningDownloadUrl = file.download_url
        // TODO: download the text file into a string toAdd.closedCaptioningText = file.
        break;

      case ZOOM_FILE_TYPE.TRANSCRIPT:
        toAdd.transcriptDownloadUrl = file.download_url
        toAdd.transcriptPlayUrl = file.play_url
        // TODO: Download text file
        break;

      default:
        console.log("Met default")

    }
  })

  return toAdd
}


interface ZoomMeetingRecordingsFileResponse {
  file_type: ZOOM_FILE_TYPE | string
  file_extension: string
  file_size: number
  play_url: string
  download_url: string
  status: ZOOM_STATUS | string
  recording_type: ZOOM_RECORDING_TYPE | string
}

enum ZOOM_FILE_TYPE {
  MP4 = "MP4",
  M4A = "M4A",
  TIMELINE = "TIMELINE",
  TRANSCRIPT = "TRANSCRIPT",
  CC = "CC",
}

enum ZOOM_RECORDING_TYPE {
  shared_screen_with_speaker_view_cc = "shared_screen_with_speaker_view(CC)",
  audio_only = "audio_only",
  timeline = "timeline",
  audio_transcript = "audio_transcript",
  closed_caption = "closed_caption",
}

enum ZOOM_STATUS {
  completed = "completed"
}

/**
 * @generated SignedSource<<6e2fa8a67bf5898020abd2a569c1c03a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
export type QuestionType = "freeResponse" | "multipleChoice" | "numeric" | "picture" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTipTapData_Block$data = {
  readonly id: string;
  readonly level: number;
  readonly originalBlockId: string;
  readonly questionType: QuestionType | null;
  readonly sourceBlockId: string | null;
  readonly text: string;
  readonly transcriptTimestamp: string | null;
  readonly type: BlockType;
};
export type useTipTapData_Block$key = {
  readonly " $data"?: useTipTapData_Block$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTipTapData_Block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "useTipTapData_Block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceBlockId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalBlockId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transcriptTimestamp",
      "storageKey": null
    }
  ],
  "type": "Block",
  "abstractKey": null
};

(node as any).hash = "4edabd92d51ab40a0d03b0ae03ab7269";

export default node;

import { Node } from '@tiptap/core'

const CLASS_NAME = 'title'

export const Title = Node.create({
  name: CLASS_NAME,
  content: "text*",
  isInline: false,
  inlineContent: true,
  selectable: false,
  defining: true,
  isTextblock: true,

  parseHTML() {
    return [
      {
        tag: 'p',
        getAttrs: node => typeof node !== 'string' && node.className.includes(CLASS_NAME) && null,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', {
      ...HTMLAttributes,
      class: CLASS_NAME
    }, 0]
  },
  // Your code goes here.
})
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { useRouter } from 'found';
import { useState } from 'react';
import { useCreateOpportunity } from '../opportunities/useCreateOpportunity';
import { OpportunityDocumentSideMenuItem } from './OpportunityDocumentSideMenuItem';
import {useColorModeValue} from '@chakra-ui/react'

type Props = {
  updateConnectionIds: string[]
};

const initialOpportunityState = {
  title: '',
  description: '',
}

export function NewOpportunityModal({ updateConnectionIds }: Props) {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [opportunity, setOpportunity] = useState(initialOpportunityState)

  const [createOpportunity, isSaving] = useCreateOpportunity((response) => {
    setOpportunity(initialOpportunityState)
    const url = `/opportunity/${response.id}`
    router.router.push(url)
    onClose()
  },
    updateConnectionIds
  )

  const isValid = opportunity.title.length && opportunity.description.length

  const save = () => {
    if (!isValid) return
    createOpportunity(opportunity)
  }

  const modalBackground = useColorModeValue('#FFF','#181818');

  return (
    <>
      <Button onClick={onOpen}
        size='sm'
        variant='ghost'
        justifyContent='left'
        color='GrayText'>
        <AddIcon boxSize={2} mr={1} /> <span>Add Opportunity</span>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background={modalBackground}>
          <ModalHeader>Create New Opportunity</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Input
                value={opportunity?.title}
                onChange={(evt) => setOpportunity({
                  ...opportunity,
                  title: evt.target.value,
                })}
                placeholder='Title'
              />
              <Textarea
                value={opportunity?.description}
                onChange={(evt) => setOpportunity({
                  ...opportunity,
                  description: evt.target.value,
                })}
                placeholder='Description'
                size='lg'
                height={200}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} variant='ghost'>
              Cancel
            </Button>
            <Button
              isLoading={isSaving}
              onClick={save}
              disabled={!isValid}
              colorScheme='blue'
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { IconTitleButton } from '../../../components/IconTitleButton';
import { GuideInterviewListItem_document$key } from './__generated__/GuideInterviewListItem_document.graphql';

const fragmentQL = graphql`
  fragment GuideInterviewListItem_document on Document {
    id
    title
    createdAt
  }
`
const formatDate = (date: Date) =>
  new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric" })


type Props = { document: GuideInterviewListItem_document$key; }

export function GuideInterviewListItem({ document: documentKey }: Props) {

  const document = useFragment(fragmentQL, documentKey)
  const created = document.createdAt as Date
  return (
    <IconTitleButton
      title={document.title || ''}
      subtitle={formatDate(created)}
      color={'magenta'}
      onClick={(event) => {
        const url = `/document/${document.id}`
        if (event.metaKey)
          window.open(url, '_blank')
        else
          window.location.href = url
      }}
    />
  )
}
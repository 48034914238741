import { Editor } from "@tiptap/core"
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo, useRef } from "react"

import fpFlow from 'lodash/fp/flow';
import fpFilter from 'lodash/fp/filter';

import { CommandMenuSection, SectionProps, SectionRef } from "./CommandMenuSection"
import { CommandMenuSectionItem } from "./CommandMenuSectionItem"
import { Command, SLASH_COMMANDS } from "./commands"

type Props = {
  children?: ReactNode | ReactNode[]
} & SectionProps

const AddNewBlockSectionUnforwarded: React.ForwardRefRenderFunction<SectionRef, Props> = ({
  search,
  selectedIndex,
  nestingLevel,
  editor,
}: Props, ref) => {

  const commands: Command[] = useMemo(() => {
    return fpFlow(
      fpFilter((command: Command) => (!search.length || command.type.startsWith(search.toLowerCase()))
        && nestingLevel <= command.maxNestingLevel),
      fpFilter((command: Command) => !command.shouldShow || command.shouldShow({ editor }))
    )(SLASH_COMMANDS);
  }, [search, editor, nestingLevel, editor.state]);


  const selectOptionAtIndex = useCallback((editor: Editor, index: number) => {
    const { [index]: command } = commands;
    command?.onClick?.(editor);
  },
    [commands],
  )

  useImperativeHandle(ref, () => ({
    length: commands.length,
    isLoading: false,
    insertElement: (editor) => {
      selectOptionAtIndex(editor, selectedIndex)
    },
  }), [commands, selectedIndex])

  return (
    <CommandMenuSection title={'Basic Blocks'}>
      {
        commands.map((command, index) => {
          const disabled = typeof nestingLevel === 'number' && nestingLevel > (command.maxNestingLevel ?? Infinity)
          return <CommandMenuSectionItem
            onClick={() => selectOptionAtIndex(editor, index)}
            key={command.name}
            title={command.name}
            description={command.description}
            disabled={disabled}
            index={index}
            selectedIndex={selectedIndex}
          />
        })
      }
      {
        commands.length ? null :
          <li>No Matching Commands</li>
      }
    </CommandMenuSection>
  )
}

export const AddNewBlockSection = forwardRef(AddNewBlockSectionUnforwarded)
import { HStack } from '@chakra-ui/react'
import graphql from 'babel-plugin-relay/macro'
import { useRouter } from 'found'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { DocumentCompareTable } from './DocumentCompareTable'
import { CompareDocumentQuery } from './__generated__/CompareDocumentQuery.graphql'

export const queryQL = graphql`
  query CompareDocumentQuery (
    $documentId: ID!
  ){
    node(id: $documentId) {
      ... on Document{
        id
        ...DocumentCompareTable_document
      }
    }
  }
`

type Props = {}

export function CompareDocumentUnsuspended(props: Props) {
  const router = useRouter()
  const documentId = router.match.params['documentId']
  const { node: document } = useLazyLoadQuery<CompareDocumentQuery>(queryQL, {
    documentId
  })
  if (!document)
    throw new Error("No Document found for id!");


  return (
    <HStack
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <DocumentCompareTable document={document} />
    </HStack>
  )
}


export const CompareDocument = (props: Props) =>
  <SuspenseErrorBoundary>
    <CompareDocumentUnsuspended {...props} />
  </SuspenseErrorBoundary> 
import { ExpandRecursively } from "../bonusGenerics/expand"
import { AttributionMark } from "../documentSchema/marks/attribution"

export type Attributes = {
  id?: string
  originalBlockId?: string
  sourceBlockId?: string
  transcriptTimestamp?: string
}

export type Mark = AttributionMark

export type Paragraph = {
  type: "paragraph",
  content?: [{
    type: "text",
    text: string,
  }] | []
} & ExpandRecursively<Attributes>

export type Title = {
  type: "title",
  content?: [{
    type: "text",
    text: string,
    marks?: Mark[]
  }] | []
} & ExpandRecursively<Attributes>

export type ContentList = {
  type: "contentList",
  content: NestableBlock[]
}

export type NestableBlockContent = [Title] | [Title, ContentList]

export type Section = {
  type: "section",
  attrs?: Attributes,
  content: NestableBlockContent
} & ExpandRecursively<Attributes>

export type Question = {
  type: "question",
  attrs?: Attributes,
  content: NestableBlockContent
} & ExpandRecursively<Attributes>

export type FreeText = {
  type: "freeText",
  attrs?: Attributes,
  content: NestableBlockContent
} & ExpandRecursively<Attributes>


export type NestableBlock = FreeText | Section | Question
export type Block = NestableBlock | ContentList
export type BlockType = Block['type']
export type TipTapDocument = {
  content: NestableBlock[] | undefined
}


export const isNestableType = (type: Block['type'] | 'doc') => {
  switch (type) {
    case 'question':
    case 'section':
    case 'freeText':
      return true
    // case 'paragraph':
    case 'doc':
    case 'contentList':
      return false
    default:
      throw new Error(`Type ${type} not degined as nestable or not yet`);
  }
}


export const makeParagraphBlock = (text: string): Paragraph => {
  return {
    type: "paragraph",
    content: !text?.length ? [] : [{
      type: "text",
      text: text,
    }]
  }
}
export const makeTitleBlock = (text: string, marks: Mark[] = []): Title => {
  return {
    type: "title",
    content: !text?.length ? [] : [{
      type: "text",
      text: text,
      marks: marks,
    }]
  }
}

export const makeContentList = (children: NestableBlock[]): ContentList => ({
  type: 'contentList',
  content: children,
})
export const makeContentListWithAtLeastOneChild = (children: NestableBlock[]): ContentList => ({
  type: 'contentList',
  content: children,
})

export const makeFreeTextBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = [],
): FreeText => {
  const contentList = children?.length ? makeContentList(children) : undefined
  const titleBlock = makeTitleBlock(text, marks)
  const content: NestableBlockContent = contentList ? [titleBlock, contentList] : [titleBlock]
  return {
    type: "freeText",
    attrs: attributes,
    content,
  }
}

export const makeSectionBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = [],
): Section => {
  const contentList = children?.length ? makeContentList(children) : undefined
  const content: NestableBlockContent = contentList ? [makeTitleBlock(text, marks), contentList] : [makeTitleBlock(text, marks)]
  return {
    type: "section",
    attrs: attributes,
    content,
  }
}

export const makeQuestionBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = [],
): Question => {
  const contentList = children?.length ? makeContentList(children) : undefined
  const content: NestableBlockContent = contentList ? [makeTitleBlock(text, marks), contentList] : [makeTitleBlock(text, marks)]
  return {
    type: "question",
    attrs: attributes,
    content,
  }
}

import { border } from "@chakra-ui/react";
import { css, SerializedStyles } from "@emotion/react"
import { CSSProperties } from "react";
import { FaMicrophoneAlt } from 'react-icons/fa'

export const IconColors = {
  purple: '#907bff',
  magenta: '#ff5fcb',
  turquoise: '#7ae4ca',
  gray: '#999',
} as const

const padding = '15%' as const
const radius = '50%' as const

const focusBracketsCss = (
  color: string,
  width: number
) => css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1;

  &:before,
  &:after,
  &>:first-of-type:before,
  &>:first-of-type:after {
    position: absolute;
    aspect-ratio: 1;
    width: 15%;
    border-color: ${color};
    border-style: solid;
    content: ' ';
    border-width: ${width}px;
  }

  &:before {
    top: ${padding};
    left: ${padding};
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: ${radius}
  }

  &:after {
    top: ${padding};
    right: ${padding};
    border-left: 0;
    border-bottom: 0;
    border-top-right-radius: ${radius}
  }

  &>:first-of-type:before {
    bottom: ${padding};
    right: ${padding};
    border-left: 0;
    border-top: 0;
    border-bottom-right-radius: ${radius}
  }

  &>:first-of-type:after {
    bottom: ${padding};
    left: ${padding};
    border-right: 0;
    border-top: 0;
    border-bottom-left-radius: ${radius}
  }
`



type Color = keyof typeof IconColors

const styleForProps = (
  color: Color,
  size: number,
  inverted: boolean,
  extraCss?: SerializedStyles,
) => {
  const borderWidth = size > 15 ? '2px' : '1px'

  return css`
      aspect-ratio: 1;
      background-color: ${!inverted ? IconColors[color] : 'transparent'};
      border: ${borderWidth} solid ${inverted ? IconColors[color] : 'transparent'};
      border-radius: 25%;
      padding: ${size / 2.25}px;
      display: inline-flex;
      position: relative;
    `;

}

type Props = {
  color: Color
  size?: number
  className?: string
  hasFocusBrackets?: boolean
  inverted?: boolean
}

const FocusBrackets = ({
  color,
  width,
}: {
  color: string
  width: number
}) =>
  <div css={focusBracketsCss(color, width)} >
    <div />
    <div />
  </div>

export const ColoredIconHolder = ({
  color,
  size = 11,
  className,
  hasFocusBrackets = false,
  inverted = false,
}: Props) => {

  const lineColor = inverted ? IconColors[color] : 'white'
  const bracketWidth = size >= 15 ? 2 : 1.5
  return (
    <div
      css={styleForProps(color, size, inverted)}
      className={className}
    >
      {
        hasFocusBrackets &&
        <FocusBrackets
          width={bracketWidth}
          color={lineColor}
        />
      }
      <FaMicrophoneAlt
        color={lineColor}
        size={size}
      />
    </div>
  )
}
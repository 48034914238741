import { Heading, HStack, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { GuideList } from './guideList/GuideList'
import { InterviewList } from './interviewList/InterviewList'
import { DocumentsQuery } from './__generated__/DocumentsQuery.graphql'

export const queryQL = graphql`
  query DocumentsQuery {
    ...GuideConnection_query
    # ...InterviewConnection_query
  }
`

type Props = {}

export function DocumentsUnsuspended(props: Props) {
  const queryData = useLazyLoadQuery<DocumentsQuery>(queryQL, {})
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        max-width: 800px;
        margin: auto;
      `}
    >
      <GuideList queryFragment={queryData} />
      {/* <InterviewList queryFragment={queryData} /> */}
    </div>
  )
}


export const Documents = (props: Props) =>
  <SuspenseErrorBoundary>
    <DocumentsUnsuspended {...props} />
  </SuspenseErrorBoundary> 
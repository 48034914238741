import { Alert, AlertIcon, Button, Container, Heading, Input, Link, Progress, Stack, Textarea } from "@chakra-ui/react"
import { useState } from "react"

export const ProjectEditor = () => {

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [projectDescription, setProjectDescription] = useState("")
  const [projectTitle, setProjectTitle] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [didSaveSuccessful, setDidSaveSuccessful] = useState(false)
  const [newDiscussionGuideDocumentId, setNewDiscussionGuideDocumentId] = useState("fakeid")

  const saveAndContinue = () => {

    //TODO: Save this
    setIsSaving(true)

    // Demo For now
    setTimeout(() => {
      setIsSaving(false)
      setDidSaveSuccessful(true)
    }, 5000)

    setCurrentTabIndex(1)
  }


  return <div>


    {currentTabIndex === 0 &&
      <Container>
        <Heading>Projects</Heading>
        <Stack>
          <p>Projects are based around making progress towards answering a specific question.</p>

          <strong>What is the most important question you want answered?</strong>
          <Textarea
            value={projectDescription}
            onChange={(evt) => setProjectDescription(evt.target.value)}
            placeholder='start typing here ...'
            size='lg'
            height={20}
          />

          <strong>Give this project a title</strong>
          <Input
            value={projectTitle}
            onChange={(evt) => setProjectTitle(evt.target.value)}
            placeholder='start typing here ...'
            size='lg' />

          <Button onClick={saveAndContinue}
            colorScheme="blue"
            variant="solid">Continue</Button>
        </Stack>
      </Container>
    }


    {currentTabIndex === 1 &&
      <Container>
        <Heading>Projects</Heading>
        <Stack spacing={5}>
          <Heading as="h2" size="lg">Your question has been saved!</Heading>

          <Heading as="h3" size="md">What else should you know about projects?</Heading>
          <p>
            Projects will help you organize your research.
            They include an interview guide as well as notes
            taken for each user interview you conduct.
          </p>
          <p>I just created a project for you.
            Now I will generate a starting point for a discussion guide.</p>

          {isSaving &&
            <Stack>
              <Alert status='info'>
                <AlertIcon />
                <p>
                  Generating a draft discussion guide
                </p>
              </Alert>
              <Progress size="sm" isIndeterminate />

            </Stack>
          }


          {didSaveSuccessful &&
            <Stack>
              <Alert status="success">
                <AlertIcon></AlertIcon>
                Discussion guide successfully generated!
              </Alert>
              <Progress size="sm" isAnimated value={100}
                colorScheme="green" />
              <Stack direction="row" alignItems={"right"}>
                <Link href={`/documents/${newDiscussionGuideDocumentId}`}>
                  <Button onClick={() => console.log("This button did NOTHING!")}
                    colorScheme="blue"
                    variant="solid">Review the Discussion Guide</Button>
                </Link>
              </Stack>
            </Stack>
          }

        </Stack>
      </Container>
    }


  </div>
}
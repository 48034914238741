import { Heading } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { useUrlParams } from '../../../utility-hooks/useUrlParams'
import { documentListCss, headingStyle } from '../documentListStyles'
import { NewInitiative } from '../NewInitiative'
import { GuideConnection } from './GuideConnection'
import { NewDocumentButton } from './NewDocumentButton'
import { GuideConnection_query$key } from './__generated__/GuideConnection_query.graphql'


type Props = {
  queryFragment: GuideConnection_query$key
}

export function GuideList(props: Props) {

  const { ai: withAI } = useUrlParams()

  return (
    <div css={documentListCss}>
      <div
        css={css`
          ${headingStyle}
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Heading css={css`
          color: #999;
          padding-left: 40px;
        `}>
          Interviews
        </Heading>
        {
          !withAI ? null :
            <NewInitiative />
        }
      </div>
      <GuideConnection query={props.queryFragment} />
    </div>
  )
}

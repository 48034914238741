import { Alert, AlertIcon, Button, Container, Link, Progress, Stack, Tab, TabList, Tabs } from "@chakra-ui/react"
import { Textarea } from '@chakra-ui/react'
import { useEffect, useState } from "react"
import { useNewDocumentMutation } from "../documents/guideList/NewDocumentButton"
import { useCreateOpportunity } from "./useCreateOpportunity"

export const OpportunityIntakeForm = () => {

  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [descriptionText, setDescriptionText] = useState("")
  const [newOpportunityId, setNewOpportunityId] = useState<string>()
  const [didSaveSuccessful, setDidSaveSuccessful] = useState(false)
  const [saveOpportunity, isSavingOpportunity] = useCreateOpportunity((response) => setNewOpportunityId(response.id))
  const [saveDocument, isDocumentSaving] = useNewDocumentMutation(
    false,
    () => setDidSaveSuccessful(true)
  )
  const isSaving = isSavingOpportunity || isDocumentSaving
  const saveAndContinue = async () => {
    await saveOpportunity({
      description: descriptionText
    })

    setCurrentStepIndex(1)
  }

  useEffect(() => {
    if (newOpportunityId) {
      saveDocument({
        type: 'pressRelease',
        opportunityId: newOpportunityId,
        createFromAIPrompt: descriptionText,
      })
    }
  }, [newOpportunityId])


  return <div>
    {currentStepIndex === 0 &&
      <div>
        <Tabs>
          <TabList opacity="0.1">
            <Tab isDisabled>Description</Tab>
            <Tab isDisabled>Mock Pull Request</Tab>
            <Tab isDisabled>Projects</Tab>
          </TabList>
        </Tabs>
        <Container border="1px solid #DDD" borderRadius="10px">
          <Stack spacing={4} fontSize="xl">
            <p>
              Welcome to Verdi. Let’s get you set up by giving you
              a jump start on some product discovery automation magic!
            </p>

            <p>
              Please tell me about an opportunity you are exploring
              (i.e., a customer problem or a business outcome).
              The more context the better, but don’t take more than a few minutes on it.
            </p>

            <Textarea
              value={descriptionText}
              onChange={(evt) => setDescriptionText(evt.target.value)}
              placeholder='start typing here ...'
              size='lg'
              height={200}
            />
            <Stack direction="row" alignItems={"right"}>
              {/* <Button onClick={() => console.log("save")}
                colorScheme="blue"
                variant="outline">Save</Button> */}
              <Button onClick={saveAndContinue}
                colorScheme="blue"
                variant="solid"
              >
                Save and Continue
              </Button>
            </Stack>
          </Stack>
        </Container>
      </div>
    }

    {currentStepIndex === 1 &&
      <div>
        <Tabs>
          <TabList>
            <Tab isDisabled>Description</Tab>
            <Tab isDisabled>Mock Press Release</Tab>
            <Tab isDisabled>Projects</Tab>
          </TabList>

        </Tabs>
        <Container border="1px solid #DDD" borderRadius="10px">
          <Stack spacing={4} fontSize="lg">
            <p>
              Great. Verdi uses a collection of documents
              to help guide the discovery flow.
              These include:
            </p>

            <div>
              <ul>
                <li>
                  <strong>Mock Press Release (PR)</strong> - To help visualize the desired outcome.
                </li>
                {/* <li>
                  <strong>Frequently Asked Questions (FAQ)</strong> - Details on intended behavior.
                </li> */}
                {/* <li>
                  <strong>Product Requirements Doc (PDR)</strong> - Things that need dev attention.
                </li> */}
                <li>
                  <strong>Project and Discussion Guide</strong> - Jump-start your user interview flow.
                </li>
              </ul>
            </div>

            {isSaving &&
              <Stack>
                <Alert status='info'>
                  <AlertIcon />
                  <p>
                    Reviewing your opportunity description
                  </p>
                </Alert>
                <Progress size="sm" isIndeterminate />

              </Stack>
            }


            {didSaveSuccessful &&
              <Stack>
                <Alert status="success">
                  <AlertIcon></AlertIcon>
                  Opportunity successfully reviewed!
                </Alert>
                <Progress size="sm" isAnimated value={100}
                  colorScheme="green" />
                <Stack direction="row" alignItems={"right"}>
                  <Button onClick={() => setCurrentStepIndex(0)}
                    colorScheme="blue"
                    variant="ghost">Go back</Button>

                  <Button onClick={() => setCurrentStepIndex(2)}
                    colorScheme="blue"
                    variant="solid">Continue</Button>
                </Stack>
              </Stack>
            }

          </Stack>
        </Container>
      </div>
    }

    {currentStepIndex === 2 &&
      <div>
        <Tabs>
          <TabList>
            <Tab isDisabled>Description</Tab>
            <Link href={`/opportunities/${newOpportunityId}?tab=pr`}>
              <Tab className="callToAction">Mock Press Release</Tab>
            </Link>
            <Tab isDisabled>Projects</Tab>
          </TabList>

        </Tabs>
        <Container border="1px solid #DDD" borderRadius="10px">
          <Stack spacing={4} fontSize="xl">
            <p>
              Let's start with the <strong>Mock Press Release </strong>
              as the foundation for the other docs.
            </p>
            <Alert status='info'>
              <AlertIcon />
              Press the "Mock Press Release" Tab near the top of the page to continue.
            </Alert>
            <Stack direction="row" alignItems={"right"}>
              <Button onClick={() => setCurrentStepIndex(1)}
                colorScheme="blue"
                variant="ghost">Go back</Button>

              <Link href={`/opportunities/${newOpportunityId}?tab=pr`}>
                <Button onClick={saveAndContinue}
                  colorScheme="blue"
                  variant="solid">Continue</Button>
              </Link>
            </Stack>
          </Stack>
        </Container>
      </div>
    }

  </div>
}
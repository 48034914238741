import History from '@tiptap/extension-history'
import { ConfiguredPlaceholder } from './placeholder/placeholder'
import { PressTab } from './events/PressTab'
import { NestingLimit } from './NestingLimit'
import { PressEnter } from './events/PressEnter'
import { PressBackspace } from './events/PressBackspace'
import Focus from '@tiptap/extension-focus'
import Convert from './Convert';
import './extensionsCss'
import { DocumentSchema } from 'shared-constants'
import { clearEditMarksExtension } from './clearAIEditMarksPlugin'

const congiguredFocus = Focus.configure({
  className: 'focus',
  mode: 'deepest',
})

export const extensions = () => [
  // behavioral
  PressTab,
  PressEnter,
  PressBackspace,
  ConfiguredPlaceholder,
  History,
  congiguredFocus,
  Convert,
  NestingLimit,
  ...DocumentSchema.schemaExtensions,
  clearEditMarksExtension,
]
import { PromptFunction } from "./prompt"


export const pressReleaseAiPrompt: PromptFunction = () => {
  return {
    documentDescription: 'mock press release',
    sharedOpenAIParams: { max_tokens: 200 },
    steps: [
      {
        prompt: `Draft a headline for a mock future press release for the following opportunity.`,
        context: `\
desired format: short, punchy headline that reads like a news story headline
scope: leverage whatever information is most likely to grab attention in a press release
`
      },
      {
        prompt: `Draft the first paragraph in the mock future press release.`,
        context: `\
desired format: no more than 3-5 short, punchy sentences
scope: draft a paragraph that gives the most important details about the product (or service), how consumers will benefit from it, and the problem that it’s going to solve. In the first sentence, state your company’s city and state, followed by the date. The paragraph should answer the questions, “who,” “what,” “when,” “where,” and “why”.
`
      },
      {
        prompt: `Draft a paragraph that includes relevant quotes about the product (or service) and the benefits it gives to the customer.`,
        context: `\
desired format: no more than 3-5 short, punchy sentences
scope: include hypothetical quotes from the company spokesperson and satisfied customer(s)
`
      },
      {
        prompt: `Draft a paragraph that highlights what made this solution possible and why this solution will win in the market.`,
        context: `\
desired format: no more than 3-5 short, punchy sentences
scope: outline the inputs that led to success - the hard things accomplished, the scarce resources acquired, the design principles, and the important decisions and tradeoffs made.
`
      },
      {
        prompt: `Draft a paragraph that sets the vision and goals for this solution in the future.`,
        context: `\
desired format: no more than 3-5 short, punchy sentences
scope: set a clear and audacious goal for the future with measurable results (e.g., financial, operating, and / or market share)
`
      },
      {
        prompt: `Draft a call to action statement and explain how the user can learn more.`,
        context: `\
desired format: no more than 3-5 short, punchy sentences
scope: briefly explain how the customer can learn more or begin using the product (or service)
`
      },
    ],
  }
}
import { getSchema } from '@tiptap/core'
import Text from '@tiptap/extension-text'
import { attribution } from './marks/attribution'
import * as NodeExtensions from './nodeExtensions'

export * as NodeExtensions from './nodeExtensions'
export { initialDoc, createNewDoc } from './initialDoc'

const { Section, Question, FreeText, Title, ConfiguredDocument, ContentList } = NodeExtensions

export const schemaExtensions = [
  // required
  Text,
  // nodes
  ConfiguredDocument,
  ContentList,
  Title,
  FreeText,
  Section,
  Question,
  // marks
  attribution,
]

export const schema = getSchema(schemaExtensions)
import { ReadyState } from "react-use-websocket"
import { css } from "@emotion/react"


type Props = {
  documentId: string
  readyState: ReadyState
  subscriberIds: string[]
}

export function Collaborators({ documentId, readyState, subscriberIds }: Props) {

  if (readyState !== ReadyState.OPEN) {
    return <h1>{readyState}</h1>
  }

  return (
    <div css={css`
      padding: 10px;
      font-size: 6px;
    `}>
      <ul>
        {
          subscriberIds.map((id, index) =>
            <li key={id + index}>{id}</li>
          )
        }
      </ul>
    </div>
  )
}
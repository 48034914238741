import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { CompareTableHeader } from './CompareTableHeader';
import { CompareTableRow } from './CompareTableRow';
import { DocumentCompareTable_document$key } from './__generated__/DocumentCompareTable_document.graphql';

const fragmentQL = graphql`
  fragment DocumentCompareTable_document on Document {
    ...CompareTableHeader_document
    comparison {
      blockComparison {
      ...CompareTableRow_blockComparison
        mainBlock {
          id
        }
      }
    }
  }
`

type Props = { document: DocumentCompareTable_document$key; }

export function DocumentCompareTable({ document: documentKey }: Props) {

  const document = useFragment(fragmentQL, documentKey)

  return (
    <table>
      <CompareTableHeader document={document} />
      <tbody>
        {
          document.comparison.blockComparison.map(blockComparison =>
            <CompareTableRow
              key={blockComparison.mainBlock.id}
              blockComparison={blockComparison}
            />
          )
        }
      </tbody>
    </table>
  )
}
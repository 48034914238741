/**
 * @generated SignedSource<<909ed92c690212a34dc3e0ac7bdaccb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuideListItem_document$data = {
  readonly description: string | null;
  readonly id: string;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"GuideInterviewList_document">;
  readonly " $fragmentType": "GuideListItem_document";
};
export type GuideListItem_document$key = {
  readonly " $data"?: GuideListItem_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuideListItem_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuideListItem_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuideInterviewList_document"
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "3e25e247ad5f48ce0499d8eced7f599d";

export default node;

import { PromptFunction } from "./prompt"


export const opportunityAiPrompt: PromptFunction = () => {
  return {
    documentDescription: 'opportunity brief',
    sharedOpenAIParams: { max_tokens: 200 },
    steps: [
      {
        prompt: `Describe the ideal customers for the opportunity below. Start with the section title "Ideal Customers:”`,
        context: `\
desired format: short, punchy description, without preamble (e.g., don't include "The ideal customers for this opportunity are...")
scope: in addition to basic demographics, focus on the ideal customers' context, and other factors that cause people to be customers
`
      },
      {
        prompt: `Describe the shape of the problem the ideal customers are facing. Start with the section title "Shape of the Problem:"`,
        dontDo: `Shape of the Problem`,
        context: `\
desired format: short, punchy description, without preamble (e.g., don't include "The problem ideal customers are facing is...")
scope: Focus on how often our ideal customers will experience it, when they experience it, how they might feel about it, how severe the pain is (e.g., deep pain vs paper cut), etc.
`
      },
      {
        prompt: `Describe how our ideal customers are dealing with this particular problem today. Start with the section title "Current Solutions:”`,
        dontDo: `Current Solutions`,
        context: `\
desired format: short, punchy description, without preamble (e.g., don't include "The current solutions our ideal customers employ are...")
scope: Include any applicable direct competitors (competitors related to this specific opportunity), as well as substitutes (indirect competitors), workarounds (ways to avoid the problem), and mitigating the pain from experiencing the problem. 
`
      },
      {
        prompt: `List potential ideas for how we might solve this problem for our customers. Start with the section title: “Potential Solutions:” `,
        dontDo: `Potential Solutions`,
        context: `\
desired format: list of ideas, with short, punchy, one-line descriptions for each, without preamble (e.g., don't include "The potential solutions include...")
scope: Consider the full range of possible solutions we may consider, potentially including products, processes, training, or even no solution
`
      },
      {
        prompt: `List the potential stakeholders we should consider collaborating with in exploring and eventually solving this problem. Start with the section title "Stakeholders:"`,
        dontDo: `Stakeholders`,
        context: `\
desired format: list of just the names of people or functions, without explanation
scope: Focus on internal stakeholders, or in rare situations include partners or other external stakeholders. Do not include people on the core product team (e.g., do not include product managers, engineers, or UX designers)
`
      },
      {
        prompt: `Outline the types of research that we may already have related to this problem. Start with the section title "Existing Research: [" and pose the rest of the response as a question or prompt to the user, and end by enclosing in square brackets “]”.`,
        dontDo: `Existing Research`,
        context: `\
desired format: list of just the names of people or functions, without explanation
scope: Focus on internal stakeholders, or in rare situations include partners or other external stakeholders. Do not include people on the core product team (e.g., do not include product managers, engineers, or UX designers)
`
      },
    ],
  }
}
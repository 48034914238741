import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { OpportunityDocumentSideMenu_opportunity$key } from './__generated__/OpportunityDocumentSideMenu_opportunity.graphql';
import { css } from "@emotion/react"
import { OpportunityDocumentSideMenuItem } from './OpportunityDocumentSideMenuItem';
import { DocumentType } from '../document/__generated__/DocumentSettingsEditor_document.graphql';
import { useRouter } from 'found';
import { useNewDocumentMutation } from '../documents/guideList/NewDocumentButton';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, HStack, Spinner, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AddIcon } from '@chakra-ui/icons';

const fragmentQL = graphql`
  fragment OpportunityDocumentSideMenu_opportunity on Opportunity {
     id
     title
     description
     opportunities: documents(type: opportunity) {
        id
     }
     pressReleases: documents(type: pressRelease) {
        id
     }
     faqs: documents(type: faq) {
        id
     }
     prds: documents(type: prd) {
        id
     }
     interviewGuides: documents(type: guide) {
      id
      title
      copies {
        id
        title
      }
     }
  }
`

type Props = {
  opportunity: OpportunityDocumentSideMenu_opportunity$key;
  selectedDocId: string
  shouldExpandInterviewGuide: boolean
}

const nestedMenuIndent = 4

export function OpportunityDocumentSideMenu({
  opportunity: opportunityKey,
  selectedDocId,
  shouldExpandInterviewGuide
}: Props) {

  const opportunity = useFragment(fragmentQL, opportunityKey)
  const router = useRouter()

  const goToDocument = (documentId: string) => {
    // window.location.href = `/opportunity/${opportunity.id}/${documentId}?collab=true`
    router.router.push(`/opportunity/${opportunity.id}/${documentId}?collab=true`)
  }

  const [createNewDoc, isLoading] = useNewDocumentMutation(false, response => {
    if (response?.id) {
      goToDocument(response.id)
    }
  })

  const documentLinkOnClick = (
    documentType: DocumentType,
    documentId?: string,
    createFromDocId?: string
  ) => () => {
    if (!documentId) {
      createNewDoc({ // TODO: Steal this
        opportunityId: opportunity.id,
        type: documentType,
        createFromDocId
      })
      return
    }
    goToDocument(documentId)
  }

  useEffect(() => {
    if (!selectedDocId?.length) {
      console.log('here for it?')
      documentLinkOnClick('opportunity', opportunity.opportunities?.[0]?.id)()
    }
  }, [])

  return (
    <Stack>
      {
        isLoading &&
        <Spinner css={css`
        position: absolute;
        top: 73px;
      `} />
      }
      <OpportunityDocumentSideMenuItem
        text={'Opportunity'}
        onClick={
          documentLinkOnClick('opportunity', opportunity.opportunities?.[0]?.id)
        }
        isSelected={opportunity.opportunities?.[0]?.id === selectedDocId}
      />
      <Stack ml={nestedMenuIndent} mt={1}>
        <OpportunityDocumentSideMenuItem
          text={'Mock Press Release'}
          onClick={
            documentLinkOnClick('pressRelease', opportunity.pressReleases?.[0]?.id)
          }
          isSelected={opportunity.pressReleases?.[0]?.id === selectedDocId}
        />
        <OpportunityDocumentSideMenuItem
          text={'FAQ'}
          onClick={
            documentLinkOnClick('faq', opportunity.faqs?.[0]?.id)
          }
          isSelected={opportunity.faqs?.[0]?.id === selectedDocId}
        />
        {/* <OpportunityDocumentSideMenuItem
          text={'PRD'}
          onClick={
            documentLinkOnClick('prd', opportunity.prds?.[0]?.id)
          }
          isSelected={opportunity.prds?.[0]?.id === selectedDocId}
        /> */}

        <Accordion allowMultiple defaultIndex={
          shouldExpandInterviewGuide
            || opportunity.interviewGuides?.[0]?.id === selectedDocId
            || opportunity.interviewGuides?.[0]?.copies?.find(c => c.id === selectedDocId)
            ? [0] : undefined}>
          <AccordionItem marginLeft={-4} marginRight={-4} border='none'>
            <h2>
              <HStack ml={4}>
                <OpportunityDocumentSideMenuItem
                  text={'Interviews'}
                  onClick={
                    documentLinkOnClick('guide', opportunity.interviewGuides?.[0]?.id)
                  }
                  isSelected={opportunity.interviewGuides?.[0]?.id === selectedDocId}
                />
                <AccordionButton width={14} >
                  <Box flex='1'>
                    <AccordionIcon opacity={0.3} />
                  </Box>
                </AccordionButton>
              </HStack>
            </h2>
            <AccordionPanel pb={4}>

              <Flex ml={3} flexDirection='column' alignItems='baseline'>
                {opportunity.interviewGuides?.map((guide, index) =>
                (<Flex ml={3} flexDirection='column' alignItems='baseline' key={guide.id} width='100%'>

                  {index !== 0 &&
                    <OpportunityDocumentSideMenuItem
                      text={guide.title || "Discussion Guide"}
                      onClick={
                        documentLinkOnClick('guide', guide.id)
                      }
                      isSelected={guide.id === selectedDocId}
                    />
                  }

                  {guide?.copies?.map((interviewNote) =>
                  (<Button key={interviewNote.id}
                    variant={interviewNote.id === selectedDocId ? 'solid' : 'ghost'}
                    onClick={documentLinkOnClick('interview', interviewNote.id)}
                    width='100%'
                    justifyContent='normal'
                  >
                    {interviewNote.title || "Interview Note"}
                  </Button>))}

                  <Button onClick={documentLinkOnClick('interview', undefined, guide.id)}
                    size='sm'
                    variant='ghost'
                    color='GrayText'>
                    <AddIcon boxSize={2} mr={1} />
                    Add Interview
                  </Button>

                </Flex>))}
              </Flex>
            </AccordionPanel>
          </AccordionItem>


        </Accordion>
      </Stack>
    </Stack>
  )
}



import { useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks'
import { logoutUrl } from '../../auth/network';

const styles = {
  container: {
    display: 'flex',
    alignSelf: 'center',
    margin: 'auto',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 500,
  },
} as const

const fetchConfig = {
  credentials: 'include'
} as const

const Logout = () => {

  const [error, setError] = useState<string>()
  const environment = useRelayEnvironment()

  useEffect(() => {
    (async () => {
      const response = await fetch(logoutUrl(), fetchConfig)
      if (response.status === 200) {
        window.location.href = './login'
        console.log('logged out')
      } else {
        const error = await response.text()
        setError(error)
      }
    })()
  })

  return (
    <div style={styles.container}>
      <h3>Logging out...</h3>
      {
        error &&
        <p>{error}</p>
      }
    </div>
  )
}

export default Logout
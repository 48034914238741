import { ClientSideOpenAIParams } from "../../../../../components/documentSubscriptions/useDocumentSubscription"
import { defaultParams } from "./defaultParams"

type Step = {
  prompt: string
  openAIParams?: ClientSideOpenAIParams
  context?: string
  initialText?: string
  dontDo?: string
}

export type CompanyContext = {
  companyUrl?: string
  companyName?: string
  currentProducts?: string
  currentUsers?: string
  currentBuyers?: string
}

export type OpportunityContext = {
  opportunityTitle: string
  description: string
}

export type SharedContext = {
  opportunity: OpportunityContext,
  company: CompanyContext,
}


export type Prompt = {
  documentDescription: string,
  steps: Step[],
  sharedOpenAIParams?: ClientSideOpenAIParams
}


export type PromptParams = {
  existingDocumentText: string,
  paragraphNumber: number,
  context: SharedContext,
}

export type PromptFunction = (params: PromptParams) => Prompt

const unCamelCase = (text: string) =>
  text// insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .toLocaleLowerCase()

const keyValuePairToPromptString = (key: string, value: string) =>
  `${unCamelCase(key)}: ${value}`

export const defaultPrompt = (params: PromptParams) => {
  const prompt = `
    Add a paragraph to the end of the following document:
    ${params.existingDocumentText}
  `
  return { prompt, tokenLimit: 300 }
}

export const objectToContext = (contextObject: Record<string, string>) => {
  const validKeys = Object.keys(contextObject).filter(key => !!contextObject[key as any])
  if (!validKeys?.length) return ''
  return validKeys.map(key =>
    keyValuePairToPromptString(key, contextObject[key as any].trim())
  ).join(
    '\n'
  ) + '\n'
}

const generateSubsequentStepsString = (subsequentSteps: Step[]) => {
  const subsequentStepsToAvoid = subsequentSteps
    .map(step => step.dontDo?.trim())
    .filter(dontDo => !!dontDo)
  if (!subsequentStepsToAvoid.length) return ''
  return `\
future sections: avoid duplicating content that will be covered in the following future sections: 
${subsequentStepsToAvoid.join(',')}
`
}

export const generatePrompt = (params: PromptParams, promptFunction: PromptFunction) => {
  const { steps, documentDescription, sharedOpenAIParams } = promptFunction(params)
  const { paragraphNumber, existingDocumentText, context } = params
  const paragraphIndex = paragraphNumber - 1
  const currentStep = steps[paragraphIndex]
  const subsequentSteps = steps.slice(paragraphIndex, -1)
  if (!currentStep) return false
  const prompt = `\
${currentStep.prompt} 

###
${objectToContext(context.company)}\
${objectToContext(context.opportunity)}\
current document: ${documentDescription}
${generateSubsequentStepsString(subsequentSteps)}\
${currentStep.context ? currentStep.context.trim() + '\n' : ''}\
###

${existingDocumentText} \
${currentStep.initialText ? currentStep.initialText + '\n' : ''}\
`
  const openAIParams = {
    ...defaultParams,
    ...sharedOpenAIParams,
    ...currentStep.openAIParams
  }
  return { prompt, openAIParams, initialText: currentStep.initialText }
}
import { useEffect, useRef, useState } from "react"


const authenticateWithZoom = () => {

  const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID
  const redirectUrl = encodeURIComponent(process.env.REACT_APP_ZOOM_REDIRECT_URL as string)

  console.info("redirectUrl = ", redirectUrl)

  window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&code_challenge_method=plain`
}

const generateZoomAccessToken = (zoomCode: string): Promise<any> => {
  const toPost = {
    zoomCode
  }
  console.log("zoomCode = ", zoomCode)

  return fetch(`${process.env.REACT_APP_API_DOMAIN}/zoom/connect`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(toPost),
    credentials: "include"
  });

}

type ZoomUser = {
  verdiUserId: Buffer
  zoomAccountId: string
  zoomUserId: string
  firstName: string
  lastName: string
  emailAddress: string
  personalMeetingUrl: string
  timezone: string
  accessToken: string
  refreshToken: string
}

export const ZoomConnectAccount = () => {

  const urlParams = new URLSearchParams(window.location.search);
  var code = urlParams.get("code")

  const hasAlreadyRanOnce = useRef(false)
  const [zoomUser, setZoomUser] = useState<ZoomUser>()

  useEffect(() => {
    if (code && !hasAlreadyRanOnce.current) {
      generateZoomAccessToken(code)
        .then((res) => res.json())
        .then(body => {
          setZoomUser(body)
          console.log("Request complete! response:", zoomUser);
        }).catch((err) => {
          console.error(err)
        });
      hasAlreadyRanOnce.current = true
    }
  }, [code, zoomUser])

  if (!code) {
    authenticateWithZoom()
    return null
  }

  return (
    <div>
      <h1>Connected Zoom Account</h1>
      {zoomUser &&
        <div>
          <h2>This account is currently connected</h2>
          <p>
            {zoomUser.firstName} {zoomUser.lastName} <br />
            {zoomUser.emailAddress}
          </p>
          <p>
            <a href="/zoom/meetings">View meetings</a>
          </p>
        </div>
      }
      {!zoomUser &&
        <div>
          <p>
            Detecting zoom user ...
          </p>
        </div>
      }

    </div>
  )
}



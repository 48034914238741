import Placeholder from '@tiptap/extension-placeholder'
import './placeholder.css'

export const ConfiguredPlaceholder = Placeholder.configure({
  showOnlyCurrent: false,
  includeChildren: true,
  placeholder: (args) => {
    const { editor, pos } = args
    const resolvedPosition = editor.state.doc.resolve(pos)
    const isFirstChild = resolvedPosition.index() === 0
    if (isFirstChild) {
      const parent = resolvedPosition.parent
      switch (parent.type.name) {
        case `section`:
          return `Enter a section name`
        case `question`:
          return `Enter a question`
        case `freeText`:
          return `Type '/' for commands`
        default:
          break;
      }
    }
    return 'Enter Text'
  },
})
/**
 * @generated SignedSource<<c209d3ab511a28ff85a757577128f617>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuideInterviewListItem_document$data = {
  readonly createdAt: any;
  readonly id: string;
  readonly title: string | null;
  readonly " $fragmentType": "GuideInterviewListItem_document";
};
export type GuideInterviewListItem_document$key = {
  readonly " $data"?: GuideInterviewListItem_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuideInterviewListItem_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuideInterviewListItem_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "fe6f8847fb14ca2c9df90780c75ec849";

export default node;

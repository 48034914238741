import { css } from '@emotion/react'
import graphql from 'babel-plugin-relay/macro'
import { useRouter } from 'found'
import { useLazyLoadQuery } from 'react-relay/hooks'
import LoadingSpinner from '../../components/LoadingSpinner'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { DocumentEditor } from '../document/DocumentEditor'
import { SideMenu } from './SideMenu'
import { DynamicPrompt } from './DynamicPrompt'
import { OpportunityDocumentQuery } from './__generated__/OpportunityDocumentQuery.graphql'
import { useColorModeValue } from '@chakra-ui/react'
import { createContext, useState } from 'react'

const queryQL = graphql`
  query OpportunityDocumentQuery(
    $opportunityId: ID!
    $shouldSkip: Boolean!
  ) {
    node(id: $opportunityId) @skip(if: $shouldSkip)  {
      ...on Opportunity{
        ...OpportunityDocumentSideMenu_opportunity
      }
    } 
  }
`

type Props = {
  opportunityId?: string
  documentId?: string
}

export function OpportunityDocument(props: Props) {
  const router = useRouter()
  const opportunityId = props.opportunityId || router.match.params['opportunityId']
  const documentId = props.documentId || router.match.params['documentId']
  const documentBackground = useColorModeValue('#FFF', '#1E1E1E');
  const DocumentContext = createContext({ verticalPosition: 0, type: '' });
  const { node: opportunity } = useLazyLoadQuery<OpportunityDocumentQuery>(queryQL, {
    opportunityId: opportunityId || '',
    shouldSkip: !opportunityId
  })
  const [documentPosition, setDocumentPosition] = useState({ verticalPosition: 0, type: null })


  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        height: 100vh;
      `}
    >
      <SuspenseErrorBoundary>
        <SideMenu
          key={opportunityId}
          selectedDocId={documentId}
          opportunity={opportunity ?? undefined}
        />
      </SuspenseErrorBoundary>
      <div css={css`
            width: 100%;
            text-align: left;
            height: 100vh;
            overflow-y: auto;
            display: flex;
            flex-direction: row;
          `}>
        {

          !opportunity ?
            <div css={css`
            padding-top: 22%;
            text-align: center;
            height: 100vh;
          `}>
              <h3
                css={css`
                width: 100%;
                font-size: 18px;
              `}
              >
                Create your first opportunity by clicking "+ New Opportunity" to the right
              </h3>
            </div>
            :
            (
              <div css={css`
            background: ${documentBackground}; 
            width: 100%;
            `}>
                {!documentId ?
                  <LoadingSpinner />
                  :
                  <div css={css`padding-left: 30px`}>
                    <SuspenseErrorBoundary>
                      <DocumentEditor documentId={documentId} key={documentId} setDocumentPosition={setDocumentPosition} />
                    </SuspenseErrorBoundary>
                  </div>
                }
              </div>
            )

        }
        <SuspenseErrorBoundary>
          <DynamicPrompt
            key={opportunityId}
            selectedDocId={documentId}
            opportunity={opportunity ?? undefined}
            documentPosition={documentPosition}
          />
        </SuspenseErrorBoundary>
      </div>
    </div>
  )
}
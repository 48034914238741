/**
 * @generated SignedSource<<5ff55eebf5d09abfdbf96815a2ebe35e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SideMenuQuery$variables = {};
export type SideMenuQuery$data = {
  readonly opportunityConnection: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly documents: ReadonlyArray<{
          readonly id: string;
          readonly title: string | null;
        }>;
        readonly id: string;
        readonly interviewGuides: ReadonlyArray<{
          readonly copies: ReadonlyArray<{
            readonly id: string;
            readonly title: string | null;
          }> | null;
          readonly id: string;
          readonly title: string | null;
        }>;
        readonly title: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
      };
    }>;
  };
};
export type SideMenuQuery = {
  response: SideMenuQuery$data;
  variables: SideMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "documents",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": "interviewGuides",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "guide"
    }
  ],
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "documents",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "copies",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "documents(type:\"guide\")"
},
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v6 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SideMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryOpportunityConnection",
        "kind": "LinkedField",
        "name": "opportunityConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryOpportunityConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Opportunity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OpportunityDocumentSideMenu_opportunity"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SideMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryOpportunityConnection",
        "kind": "LinkedField",
        "name": "opportunityConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryOpportunityConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Opportunity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": "opportunities",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "opportunity"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": "documents(type:\"opportunity\")"
                  },
                  {
                    "alias": "pressReleases",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "pressRelease"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": "documents(type:\"pressRelease\")"
                  },
                  {
                    "alias": "faqs",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "faq"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": "documents(type:\"faq\")"
                  },
                  {
                    "alias": "prds",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "prd"
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": "documents(type:\"prd\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28438391ccaa675d6e117a1900778644",
    "id": null,
    "metadata": {},
    "name": "SideMenuQuery",
    "operationKind": "query",
    "text": "query SideMenuQuery {\n  opportunityConnection {\n    edges {\n      node {\n        title\n        id\n        documents {\n          id\n          title\n        }\n        interviewGuides: documents(type: guide) {\n          id\n          title\n          copies {\n            id\n            title\n          }\n        }\n        ...OpportunityDocumentSideMenu_opportunity\n      }\n    }\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  title\n  description\n  opportunities: documents(type: opportunity) {\n    id\n  }\n  pressReleases: documents(type: pressRelease) {\n    id\n  }\n  faqs: documents(type: faq) {\n    id\n  }\n  prds: documents(type: prd) {\n    id\n  }\n  interviewGuides: documents(type: guide) {\n    id\n    title\n    copies {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "073dc2c53006ced876cc9c27bf5fd01d";

export default node;

import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { DocumentSettingsEditor_document$key, DocumentSettingsEditor_document$data, DocumentType } from './__generated__/DocumentSettingsEditor_document.graphql';
import { DocumentSettingsEditorMutation, UpdateDocumentInput } from './__generated__/DocumentSettingsEditorMutation.graphql';
import { useCallback, useState, useContext } from 'react';
import { useThrottle } from '../../utility-hooks/useThrottle';
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';
import { ColoredIconHolder } from '../../components/ColoredIconHolder';
import { css } from '@emotion/react';
import { useUpdateOpportunity } from '../opportunities/useUpdateOpportunity';
import { settingsForDocumentTypes } from './tiptapEditor/documentType/settingsForDocumentType';
import { Flex, useColorModeValue } from '@chakra-ui/react'
import { useDeleteDocument } from './useDeleteDocument';
import DeleteDocumentButton from './DeleteDocumentButton';

const fragmentQL = graphql`
  fragment DocumentSettingsEditor_document on Document {
     id
     title
     type
     description
     opportunities{
      id
      title
      description
     }
  }
`

const mutationQL = graphql`
  mutation DocumentSettingsEditorMutation (
    $input: UpdateDocumentInput!
  ) @raw_response_type {
    updateDocument(input: $input){
      id
      title
      description
    }
  }
`

type Props = {
  document: DocumentSettingsEditor_document$key;
  autoFocusTitle: boolean
  setDocumentPosition: (args: any) => void
}

export function DocumentSettingsEditor({
  document: documentKey,
  autoFocusTitle,
  setDocumentPosition
}: Props) {

  const { id: documentId, ...document } = useFragment(fragmentQL, documentKey)
  const documentSettings = settingsForDocumentTypes(document.type)
  const opportunity = document.opportunities?.[0]
  const [saveDocument, isSavingDocument] = useErrorHandledMutation<DocumentSettingsEditorMutation>(mutationQL)
  const [saveOpportunity, isSavingOpporunity] = useUpdateOpportunity()
  const _isSaving = isSavingDocument || isSavingOpporunity

  const [input, updateInput] = useState<{
    title: string,
    description: string,
  }>(
    documentSettings.shouldUseOpportunityTitle ? {
      title: opportunity?.title || '',
      description: opportunity?.description || '',
    } : {
      title: document.title || '',
      description: document.description || '',
    }
  )

  const updateDocumentSettings = useThrottle(() => {
    saveDocument({
      variables: {
        input: {
          ...input,
          documentId,
        }
      }
    })
  })

  const updateOpporunitySettings = useThrottle(() => {
    saveOpportunity({
      opportunityId: opportunity.id,
      ...input,
    })
  })

  const updateInputAndSave = useCallback(async (inputArg: typeof input) => {
    await updateInput(inputArg)
    if (documentSettings.shouldUseOpportunityTitle) {
      updateOpporunitySettings()
    } else {
      updateDocumentSettings()
    }
  }, [updateInput, updateDocumentSettings])

  const settingsBackground = useColorModeValue('#FFF', '#1E1E1E');

  const [deleteDocument, isCurrentlyDeleting] = useDeleteDocument(documentId)


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: 10,
      }}
    >
      <h6 css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
      `}>
        <ColoredIconHolder
          hasFocusBrackets={document.type === 'guide'}
          size={15}
          color={document.type === 'interview' ? 'magenta' : 'gray'}
          css={css`
            margin-right: 7px;
          `}
        />
        {
          documentSettings.typeName
        }
      </h6>
      <Flex justifyContent='space-between'>
        <input
          placeholder="Enter a Title"
          css={css`
          border: 'none';
          border-bottom: 1px solid rgba(128,128,128,0.1);
          height: 50px;
          font-family: Lato;
          font-size: xx-large;
          font-weight: 700;
          outline: none;
          padding: 0;
          background: ${settingsBackground};
          width: 100%;
        `}
          value={input.title || ''}
          autoFocus={autoFocusTitle}
          onChange={(event) => {
            const { offsetTop, offsetHeight } = event.target;
            if (event.target.value.length < 1) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'emptyTitle' }) }
            if (event.target.value.length > 0) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'title' }) }
            updateInputAndSave({ ...input, title: event.target.value })
          }}
          onFocus={(event) => {
            const { offsetTop, offsetHeight } = event.target;
            if (event.target.value.length < 1) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'emptyTitle' }) }
            if (event.target.value.length > 0) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'title' }) }
          }
          }
        />

        {document.type === 'interview' &&
          <DeleteDocumentButton documentId={documentId}
            documentTitle={input.title}
            redirectUrl='/'
          />
        }

      </Flex>
      <input
        placeholder='Enter a Description'
        css={css`
          border: none;
          outline: none;
          margin-bottom: 10px;
          padding: 0;
          font-size: 16px;
          background: ${settingsBackground};
        `}
        value={input.description || ''}
        onChange={(event) => {
          const { offsetTop, offsetHeight } = event.target;
          if (event.target.value.length < 1) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'emptyDescription' }) }
          if (event.target.value.length > 0) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'description' }) }
          updateInputAndSave({ ...input, description: event.target.value })
        }}
        onFocus={(event) => {
          const { offsetTop, offsetHeight } = event.target;
          if (event.target.value.length < 1) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'emptyDescription' }) }
          if (event.target.value.length > 0) { setDocumentPosition({ verticalPosition: offsetTop + offsetHeight, type: 'description' }) }
        }}
      />
    </div>
  )
}
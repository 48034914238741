/**
 * @generated SignedSource<<830a90e85ef847a9f01acfd6fc14f2ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CompareTableRow_blockComparison$data = {
  readonly children: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly children: ReadonlyArray<{
            readonly children: ReadonlyArray<{
              readonly children: ReadonlyArray<{
                readonly children: ReadonlyArray<{
                  readonly children: ReadonlyArray<{
                    readonly comparedDocumentBlocks: ReadonlyArray<{
                      readonly children: ReadonlyArray<{
                        readonly text: string;
                        readonly type: BlockType;
                      }>;
                      readonly id: string;
                      readonly text: string;
                      readonly type: BlockType;
                    } | null>;
                    readonly mainBlock: {
                      readonly id: string;
                      readonly text: string;
                      readonly type: BlockType;
                    };
                  }>;
                  readonly comparedDocumentBlocks: ReadonlyArray<{
                    readonly children: ReadonlyArray<{
                      readonly text: string;
                      readonly type: BlockType;
                    }>;
                    readonly id: string;
                    readonly text: string;
                    readonly type: BlockType;
                  } | null>;
                  readonly mainBlock: {
                    readonly id: string;
                    readonly text: string;
                    readonly type: BlockType;
                  };
                }>;
                readonly comparedDocumentBlocks: ReadonlyArray<{
                  readonly children: ReadonlyArray<{
                    readonly text: string;
                    readonly type: BlockType;
                  }>;
                  readonly id: string;
                  readonly text: string;
                  readonly type: BlockType;
                } | null>;
                readonly mainBlock: {
                  readonly id: string;
                  readonly text: string;
                  readonly type: BlockType;
                };
              }>;
              readonly comparedDocumentBlocks: ReadonlyArray<{
                readonly children: ReadonlyArray<{
                  readonly text: string;
                  readonly type: BlockType;
                }>;
                readonly id: string;
                readonly text: string;
                readonly type: BlockType;
              } | null>;
              readonly mainBlock: {
                readonly id: string;
                readonly text: string;
                readonly type: BlockType;
              };
            }>;
            readonly comparedDocumentBlocks: ReadonlyArray<{
              readonly children: ReadonlyArray<{
                readonly text: string;
                readonly type: BlockType;
              }>;
              readonly id: string;
              readonly text: string;
              readonly type: BlockType;
            } | null>;
            readonly mainBlock: {
              readonly id: string;
              readonly text: string;
              readonly type: BlockType;
            };
          }>;
          readonly comparedDocumentBlocks: ReadonlyArray<{
            readonly children: ReadonlyArray<{
              readonly text: string;
              readonly type: BlockType;
            }>;
            readonly id: string;
            readonly text: string;
            readonly type: BlockType;
          } | null>;
          readonly mainBlock: {
            readonly id: string;
            readonly text: string;
            readonly type: BlockType;
          };
        }>;
        readonly comparedDocumentBlocks: ReadonlyArray<{
          readonly children: ReadonlyArray<{
            readonly text: string;
            readonly type: BlockType;
          }>;
          readonly id: string;
          readonly text: string;
          readonly type: BlockType;
        } | null>;
        readonly mainBlock: {
          readonly id: string;
          readonly text: string;
          readonly type: BlockType;
        };
      }>;
      readonly comparedDocumentBlocks: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly text: string;
          readonly type: BlockType;
        }>;
        readonly id: string;
        readonly text: string;
        readonly type: BlockType;
      } | null>;
      readonly mainBlock: {
        readonly id: string;
        readonly text: string;
        readonly type: BlockType;
      };
    }>;
    readonly comparedDocumentBlocks: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly text: string;
        readonly type: BlockType;
      }>;
      readonly id: string;
      readonly text: string;
      readonly type: BlockType;
    } | null>;
    readonly mainBlock: {
      readonly id: string;
      readonly text: string;
      readonly type: BlockType;
    };
  }>;
  readonly comparedDocumentBlocks: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly text: string;
      readonly type: BlockType;
    }>;
    readonly id: string;
    readonly text: string;
    readonly type: BlockType;
  } | null>;
  readonly mainBlock: {
    readonly id: string;
    readonly text: string;
    readonly type: BlockType;
  };
  readonly " $fragmentType": "CompareTableRow_blockComparison";
};
export type CompareTableRow_blockComparison$key = {
  readonly " $data"?: CompareTableRow_blockComparison$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompareTableRow_blockComparison">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Block",
  "kind": "LinkedField",
  "name": "mainBlock",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Block",
  "kind": "LinkedField",
  "name": "comparedDocumentBlocks",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompareTableRow_blockComparison",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockComparison",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockComparison",
          "kind": "LinkedField",
          "name": "children",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BlockComparison",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BlockComparison",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BlockComparison",
                      "kind": "LinkedField",
                      "name": "children",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BlockComparison",
                          "kind": "LinkedField",
                          "name": "children",
                          "plural": true,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BlockComparison",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": true,
                              "selections": [
                                (v3/*: any*/),
                                (v4/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BlockComparison",
                                  "kind": "LinkedField",
                                  "name": "children",
                                  "plural": true,
                                  "selections": [
                                    (v3/*: any*/),
                                    (v4/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "BlockComparison",
                                      "kind": "LinkedField",
                                      "name": "children",
                                      "plural": true,
                                      "selections": [
                                        (v3/*: any*/),
                                        (v4/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BlockComparison",
  "abstractKey": null
};
})();

(node as any).hash = "076f1ff0b117a0c3929648a249b60147";

export default node;

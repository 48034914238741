import { useFragment } from 'react-relay/hooks'
import { css } from "@emotion/react"
import { Button } from '@chakra-ui/react'

type Props = {
  text: string
  onClick: () => void
  isSelected: boolean
}

export function OpportunityDocumentSideMenuItem({ text, onClick, isSelected }: Props) {
  return (
    <Button
      variant={isSelected ? 'solid' : 'ghost'}
      onClick={onClick}
      css={css`
          float: left;
          margin: 0;
          padding: 3px;
          padding-left: 6px;
          font-weight: 900;
          width: 100%;
          color: #999;
          text-align: left  !important;
          display: block;
          white-space: break-spaces;
          `}
      _hover={{ bg: "#EEEEEE" }}
    >
      {text}
    </Button>
  )
}



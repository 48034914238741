// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';

export const verdiChakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global:  {
      body: {
        fontSize: 16,
        bg: mode('#FFF', '#181818'),
        color: mode('#333', '#FFF')
      },
      h6: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      ul: {
        marginLeft: 5,
      },
      ol: {
        marginLeft: 5,
      },
      'div[role=main]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      // styles for the `a`
      a: {
        color: 'teal.500',
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
    components: {
      MenuButton: {
        baseStyle: {
          padding: 0,
          backgroundColor: 'orange',
        },
      },
    },
  },
})



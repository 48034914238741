import graphql from 'babel-plugin-relay/macro'
import { useRouter } from 'found'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { OpportunityPage } from './OpportunityPage'
import { OpportunityPageQueryQuery } from './__generated__/OpportunityPageQueryQuery.graphql'

const queryQL = graphql`
  query OpportunityPageQueryQuery(
    $opportunityId: ID!
    # $projectId: ID
  ) {
     opportunity: node(id: $opportunityId){
      id
      ... on Opportunity {
        ...OpportunityPage_opportunity
      }
     }
    #  project: node(id: $projectId){
    #   id
    #   ... on Project {
    #     ...OpportunityPage_project
    #   }
    #  }
  }
`

type Props = {}

export function OpportunityPageQuery() {
  const router = useRouter()
  const opportunityId = router.match.params['opportunityId']
  const projectId = router.match.params['projectId']

  const query = useLazyLoadQuery<OpportunityPageQueryQuery>(queryQL, {
    opportunityId,
    // projectId
  })

  return (
    <OpportunityPage
      // project={query.project}
      opportunity={query.opportunity!}
    />
  )
}
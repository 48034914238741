/**
 * @generated SignedSource<<355921608e21a949afe9fbaa44e54e24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompareTableHeader_document$data = {
  readonly comparison: {
    readonly comparedDocuments: ReadonlyArray<{
      readonly id: string;
      readonly title: string | null;
    }>;
  };
  readonly title: string | null;
  readonly " $fragmentType": "CompareTableHeader_document";
};
export type CompareTableHeader_document$key = {
  readonly " $data"?: CompareTableHeader_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompareTableHeader_document">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompareTableHeader_document",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentComparison",
      "kind": "LinkedField",
      "name": "comparison",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "comparedDocuments",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "613b29174ae9b6e8e6a5509d2066be45";

export default node;

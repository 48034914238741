import { Editor } from "@tiptap/core"
import { findParentNestableNodeClosestToPos } from "./findParentNestableNode"

export const getSelectedBlockText = (pos?: Editor['state']['selection']['$anchor']) => {
  if (!pos) return undefined
  try {
    const text = pos?.node()?.textContent
    return text
  } catch (error) {
    return undefined
  }
}


export const getSelectedBlockChildren = (pos?: Editor['state']['selection']['$anchor']) => {
  if (!pos) return undefined
  try {
    const nestable = findParentNestableNodeClosestToPos(pos)
    if (!nestable) return undefined
    const content = nestable.node.content
    const contentList = content.child(1)
    const contentListChildren = contentList.content
    return contentListChildren
  } catch (error) {
    return undefined
  }
}

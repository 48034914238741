import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ZoomDetailsForDocumentAttach } from './ZoomDetailsForDocumentAttach';
import { ZoomDetailsForDocument_document$key } from './__generated__/ZoomDetailsForDocument_document.graphql';
import "./ZoomMeetingList.css"
import { Box, Button, Heading } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useEffect, useState, ForwardRefRenderFunction, useImperativeHandle, forwardRef } from 'react';
import { buildFromString, TranscriptLine } from './TranscriptBuilder';
import { ZoomDetailsForDocumentRemove } from './ZoomDetailsForDocumentRemoveButton';

const fragmentQL = graphql`
  fragment ZoomDetailsForDocument_document on Document {
    id
   zoomMeeting { 
    topic
    closedCaptioningDownloadUrl
    closedCaptioningText
    durationInMinutes
    startTime
    zoomMeetingId
    }
  }
`

export type ZoomTranscriptForDocument = {
  scrollToTimestamp: (timestamp: string) => void
}

type Props = { document: ZoomDetailsForDocument_document$key; }

const ZoomDetailsForDocumentUnforwarded: ForwardRefRenderFunction<ZoomTranscriptForDocument, Props> = ({ document: documentKey }: Props, ref) => {

  useImperativeHandle(ref, () => ({
    scrollToTimestamp: (timeStamp: string) => {
      console.log("Scrolling to the thing at ", timeStamp)
    }
  }))

  const document = useFragment(fragmentQL, documentKey)
  const [isShowingAttach, setIsShowingAttach] = useState(false)
  const [transcribedLines, setTranscribedLines] = useState<TranscriptLine[]>([])
  const zoomMeeting = document.zoomMeeting

  useEffect(() => {
    if (zoomMeeting?.closedCaptioningText) {
      const lines = buildFromString(zoomMeeting.closedCaptioningText)
      setTranscribedLines(lines)
    }

  }, [zoomMeeting])

  return (
    <div className='sideBar'>
      <Box maxW="300px">
        <Heading as='h2' size='md'>Meeting Notes</Heading>

        {zoomMeeting &&
          <>
            <div className='listedItem'>
              <Heading as='h3' >
                {zoomMeeting.topic} <br />
                <small>{zoomMeeting.zoomMeetingId}</small>
              </Heading>


              {format(new Date(zoomMeeting.startTime), "MMM dd, yyyy - hh:mm aaa")} - {zoomMeeting.durationInMinutes} min
            </div>

            {!isShowingAttach &&
              <div className='transcript'>
                {transcribedLines && transcribedLines.map((line, i) =>
                  <div key={i}><strong>{line.timeStamp}</strong><br />
                    <span>{line.text}</span></div>
                )}
              </div>
            }

            <ZoomDetailsForDocumentRemove documentId={document.id}></ZoomDetailsForDocumentRemove>
          </>
        }


        {isShowingAttach &&
          <>
            <Button onClick={() => setIsShowingAttach(false)}
              marginBottom='10px'>
              Cancel
            </Button>
            <ZoomDetailsForDocumentAttach documentId={document.id}
              callbackOnAttach={() => setIsShowingAttach(false)} />
          </>}

        {!isShowingAttach &&
          <div>
            {!zoomMeeting &&
              <div>
                <p>No Zoom meeting connected</p>
                <Button onClick={() => setIsShowingAttach(!isShowingAttach)}
                  marginBottom='10px'>
                  Connect a Zoom Meeting
                </Button>
              </div>
            }

          </div>}
      </Box>
    </div >
  )
}

export const ZoomDetailsForDocument = forwardRef(ZoomDetailsForDocumentUnforwarded)
import { useEditor, EditorContent } from '@tiptap/react'
import './tiptap.css'
import { extensions } from './extensions/extensions'
import { CommandMenuWrapper, CommandMenuRef } from './commandMenu/CommandMenuWrapper'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useTipTapData } from './utils/useTipTapData'
import { useSaveTipTapData } from './utils/useSaveTipTapData'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { TipTapEditor_document$key } from './__generated__/TipTapEditor_document.graphql';
import { TipTapEditor_query$key } from './__generated__/TipTapEditor_query.graphql';
import { LoadingIndicatorPortal } from '../LoadingIndicatorPortal'
import { applyDevTools } from 'prosemirror-dev-toolkit'
import { handlePaste, transformPasted } from './extensions/events/CopyPaste'
import { findParentNestableNodeClosestToPos } from './utils/findParentNestableNode'
import { ZoomTranscriptForDocument } from '../../zoom/ZoomDetailsForDocument'
import { EditorState } from 'prosemirror-state'
import { DocumentSubscription } from '../../../components/documentSubscriptions/useDocumentSubscription'
import { useCollabEditor } from './useCollabEditor'
import { Data, DocumentSchema, ProsemirrorNodes } from 'shared-constants'
import { useGenerateNext } from '../aiEdit/useGenerateNext'
import { generateClientId } from './utils/clientIdGenerator'

const fragmentQL = graphql`
  fragment TipTapEditor_document on Document {
    id
    type
    opportunities {
      title
      description
    }
    ...useTipTapData_document
  }
`

const queryFragmentQL = graphql`
    fragment TipTapEditor_query on Query {
      loggedInUser {
        id
        email
      }
    }
`

type Props = {
  document: TipTapEditor_document$key
  setTipTapData: (content: ProsemirrorNodes.TipTapDocument) => void
  showDevTools?: boolean
  selectedZoomTimestamp?: string
  scrollToTranscriptRef: RefObject<ZoomTranscriptForDocument>
  documentSubscription: DocumentSubscription
  setDocumentPosition: (args: any) => void
  query: TipTapEditor_query$key
}



export const TiptapEditor = ({
  document: documentKey,
  query: queryKey,
  setTipTapData,
  showDevTools = false,
  selectedZoomTimestamp,
  scrollToTranscriptRef,
  documentSubscription,
  setDocumentPosition,
}: Props) => {

  const document = useFragment(fragmentQL, documentKey)
  const { loggedInUser } = useFragment(queryFragmentQL, queryKey)
  if (!loggedInUser) throw new Error(`No user logged in!`);

  // const startingData = useTipTapData({ document })
  const [save, saveInProgress] = useSaveTipTapData(document?.id)
  const savedDoc = useTipTapData({ document })
  const initialDoc = savedDoc ? {
    type: "doc",
    content: savedDoc,
  } : DocumentSchema.initialDoc()
  // const startingContent = documentSubscription ? documentSubscription.initialDoc!.body : initialDoc
  const startingContent = documentSubscription ? documentSubscription.initialDoc!.body : initialDoc
  const commandMenuRef = useRef<CommandMenuRef>()
  const editorRef = useRef<ReturnType<typeof useEditor>>()
  const [editorContentPos, setEditorContentPos] = useState<number>(0);
  const previousStateRef = useRef<EditorState>()
  const clientId =
    useEffect(() => {
      // console.log('starting content', startingContent)
      setTipTapData(startingContent)
    }, [])
  const editor = useCollabEditor(
    generateClientId(loggedInUser.id),
    documentSubscription,
    {
      onCreate({ editor }) {
        if (showDevTools) {
          applyDevTools(editor.view, { devToolsExpanded: true })
        }
        previousStateRef.current = editor.state

      },
      extensions: [
        ...extensions(),
      ],
      autofocus: true,
      onUpdate: ({ editor }) => {
        commandMenuRef.current?.onEditorUpdate(editor.state, previousStateRef.current)
        const doc = editor?.getJSON()
        if (!documentSubscription) {
          save(doc.content as any)
        }
        setTipTapData(doc as ProsemirrorNodes.TipTapDocument)
        previousStateRef.current = editor.state
      },
      editorProps: {
        handleClickOn: (view, pos, node, nodePos, event, direct) => {
          if (!direct) {
            return false
          }

          const resolvedPos = view.state.doc.resolve(pos)
          const parent = findParentNestableNodeClosestToPos(resolvedPos)
          if (!parent) {
            return false
          }

          if (selectedZoomTimestamp) {
            const transaction = view.state.tr.setNodeAttribute(parent.pos, "transcriptTimestamp", selectedZoomTimestamp)
            view.dispatch(transaction)
          }

          const { transcriptTimestamp } = parent.node.attrs;
          if (transcriptTimestamp) {
            scrollToTranscriptRef.current?.scrollToTimestamp(parent.node.attrs.transcriptTimestamp)
          }
          if (node.content.firstChild?.text === undefined) {
            setDocumentPosition({ verticalPosition: event.pageY, type: 'emptyTipTap' })
            return false
          }
          if (node.content.firstChild?.text) setDocumentPosition({ verticalPosition: event.pageY, type: 'tipTap' })
        },
        transformPasted: (slice) => {
          return transformPasted(slice, editorRef.current!.schema)
        },
        handlePaste: (view, event, slice) => {
          return handlePaste(view, event, slice)
        },
        handleKeyDown: (view, event) => {
          if (commandMenuRef.current?.onKeyDown) {
            const didHandleEvent = commandMenuRef.current.onKeyDown(event)
            return didHandleEvent
          }
        },
      },
      content: startingContent,
    })

  const opportunityInfo = {
    opportunityTitle: document.opportunities?.[0]?.title ?? '',
    description: document.opportunities?.[0]?.description ?? '',
  }
  useGenerateNext({
    documentSubscription,
    editor: editor,
    documentType: document.type,
    sharedContext: {
      opportunity: opportunityInfo,
      company: {
        companyUrl: Data.EmailDomains.getUserCompanyDomainForEmail(loggedInUser.email) ?? undefined
      }
    },
  })

  if (!editorRef.current) {
    editorRef.current = editor
  }

  return (
    <>
      {/* {
        editor && documentSubscription &&
        <TestAIEdit editor={editor} documentSubscription={documentSubscription} />
      } */}
      <LoadingIndicatorPortal isSaving={saveInProgress} />
      <CommandMenuWrapper
        ref={ref => commandMenuRef.current = ref || undefined}
        editor={editor}
      />
      <EditorContent
        editor={editor}
      />
    </>
  )
}